import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { StyledDialog, classes } from "./styles";
import { VideoType } from "./constants";

interface VideoDialogProps {
  isOpen: boolean;
  onClose: () => void;
  videoType: VideoType;
  title?: string;
}

const VideoDialog: React.FC<VideoDialogProps> = ({
  title = "",
  isOpen,
  onClose,
  videoType,
}) => {
  const getVideoSource = (type: VideoType) => {
    switch (type) {
      case VideoType.PROCESS:
        return "https://player.vimeo.com/video/1054644133?h=360f7fdb42&autoplay=1";
      case VideoType.SAMPLE:
        return "https://player.vimeo.com/video/1056092184?h=0315ef1456&autoplay=1";
      case VideoType.ADVISOR:
        return "https://player.vimeo.com/video/1054643680?h=01a2130e39&autoplay=1";
      default:
        return "";
    }
  };

  const getVideoTitle = (type: VideoType) => {
    switch (type) {
      case VideoType.PROCESS:
        return "Pearl Edison 1";
      case VideoType.SAMPLE:
        return "Pearl Edison 2";
      case VideoType.ADVISOR:
        return "Pearl Edison 3";
      default:
        return "Pearl Edison";
    }
  };

  return (
    <StyledDialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle className={classes.dialogHeader}>
        {title && (
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        )}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div
          style={{
            position: "relative",
            paddingTop: "56.25%", // 16:9 aspect ratio
            width: "100%",
            height: 0,
          }}
        >
          <iframe
            src={getVideoSource(videoType)}
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: "none",
            }}
            title={getVideoTitle(videoType)}
          />
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default VideoDialog;

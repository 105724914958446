/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import isObject from "lodash/isObject";
import { Button, Stack, Tooltip } from "@mui/material";
import { SlideSchema } from "@library/components/Sherpa/functions";
import AdvisorProfile from "@library/components/AdvisorProfile";
import isFunction from "lodash/isFunction";
import AdvisorTooltipContent from "../AdvisorTooltipContent";

interface CustomFooterProps {
  theme: any;
  payload: any;
  slideState: any;
  slideIndex: number;
  slide: SlideSchema;
  nextSlideIndex: number;
  previousSlideIndex: number;
  setSlideDirection: (direction: number) => void;
  formRef: any;
  setSkipModalOpen: (open: boolean) => void;
}

const OnboardingFooter: React.FC<CustomFooterProps> = ({
  theme,
  slide,
  slideIndex,
  nextSlideIndex,
  previousSlideIndex,
  setSlideDirection,
  formRef,
  setSkipModalOpen,
  payload,
  slideState,
}) => {
  const [showTooltip, setShowTooltip] = useState(true);

  const handleCloseTooltip = () => {
    setShowTooltip(false);
  };

  const validateForm = () => {
    if (formRef.current) {
      let hasNextOnChange = false;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [_key, value] of Object.entries(
        formRef.current?.props?.uiSchema
      )) {
        if (isObject(value) && (value as any)?.nextOnChange === true) {
          hasNextOnChange = true;
        }
      }
      if (!hasNextOnChange) return true;
      const formData = formRef.current.state.formData;
      const { errors } = formRef.current.validate(formData);
      if (errors.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const shouldRenderFooterNavigation = () => {
    const hasFooterContent = renderFooterContent();
    const hasBackButton = previousSlideIndex !== slideIndex && slideIndex > 0;
    const hasSkipButton = slide.canSkip && slide.skipPlacement !== "form";
    const hasNextButton = slide.nextTitle !== "" && slide.noSubmit !== true;

    return hasFooterContent || hasBackButton || hasSkipButton || hasNextButton;
  };

  // Render different footer content based on the footerContent type
  const renderFooterContent = () => {
    if (slide.config?.footerContent?.type === "advisor") {
      return (
        <Tooltip
          open={slide.key === "MeetYourAdvisorStep" && showTooltip}
          placement="top-end"
          arrow
          PopperProps={{
            sx: {
              display: { xs: "none", md: "block" }, // Hide on mobile, show on desktop
              [`& .MuiTooltip-tooltip`]: {
                maxWidth: "none", // Remove default max-width
                width: {
                  sm: 480, // Tablet-ish
                },
                mx: 1,
                backgroundColor: theme.palette.common.white,
                padding: theme.spacing(2),
                border: `1px solid ${theme.palette.primary.light} !important`,
                borderRadius: 1,
              },
              [`& .MuiTooltip-arrow`]: {
                color: theme.palette.common.white,
                "&:before": {
                  border: `1px solid ${theme.palette.primary.light}`,
                },
              },
            },
          }}
          title={
            <AdvisorTooltipContent theme={theme} onClose={handleCloseTooltip} />
          }
        >
          <div>
            {" "}
            {/* Wrapper div needed because Tooltip requires a DOM element child */}
            <AdvisorProfile
              theme={theme}
              borderStyle="right"
              payload={payload}
              advisor={slideState.advisor}
            />
          </div>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <>
      {slide.navigationPlacement === "form" ? (
        <Stack
          spacing={2}
          direction="row"
          // mr={2}
          justifyContent="flex-end"
          alignItems="center"
          className="Navigation"
        >
          <Stack spacing={2} direction="row">
            {slideIndex > 0 && (
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setSlideDirection(-1);
                }}
                sx={{
                  height: 50,
                  fontSize: 18,
                  minWidth: "80px",
                  color: slide.dark
                    ? theme.palette.primary.contrastText
                    : undefined,
                  borderColor: slide.dark ? "transparent" : undefined,
                  backgroundColor: slide.dark
                    ? "rgba(255,255,255,.2)"
                    : undefined,
                }}
              >
                {slide.backTitle ? slide.backTitle : "Back"}
              </Button>
            )}
            {slide.canSkip && slide.skipPlacement !== "form" && (
              <Button
                variant="contained"
                onClick={() => setSkipModalOpen(true)}
                color="secondary"
                sx={{
                  textTransform: "none",
                  height: 50,
                  fontSize: 18,
                  minWidth: "80px",
                }}
              >
                {slide.skipTitle || slide.skipText || "Skip this step"}
              </Button>
            )}
            {slide.nextTitle !== "" && slide.noSubmit !== true && (
              <Button
                variant="contained"
                type="submit"
                data-cy="submit"
                data-key={slide.key}
                color="primary"
                sx={{
                  height: 50,
                  fontSize: 18,
                  px: 2,
                  minWidth: "100px",
                }}
                onClick={() => {
                  const form = formRef.current || {
                    submit: () => {},
                  };
                  if (form && form.submit) form.submit();
                }}
              >
                {nextSlideIndex !== -1 || slide.nextTitle
                  ? isFunction(slide.nextTitle)
                    ? slide.nextTitle({ theme })
                    : slide.nextTitle || "Next"
                  : "Next"}
              </Button>
            )}
          </Stack>
        </Stack>
      ) : (
        shouldRenderFooterNavigation() && (
          <Stack
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              boxShadow: "0px 0px 20px 0px #CCC",
              height: "75px",
              backgroundColor: theme.palette.primary.contrastText,
              zIndex: 2,
            }}
            className="Navigation"
          >
            <Stack
              spacing={2}
              direction="row"
              mt={slide.config?.footerContent?.type === "advisor" ? 0 : 1.5} // Adjust margin based on advisor presence
              mr={2}
              justifyContent={["space-between"]}
              alignItems={["center"]}
            >
              {/* Advisor Profile - only shown on specific slides */}
              <Stack>{renderFooterContent()}</Stack>

              {/* Navigation Buttons */}
              <Stack direction="row" alignItems="center" height="100%">
                {previousSlideIndex !== slideIndex && slideIndex > 0 ? (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                      setSlideDirection(-1);
                    }}
                    sx={{
                      px: 2,
                      mx: 1,
                      height: 50,
                      fontSize: 18,
                      minWidth: "100px",
                      color: slide.dark
                        ? theme.palette.primary.contrastText
                        : undefined,
                      borderColor: slide.dark ? "transparent" : undefined,
                      backgroundColor: slide.dark
                        ? "rgba(255,255,255,.2)"
                        : undefined,
                    }}
                  >
                    {slide.backTitle ? slide.backTitle : "Back"}
                  </Button>
                ) : null}
                {slide.canSkip && slide.skipPlacement !== "form" && (
                  <Button
                    variant="contained"
                    onClick={() => setSkipModalOpen(true)}
                    color="secondary"
                    sx={{
                      textTransform: "none",
                      height: 50,
                      fontSize: 18,
                      px: 2,
                      minWidth: "100px",
                    }}
                  >
                    {slide.skipTitle || slide.skipText || "Skip this step"}
                  </Button>
                )}
                {slide.nextTitle !== "" && slide.noSubmit !== true && (
                  <Button
                    variant="contained"
                    type="submit"
                    data-cy="submit"
                    data-key={slide.key}
                    color="primary"
                    sx={{
                      height: 50,
                      fontSize: 18,
                      px: 2,
                      mx: 1,
                      minWidth: "100px",
                      // INTENTIONALLY NOT "DISABLING" THE BUTTON, BECAUSE OTHERWISE THERE IS
                      // NO WAY FOR THE USER TO SEE WHAT IS WRONG WITH THEIR SELECTIONS
                      // LIKE ON HOME PROFILE
                      backgroundColor: validateForm()
                        ? undefined
                        : "#73767B !important",
                      color: validateForm() ? undefined : "white !important",
                    }}
                    onClick={() => {
                      const form = formRef.current || { submit: () => {} };
                      if (form && form.submit) form.submit();
                    }}
                  >
                    {nextSlideIndex !== -1 || slide.nextTitle
                      ? isFunction(slide.nextTitle)
                        ? slide.nextTitle({ theme })
                        : slide.nextTitle || "Next"
                      : "Next"}
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
        )
      )}
    </>
  );
};

export default OnboardingFooter;

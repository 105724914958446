import React, { useState } from "react";
import { FieldProps } from "@rjsf/utils";
import {
  Box,
  Stack,
  Typography,
  useTheme,
  TextField,
  Grid2,
  ButtonGroup,
  Button,
  Alert,
  Checkbox,
  FormControlLabel,
  Divider,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
} from "@mui/material";
import type { SelectChangeEvent, Theme } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import cloneDeep from "lodash/cloneDeep";

import { Room } from "@library/domain/home";

const CheckBadge = ({
  color,
  index = 0,
  complete = false,
  active = false,
}: {
  bgcolor: string;
  color: string;
  index: number;
  complete: boolean;
  active: boolean;
}) => {
  return (
    <Stack
      sx={{
        bgcolor: complete
          ? "primary.main"
          : complete && active
            ? "primary.light"
            : active
              ? "primary.light"
              : "gray",
        color: "primary.contrastText",
        textAlign: "center",
        height: "36px",
        width: "36px",
        fontSize: "1.4rem",
        borderRadius: "18px",
        "svg path": {
          stroke: color,
          color: color,
        },
      }}
      justifyContent="center"
      alignItems="center"
    >
      {complete ? (
        <CheckIcon
          htmlColor={color}
          sx={{
            width: "24px",
            color,
          }}
        />
      ) : null}
      {!complete ? <Typography>{index + 1}</Typography> : null}
    </Stack>
  );
};

const StyledButton = ({
  room,
  setRoom,
  field,
  value,
  theme,
  index,
  setErrors,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  room: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setRoom: (key: string, value: any, index: number) => void;
  field: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  theme: Theme;
  index: number;
  setErrors: (error: boolean) => void;
}) => {
  const label = theme.t(`labels.rooms.${field}.enum.${value}`) || value;
  return (
    <Button
      variant={room[field] === value ? "contained" : "outlined"}
      onClick={() => {
        setErrors(false);
        setRoom(field, value, index);
      }}
      data-cy="option"
      data-room={room.name}
      data-key={field}
      data-value={value}
      sx={{ fontSize: ["0.6rem", "0.8rem", "0.9rem", "1.0rem"] }}
    >
      {label}
    </Button>
  );
};

export const RoomsConfiguration = (props: FieldProps) => {
  const { errorSchema } = props;
  const formData = props.formData;
  const theme = useTheme();
  const [currentRoom, setCurrentRoom] = useState(0);
  const [errors, setErrors] = useState(false);

  const rooms = formData?.rooms || [];

  const showOverrides = props?.uiSchema?.showOverrides || false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setRoom = (key: string, value: any, index: number) => {
    const newRooms = cloneDeep(props.formData?.rooms);
    newRooms[index][key] = value;
    props.onChange({
      ...formData,
      rooms: newRooms,
    });
  };

  const Adornment = (
    <InputAdornment
      position="end"
      sx={{
        ".MuiInputAdornment-root": {
          backgrondColor: "transparent !important",
        },
      }}
    >
      sqft
    </InputAdornment>
  );

  return (
    <>
      {rooms.map((room: Room, index: number) => {
        const isComplete = Boolean(
          room.name?.length >= 3 && room.floor && room.size
        );
        const isActive = index === currentRoom;
        const hasErrors =
          Object.keys(errorSchema?.rooms?.[index] ?? {}).length ?? 0;

        return (
          <Stack
            key={index}
            justifyContent="center"
            alignItems="center"
            width="100%"
            sx={{ my: 1 }}
          >
            <Stack
              px={[0, 2]}
              pb={0}
              width="100%"
              maxWidth="900px"
              justifyContent="center"
              alignItems="center"
              direction="row"
            >
              <Stack
                width={["100%"]}
                bgcolor="white"
                p={[2, 2]}
                borderRadius={2}
                spacing={3}
                // border={
                //   error ? `1px solid ${theme.palette.error.main}` : undefined
                // }
              >
                <Stack spacing={3}>
                  <Grid2 container alignItems="center">
                    <Grid2 size={2}>
                      <CheckBadge
                        bgcolor={theme.palette.success.light}
                        color={theme.palette.primary.contrastText}
                        index={index}
                        complete={isComplete}
                        active={isActive}
                      />
                    </Grid2>
                    <Grid2 size={8}>
                      <Stack>
                        {isActive ? (
                          <TextField
                            defaultValue={room.name}
                            sx={{ width: "100%" }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setRoom("name", event.target.value, index);
                            }}
                            // helperText={theme.t(
                            //   `labels.rooms.type.enum.${room.type}`
                            // )}
                          />
                        ) : (
                          <Typography>{room.name}</Typography>
                        )}
                      </Stack>
                    </Grid2>
                    <Grid2 textAlign="right" size={2}>
                      <Stack
                        sx={{
                          display: index >= currentRoom ? "none" : "initial",
                        }}
                      >
                        <Typography
                          color="primary"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setCurrentRoom(index);
                          }}
                        >
                          Edit
                        </Typography>
                      </Stack>
                    </Grid2>
                  </Grid2>
                </Stack>
                <Stack
                  spacing={3}
                  sx={{ display: isActive ? "initial" : "none" }}
                >
                  <Divider />
                  <Grid2 container>
                    <Grid2
                      size={{
                        xs: 12,
                        sm: 4,
                      }}
                    >
                      <Box
                        display="flex"
                        height="100%"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Typography>
                          {theme.t(`labels.rooms.type.label`)}
                        </Typography>
                      </Box>
                    </Grid2>
                    <Grid2
                      size={{
                        xs: 12,
                        sm: 8,
                      }}
                    >
                      <FormControl>
                        <Select
                          defaultValue={room.type}
                          onChange={(event: SelectChangeEvent<string>) => {
                            if (errors) {
                              setErrors(false);
                            }
                            setRoom("type", event.target.value, index);
                          }}
                        >
                          {props.schema?.properties?.rooms?.items?.properties?.type?.enum.map(
                            (type: string) => {
                              return (
                                <MenuItem key={type} value={type}>
                                  {theme.t(`labels.rooms.type.enum.${type}`)}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </Grid2>
                  </Grid2>
                  <Grid2 container>
                    <Grid2
                      size={{
                        xs: 12,
                        sm: 4,
                      }}
                    >
                      <Box
                        display="flex"
                        height="100%"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Typography>
                          {theme.t(`labels.rooms.floor.label`)}
                        </Typography>
                      </Box>
                    </Grid2>
                    <Grid2
                      size={{
                        xs: 12,
                        sm: 8,
                      }}
                    >
                      <ButtonGroup
                        color="primary"
                        variant="outlined"
                        aria-label={theme.t(`labels.rooms.floor.label`)}
                        sx={{
                          ".MuiButtonGroup-firstButton": {
                            borderRadius: "2px 0 0 2px",
                          },
                          ".MuiButtonGroup-lastButton": {
                            borderRadius: "0 2px 2px 0",
                          },
                          height: "48px",
                        }}
                        fullWidth
                      >
                        {formData.basement === "yes" && (
                          <StyledButton
                            field="floor"
                            value="basement"
                            room={room}
                            setRoom={setRoom}
                            theme={theme}
                            index={index}
                            setErrors={setErrors}
                          />
                        )}
                        {formData.aboveGroundStories >= 1 && (
                          <StyledButton
                            field="floor"
                            value="1"
                            room={room}
                            setRoom={setRoom}
                            theme={theme}
                            index={index}
                            setErrors={setErrors}
                          />
                        )}
                        {formData.aboveGroundStories >= 2 && (
                          <StyledButton
                            field="floor"
                            value="2"
                            room={room}
                            setRoom={setRoom}
                            theme={theme}
                            index={index}
                            setErrors={setErrors}
                          />
                        )}
                        {formData.aboveGroundStories >= 3 && (
                          <StyledButton
                            field="floor"
                            value="3"
                            room={room}
                            setRoom={setRoom}
                            theme={theme}
                            index={index}
                            setErrors={setErrors}
                          />
                        )}
                        {formData.attic === "yes" && (
                          <StyledButton
                            field="floor"
                            value="attic"
                            room={room}
                            setRoom={setRoom}
                            theme={theme}
                            index={index}
                            setErrors={setErrors}
                          />
                        )}
                      </ButtonGroup>
                    </Grid2>
                  </Grid2>
                  <Grid2 container>
                    <Grid2
                      size={{
                        xs: 12,
                        sm: 4,
                      }}
                    >
                      <Box
                        display="flex"
                        height="100%"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Typography>
                          {theme.t(`labels.rooms.size.label`)}
                        </Typography>
                      </Box>
                    </Grid2>
                    <Grid2
                      size={{
                        xs: 12,
                        sm: 8,
                      }}
                    >
                      <ButtonGroup
                        variant="outlined"
                        color="primary"
                        aria-label={theme.t(`labels.rooms.size.label`)}
                        sx={{
                          ".MuiButtonGroup-firstButton": {
                            borderRadius: "2px 0 0 2px",
                          },
                          ".MuiButtonGroup-lastButton": {
                            borderRadius: "0 2px 2px 0",
                          },
                          height: "48px",
                        }}
                        fullWidth
                      >
                        <StyledButton
                          field="size"
                          value="SMALL"
                          room={room}
                          setRoom={setRoom}
                          theme={theme}
                          index={index}
                          setErrors={setErrors}
                        />
                        <StyledButton
                          field="size"
                          value="MEDIUM"
                          room={room}
                          setRoom={setRoom}
                          theme={theme}
                          index={index}
                          setErrors={setErrors}
                        />
                        <StyledButton
                          field="size"
                          value="LARGE"
                          room={room}
                          setRoom={setRoom}
                          theme={theme}
                          index={index}
                          setErrors={setErrors}
                        />
                        {/* FYI: Product requested hiding XL and XXL options for now */}
                        {/* <StyledButton
                          field="size"
                          value="XL"
                          room={room}
                          setRoom={setRoom}
                          theme={theme}
                          index={index}
                          setErrors={setErrors}
                        />
                        <StyledButton
                          field="size"
                          value="XXL"
                          room={room}
                          setRoom={setRoom}
                          theme={theme}
                          index={index}
                          setErrors={setErrors}
                        /> */}
                      </ButtonGroup>
                    </Grid2>
                  </Grid2>
                  {showOverrides ? (
                    <>
                      <Grid2 container>
                        <Grid2
                          size={{
                            xs: 12,
                            sm: 4,
                          }}
                        >
                          <Box
                            display="flex"
                            height="100%"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Typography>
                              {theme.t(`labels.rooms.sqft.label`)}
                            </Typography>
                          </Box>
                        </Grid2>
                        <Grid2
                          size={{
                            xs: 12,
                            sm: 8,
                          }}
                        >
                          <FormControl>
                            <TextField
                              type="number"
                              defaultValue={room.sqft || 0}
                              sx={{ width: "100%" }}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setRoom(
                                  "sqft",
                                  parseInt(event.target.value, 10),
                                  index
                                );
                              }}
                              InputProps={{
                                type: "number",
                                endAdornment: Adornment,
                              }}
                            />
                          </FormControl>
                        </Grid2>
                      </Grid2>
                      <Grid2 container>
                        <Grid2
                          size={{
                            xs: 12,
                            sm: 4,
                          }}
                        >
                          <Box
                            display="flex"
                            height="100%"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Typography>
                              {theme.t(`labels.rooms.windowSqft.label`)}
                            </Typography>
                          </Box>
                        </Grid2>
                        <Grid2
                          size={{
                            xs: 12,
                            sm: 8,
                          }}
                        >
                          <FormControl>
                            <TextField
                              type="number"
                              defaultValue={room.windowSqft}
                              sx={{ width: "100%" }}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setRoom(
                                  "windowSqft",
                                  parseInt(event.target.value, 10),
                                  index
                                );
                              }}
                              InputProps={{
                                type: "number",
                                endAdornment: Adornment,
                              }}
                            />
                          </FormControl>
                        </Grid2>
                      </Grid2>
                    </>
                  ) : null}
                  <Grid2 container>
                    <Grid2
                      size={{
                        xs: 12,
                        sm: 4,
                      }}
                    >
                      <Box
                        display="flex"
                        height="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{ pt: 1 }}
                      >
                        <Typography>
                          {theme.t(`labels.rooms.comfort.label`)}
                        </Typography>
                      </Box>
                    </Grid2>
                    <Grid2
                      alignItems="flex-start"
                      justifyContent="flext-start"
                      sx={{
                        border: room.isComfortIssue ? "1px solid #CCC" : "",
                        px: room.isComfortIssue ? 3 : 0,
                        py: room.isComfortIssue ? 1 : 0,
                        borderRadius: 2,
                      }}
                      size={{
                        xs: 12,
                        sm: 8,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={room.isComfortIssue}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (errors) {
                                setErrors(false);
                              }
                              setRoom(
                                "isComfortIssue",
                                event.target.checked,
                                index
                              );
                            }}
                          />
                        }
                        label={
                          <Typography variant="body1" color="primary">
                            {theme?.t(`labels.rooms.isComfortIssue.label`)}
                          </Typography>
                        }
                      />

                      {room.isComfortIssue ? (
                        <>
                          <Divider />
                          <Typography sx={{ pt: 2, fontWeight: "500" }}>
                            When does it occur?
                          </Typography>
                          <RadioGroup
                            value={room.comfortIssueFrequency || ""}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (errors) {
                                setErrors(false);
                              }
                              setRoom(
                                "comfortIssueFrequency",
                                event.target.value,
                                index
                              );
                            }}
                          >
                            <Stack direction="row" spacing={3}>
                              <FormControlLabel
                                value="always"
                                control={
                                  <Radio
                                    sx={{
                                      "&.Mui-checked": {
                                        color: theme.palette.primary.main,
                                      },
                                    }}
                                  />
                                }
                                label={
                                  theme?.t(
                                    `labels.rooms.comfortIssueFrequency.enum.always`
                                  ) || "Always"
                                }
                              />
                              <FormControlLabel
                                value="summer"
                                control={
                                  <Radio
                                    sx={{
                                      "&.Mui-checked": {
                                        color: theme.palette.primary.main,
                                      },
                                    }}
                                  />
                                }
                                label={
                                  theme?.t(
                                    `labels.rooms.comfortIssueFrequency.enum.summer`
                                  ) || "Summer"
                                }
                              />
                              <FormControlLabel
                                value="winter"
                                control={
                                  <Radio
                                    sx={{
                                      "&.Mui-checked": {
                                        color: theme.palette.primary.main,
                                      },
                                    }}
                                  />
                                }
                                label={
                                  theme?.t(
                                    `labels.rooms.comfortIssueFrequency.enum.winter`
                                  ) || "Winter"
                                }
                              />
                            </Stack>
                          </RadioGroup>
                          <TextField
                            defaultValue={room.comfortIssueInfo}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setRoom(
                                "comfortIssueValue",
                                event.target.value,
                                index
                              );
                            }}
                            multiline={true}
                            rows={3}
                            sx={{ width: "100%", my: 2 }}
                            label={theme?.t(
                              `labels.rooms.comfortIssueInfo.label`
                            )}
                          />
                        </>
                      ) : null}
                    </Grid2>
                  </Grid2>
                  <Box sx={{ textAlign: "right" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth={false}
                      sx={{ width: "auto", flexBasis: "initial" }}
                      onClick={() => {
                        if (!isComplete) {
                          setErrors(true);
                          return;
                        }
                        setErrors(false);
                        setCurrentRoom(currentRoom + 1);
                      }}
                    >
                      Save this room
                    </Button>
                  </Box>
                </Stack>

                {!isComplete && (hasErrors || (errors && isActive)) ? (
                  <Alert severity="error">Please fill out all fields</Alert>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </>
  );
};

export default RoomsConfiguration;

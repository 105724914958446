import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid2,
  Stack,
  useTheme,
  Container,
  useMediaQuery,
} from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Root, classes } from "./styles";
import Checklist from "@library/components/form/assets/Checklist";
import Phone from "@library/assets/Phone";
import Homestead from "@library/assets/Homestead";
import Agreement from "@library/assets/Agreement";
import Contract from "@library/assets/Contract";
import VideoDialog from "@library/components/VideoDialog";
import { VideoType } from "@library/components/VideoDialog/constants";

const baseLanguageLocation = "step.LetsGetStartedStep";

interface LetsGetStartedStepProps {
  setSlideDirection: (direction: number) => void;
}

const LetsGetStartedStep: React.FC<LetsGetStartedStepProps> = () => {
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<VideoType>(
    VideoType.PROCESS
  );
  const [dialogTitle, setDialogTitle] = useState("");

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const openVideoDialog = (videoType: VideoType) => {
    setCurrentVideo(videoType);
    setDialogTitle(
      theme.t(`${baseLanguageLocation}.dialogTitles.${videoType}`)
    );
    setIsVideoDialogOpen(true);
  };

  const closeVideoDialog = () => {
    setIsVideoDialogOpen(false);
  };

  return (
    <Root>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 8,
          px: 2,
        }}
      >
        <Stack
          spacing={1}
          alignItems="center"
          className="LetsGetStartedStep-container"
        >
          <Stack
            direction={isMobile ? "column" : "row"}
            alignItems="center"
            spacing={1}
            className={classes.descriptionButtonContainer}
          >
            <Typography className={classes.description}>
              {theme.t(`${baseLanguageLocation}.description`)}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              className={classes.videoButton}
              startIcon={<PlayCircleOutlineIcon />}
              onClick={() => openVideoDialog(VideoType.PROCESS)}
            >
              {theme.t(`${baseLanguageLocation}.buttons.learnMore`)}
            </Button>
          </Stack>

          <Grid2 container spacing={1}>
            {/* Left Column */}
            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <Box className={classes.boxContainer}>
                <Typography className={classes.cardTitle}>
                  {theme.t(`${baseLanguageLocation}.todaySection.title`)}
                </Typography>

                <Stack spacing={1}>
                  <Box>
                    <Stack direction="row" alignItems="flex-start">
                      <Box className={classes.icon}>
                        <Checklist dark />
                      </Box>
                      <Box>
                        <Typography className={classes.title}>
                          {theme.t(
                            `${baseLanguageLocation}.todaySection.estimate.title`
                          )}
                        </Typography>
                        <Typography
                          color="primary"
                          sx={{ mb: 0.5 }}
                          className={classes.timeframe}
                        >
                          {theme.t(
                            `${baseLanguageLocation}.todaySection.estimate.timeframe`
                          )}
                        </Typography>
                        <Typography className={classes.description}>
                          {theme.t(
                            `${baseLanguageLocation}.todaySection.estimate.description`
                          )}
                        </Typography>
                        <Button
                          sx={{ mt: 1, mb: 2 }}
                          variant="outlined"
                          color="primary"
                          className={classes.videoButton}
                          startIcon={<PlayCircleOutlineIcon />}
                          onClick={() => openVideoDialog(VideoType.SAMPLE)}
                        >
                          {theme.t(
                            `${baseLanguageLocation}.todaySection.sampleButton`
                          )}
                        </Button>
                        {/* <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<PlayCircleOutlineIcon />}
                        onClick={() => openVideoDialog(VideoType.PROCESS)}
                      >
                        {theme.t(`${baseLanguageLocation}.buttons.seeProcess`)}
                      </Button> */}
                      </Box>
                    </Stack>
                  </Box>

                  <Box>
                    <Stack direction="row" alignItems="flex-start">
                      <Box className={classes.icon}>
                        <Phone />
                      </Box>
                      <Box>
                        <Typography className={classes.title}>
                          {theme.t(
                            `${baseLanguageLocation}.todaySection.advisor.title`
                          )}
                        </Typography>
                        <Typography className={classes.timeframe}>
                          {theme.t(
                            `${baseLanguageLocation}.todaySection.advisor.timeframe`
                          )}
                        </Typography>
                        <Typography className={classes.description}>
                          {theme.t(
                            `${baseLanguageLocation}.todaySection.advisor.description`
                          )}
                        </Typography>
                        <Button
                          sx={{ mt: 1 }}
                          variant="outlined"
                          color="primary"
                          className={classes.videoButton}
                          startIcon={<PlayCircleOutlineIcon />}
                          onClick={() => openVideoDialog(VideoType.ADVISOR)}
                        >
                          {theme.t(
                            `${baseLanguageLocation}.todaySection.advisor.button`
                          )}
                        </Button>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Grid2>

            {/* Right Column */}
            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <Box className={classes.boxContainer}>
                <Typography className={classes.cardTitle}>
                  {theme.t(`${baseLanguageLocation}.futureSection.title`)}
                </Typography>

                <Stack spacing={2}>
                  <Box>
                    <Stack direction="row" alignItems="flex-start">
                      <Box className={classes.icon}>
                        <Homestead />
                      </Box>
                      <Box>
                        <Typography className={classes.title}>
                          {theme.t(
                            `${baseLanguageLocation}.futureSection.assessment.title`
                          )}
                        </Typography>
                        <Typography className={classes.timeframe}>
                          {theme.t(
                            `${baseLanguageLocation}.futureSection.assessment.timeframe`
                          )}
                        </Typography>
                        <Typography className={classes.description}>
                          {theme.t(
                            `${baseLanguageLocation}.futureSection.assessment.description`
                          )}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>

                  <Box>
                    <Stack direction="row" alignItems="flex-start">
                      <Box className={classes.icon}>
                        <Contract styles={{ fontSize: 20 }} />
                      </Box>
                      <Box>
                        <Typography className={classes.title}>
                          {theme.t(
                            `${baseLanguageLocation}.futureSection.project.title`
                          )}
                        </Typography>
                        <Typography className={classes.timeframe}>
                          {theme.t(
                            `${baseLanguageLocation}.futureSection.project.subtitle`
                          )}
                        </Typography>
                        <Typography className={classes.description}>
                          {theme.t(
                            `${baseLanguageLocation}.futureSection.project.description`
                          )}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>

                  <Box>
                    <Stack direction="row" alignItems="flex-start">
                      <Box className={classes.icon}>
                        <Agreement />
                      </Box>
                      <Box>
                        <Typography className={classes.title}>
                          {theme.t(
                            `${baseLanguageLocation}.futureSection.contractor.title`
                          )}
                        </Typography>
                        <Typography className={classes.timeframe}>
                          {theme.t(
                            `${baseLanguageLocation}.futureSection.contractor.subtitle`
                          )}
                        </Typography>
                        <Typography className={classes.description}>
                          {theme.t(
                            `${baseLanguageLocation}.futureSection.contractor.description`
                          )}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Grid2>
          </Grid2>
        </Stack>
        <VideoDialog
          isOpen={isVideoDialogOpen}
          onClose={closeVideoDialog}
          videoType={currentVideo}
          title={dialogTitle}
        />
      </Container>
    </Root>
  );
};

export default LetsGetStartedStep;

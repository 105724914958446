// import onboardingSchema from "@library/common/schema";
import { Description, Title } from "../components";
import { baseSchema, baseUISchema } from ".";

// const props = onboardingSchema.properties;

// const isRebateOnlyWorkflow = ({ payload }: { payload: any }) => {
//   if (
//     ["heating", "air_conditioning"].includes(payload.projectType) &&
//     (payload.heatingCoolingProjectType === "replace" ||
//       payload.heatingCoolingProjectType === "add" ||
//       payload.airConditioningProjectType === "replace" ||
//       payload.airConditioningProjectType === "add") &&
//     payload.projectMotivation === "incentives"
//   )
//     return true;
//   return false;
// };

export const rebateOnlyWorkflowSlides = [
  // 12
  {
    key: "IncentivesAddressStep",
    Title,
    Description,
    visible: () => {
      // if (!isRebateOnlyWorkflow({ payload })) return false;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        properties: {
          user: {
            required: ["address"],
            type: "object",
            properties: {
              address: {
                title: "Address",
                type: "string",
              },
            },
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        user: {
          "ui:options": {
            label: false,
          },
          address: {
            "ui:widget": "AutocompleteAddress",
            "ui:autocomplete": "off",
            width: "100%",
          },
        },
      };
    },
  },
];

export default rebateOnlyWorkflowSlides;

import React, { useState } from "react";
import {
  Box,
  Fade,
  Stack,
  Button,
  Backdrop,
  Typography,
  IconButton,
  SxProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledModal, classes } from "./styles";

interface InformationModalTriggerProps {
  title?: string;
  description?: string;
  triggerSx?: SxProps;
}

const InformationModalTrigger: React.FC<InformationModalTriggerProps> = ({
  title,
  description,
  triggerSx,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          ...triggerSx,
          borderRadius: 10,
          padding: 0,
          minWidth: 0,
          width: "25px",
          height: "25px",
          backgroundColor: "primary.light",
        }}
      >
        ?
      </Button>
      <StyledModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box className={classes.modalBox}>
            {title ? (
              <Stack
                direction="row"
                justifyContent={title ? "space-between" : "flex-end"}
                alignItems="center"
              >
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {title}
                </Typography>
                <IconButton onClick={handleClose} className={classes.closeIcon}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            ) : (
              <IconButton onClick={handleClose} className={classes.noTitleIcon}>
                <CloseIcon />
              </IconButton>
            )}

            {description && (
              <Typography
                id="transition-modal-description"
                className={
                  title ? classes.description : classes.noTitleDescription
                }
              >
                {description}
              </Typography>
            )}
          </Box>
        </Fade>
      </StyledModal>
    </>
  );
};

export default InformationModalTrigger;

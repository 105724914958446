import React from "react";
import { Alert, Grid2 } from "@mui/material";
import { EMAIL, PHONE_NUMBER } from "@library/common";

export const UncaughtError = ({
  message = `We are unable to complete this request. Please contact us via the chat
widget below, by email at ${EMAIL} or by phone at ${PHONE_NUMBER}.`,
}: {
  message?: string;
}) => {
  return (
    <Grid2
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "calc(100vh - 128px)" }}
      p={5}
    >
      <Grid2 size={3}>
        <Alert severity="error"> {message}</Alert>
      </Grid2>
    </Grid2>
  );
};

export default UncaughtError;

import { styled, Modal } from "@mui/material";

const PREFIX = "InformationModal";

export const classes = {
  modalBox: `${PREFIX}-modalBox`,
  closeIcon: `${PREFIX}-closeIcon`,
  noTitleIcon: `${PREFIX}-noTitleIcon`,
  description: `${PREFIX}-description`,
  noTitleDescription: `${PREFIX}-noTitleDescription`,
};

export const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.modalBox}`]: {
    width: 400,
    top: "50%",
    left: "50%",
    boxShadow: 24,
    position: "absolute",
    padding: theme.spacing(3),
    transform: "translate(-50%, -50%)",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.closeIcon}`]: {
    color: theme.palette.primary.contrastText,
  },
  [`& .${classes.noTitleIcon}`]: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  [`& .${classes.description}`]: {
    marginTop: theme.spacing(1),
  },
  [`& .${classes.noTitleDescription}`]: {
    paddingRight: theme.spacing(2),
  },
}));

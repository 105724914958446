import { styled } from "@mui/material";

const PREFIX = "TextParser";

export const classes = {
  superscriptContainer: `${PREFIX}-superscriptContainer`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.superscriptContainer}`]: {
    width: "1.4em", // Explicit width
    height: "1.4em", // Explicit height (same as width for perfect circle)
    marginLeft: 0.5,
    padding: "0.35em", // Using em instead of the MUI spacing unit for more precise control
    fontSize: "0.7em",
    fontWeight: "bold",
    borderRadius: "50%",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));

import React from "react";
import merge from "lodash/merge";
import {
  Avatar,
  Box,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Root, classes } from "./styles";
import {
  Advisor,
  defaultAdvisor,
} from "@library/components/AdvisorProfile/types";
import { Close } from "@mui/icons-material";

const footerLanguageLocation = "step.MeetYourAdvisorStep.footerTooltip";

interface AdvisorTooltipContentProps {
  theme: Theme;
  advisor?: Partial<Advisor>;
  onClose?: () => void;
}

const AdvisorTooltipContent: React.FC<AdvisorTooltipContentProps> = ({
  theme,
  advisor = {},
  onClose,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const currentAdvisor = merge(defaultAdvisor, advisor);

  return (
    <Root>
      <Stack spacing={2} className={classes.tooltipContent}>
        {isMobile ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
            <Avatar
              alt="Advisor profile picture"
              src={currentAdvisor?.image}
              sx={{
                width: 60,
                height: 60,
                border: `1px solid ${theme.palette.primary.light}`,
              }}
            />
            <Box>
              <Typography variant="caption" className={classes.caption}>
                {theme.t(`${footerLanguageLocation}.label`)}
              </Typography>
              <Typography variant="h5" className={classes.advisorName}>
                {currentAdvisor.firstName} {currentAdvisor.lastName}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"column"}>
              <Typography variant="caption" className={classes.caption}>
                {theme.t(`${footerLanguageLocation}.label`)}
              </Typography>
              <Typography variant="h5" className={classes.advisorName}>
                {currentAdvisor.firstName} {currentAdvisor.lastName}
              </Typography>
            </Stack>

            {onClose && (
              <Close
                sx={{
                  cursor: "pointer",
                  fontSize: 16,
                  color: theme.palette.text.secondary,
                }}
                onClick={onClose}
              />
            )}
          </Stack>
        )}
        <Typography variant="body2" className={classes.advisorDescription}>
          {currentAdvisor.data?.bio}
        </Typography>
      </Stack>
    </Root>
  );
};

export default AdvisorTooltipContent;

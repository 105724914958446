import React from "react";
import { TennantConfig } from ".";
import { lightEstimateTemplates } from "./utils";

// ""
// const arthurImage = "apps/customer/public/assets/testimonialArthur.jpeg";
const arthurImage = "apps/customer/public/assets/happyCustomer.png";

const michiganSavesMarketingUrl = "https://michigansaves.org/homeowners";

export const baseConfig = {
  name: "Pearl",
  showBrands: true,
  tiers: {
    default: "base",
    recommended: "base",
    available: ["base", "pearl"],
  },
  language: {
    "en-us": {
      energyTypes: {
        natural_gas: "Natural Gas",
        propane: "Propane",
        fuel_oil: "Fuel Oil",
        electricity: "Electric",
      },
      links: {
        michiganSavesMarketingUrl: michiganSavesMarketingUrl,
      },
      tier: {
        summary:
          'Your {{tier}} Package {{newSystemType}} is a {{newSystemCapacity}}-ton {{newSystemDistribution}} system{{#if (eq newSystemFuelType "dual_fuel")}} with a dual-fuel configuration{{else}} with an all-electric configuration{{/if}}.{{#unless (eq tier "Base")}} The {{tier}} Package also includes weatherization - specifically, {{weatherizationItems}} to reduce energy waste.{{/unless}} Your new system will {{#if (eq newSystemScope "heat_cool")}}heat and cool{{else if (eq newSystemScope "heat")}} heat{{else if (eq newSystemScope "cool")}}cool{{/if}} your home and improve your comfort, {{#if (eq billImpactSeverity "greatly_decrease")}}reduce{{else if (eq billImpactSeverity "decrease")}}slightly reduce{{else if (eq billImpactSeverity "same")}}not impact{{else if (eq billImpactSeverity "increase")}}slightly increase{{else if (eq billImpactSeverity "greatly_increase")}}increase{{/if}} your monthly bills, and {{#if (eq climateImpactSeverity "greatly_decrease")}}significantly reduce{{else if (eq climateImpactSeverity "decrease")}}reduce{{else if (eq climateImpactSeverity "increase")}}increase{{else}}not affect{{/if}} your climate impact.',
        base: {
          name: "Base",
          title: "Base Package",
          snippet:
            "We recommend this package to minimize your price for a heat pump system that will efficiently heat and cool your home.",
        },
        replace: {
          name: "Replace",
          title: "Replace Package",
          snippet:
            "We recommend this package if you're interested in a like-for-like replacement of your current system.",
        },
        pearl: {
          name: "Pearl",
          title: "Pearl Package",
          snippet:
            "We recommend this package to optimize your monthly energy cost with a dual-fuel heat pump system and accompanying weatherization.",
        },
        edison: {
          name: "Edison",
          title: "Edison Package",
          snippet:
            "We recommend this package to minimize your climate impact with an all-electric heat pump system and accompanying weatherization.",
        },
      },
      LoginPageView: {
        pearlDescription: "Personalized Home Energy Solutions",
        logInAnotherWay: "Log in another way",
        noAccount: "Don't have an account?",
        signUp: "Sign Up",
        logIn: "Log In",
        logInWithEmail: "Log In With Email Address",
        logInWithGoogle: "Log In With Google Account",
        forgotPassword: "Forgot Password",
        MagicLink: {
          linkSent: "Login Link Sent!",
          pickUp: "Pick up where you left off",
          submittedMessage:
            "If we have that email in our system, look for a one-time login link in your inbox or spam folder.",
          loginLink: "Send Me a Login Link",
        },
      },
      labels: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        phoneNumber: "Phone Number",
        homeType: "Home Type",
        yearBuilt: "Year Built",
        sqFootage: "Sq Footage",
        aboveGroundStories: "Above Ground Stories",
        numBedrooms: "Number of Bedrooms",
        ceilingHeight: "Ceiling Height",
        basement: "Basement",
        attic: "Attic",
        garage: "Garage",
        rebates: {
          type: {
            enum: {
              rebate: "Rebate",
              credit: "Tax Credit",
              discount: "Discount",
            },
          },
          timing: {
            enum: {
              instant: "Instant",
              paid_later: "Post-Install",
            },
          },
          eligibility: {
            enum: {
              eligible: "Eligible",
              potentially_eligible: "Potentially Eligible",
              not_eligible: "Not Eligible",
            },
          },
        },
        projectType: {
          label: "",
          enum: {
            heating_cooling: "Heating & Cooling",
            heating: "Heating",
            air_conditioning: "Air Conditioning",
            weatherization: "Weatherization",
            water_heating: "Water Heating",
            residential_solar: "Residential Solar",
            ev_charging: "Electrical Vehicle Charging",
            energy_efficient_appliances: "Energy-Efficient Appliances",
            electrical_upgrades: "Electrical Upgrades",
            im_not_sure: "I'm not sure yet",
          },
        },
        projectMotivation: {
          label: "",
          enum: {
            support: "I need emergency support.",
            online_quote:
              "I want an online quote - I am shopping for a new system now.",
            directional_estimate:
              "I want a directional estimate - I am budgeting for the future.",
            feedback:
              "I already have a few quotes, and I'd like feedback on them.",
            incentives:
              "I want to search for available discounts, rebates and tax incentives.",
          },
        },
        heatingCoolingProjectScope: {
          label: "",
          enum: {
            heating: "Heating",
            cooling: "Air Conditioning",
            both: "Both",
          },
        },
        heatingCoolingProjectType: {
          label: "",
          enum: {
            replace: "Replace your current system",
            service: "Service or maintain your current system",
            add: "Add heat or cooling to a part of your home",
          },
        },
        heatingCoolingProjectTypeMoreInfo: {
          label: "Anything else we should know about your project?",
        },
        wholeOrPartialHome: {
          label: "",
          enum: {
            whole: "The entire home.",
            partial: "Only part of the home.",
          },
        },
        floorSize: {
          label: "Floor Size",
          enum: {
            second_floor_is_same_size:
              "2nd floor is the same size as the 1st floor.",
            second_floor_a_bit_smaller:
              "2nd floor is a bit smaller than the 1st floor.",
            second_floor_a_lot_smaller:
              "2nd floor is a lot smaller than the 1st floor.",
          },
        },
        electricUtility: {
          label: "Electricity",
        },
        gasUtility: {
          label: "Natural Gas",
        },
        basementType: {
          label: "Basement Type",
          enum: {
            below_grade: {
              title: "Underground",
              description: `Fully "below grade"`,
            },
            partially_below_grade: {
              title: "Garden Level",
              description: `Partially "below grade"`,
            },
            above_grade: {
              title: "Walk-out",
              description: `Fully "above grade"`,
            },
          },
        },
        basementFinished: {
          label: "Basement Finish",
          enum: {
            finished: "Fully finished",
            unfinished: "Completely unfinished",
            partially_finished: "Partially finished",
          },
        },
        atticType: {
          label: "Attic Type",
          enum: {
            permanent_stairs: {
              title: "Room",
              description: "Accessible via a permanent staircase",
            },
            hatch: {
              title: "Scuttle Attic",
              description: "Accessible via a ladder or a hatch",
            },
            no_attic: {
              title: "Not Accessible",
              description: "",
            },
          },
        },
        atticFinish: {
          label: "Attic Finish",
          enum: {
            finished: "Fully finished",
            unfinished: "Completely unfinished",
            partially_finished: "Partially finished",
          },
        },
        garageType: {
          label: "Garage Type",
          enum: {
            fully_detached: {
              title: "Fully Detached",
            },
            attached_with_no_living_space_above: {
              title: "Attached",
              description: "without living space above",
            },
            attached_with_living_space_above: {
              title: "Attached",
              description: "with living space above",
            },
          },
        },
        howWouldYouLikeToProceed: {
          label: "",
          enum: {
            instant_quote: {
              title: "Get an instant quote online",
              description:
                "It takes five minutes on average. Then, you’ll schedule a home assessment with your Pearl Edison Advisor.",
            },
            schedule_virtual_consultation: {
              title: "Schedule a 15-minute call",
              description:
                "You'll connect with your assigned Pearl Edison Advisor to discuss your options.",
            },
            save_progress: {
              title: "Save my progress and come back later",
              description:
                "We'll send you a link to your email address to pick this up at a later date.",
            },
          },
        },
        ductLocation: {
          label: "Duct Location",
          enum: {
            fully_conditioned: {
              title: "Fully Conditioned",
              description: "Finished basement and interior walls only",
            },
            partially_conditioned: {
              title: "Partially Conditioned",
              description: "Ducts in unfinished basement",
            },
            unconditioned: {
              title: "Unconditioned",
              description: "A vented attic",
            },
          },
        },
        ductSealing: {
          label: "Duct Sealing",
          enum: {
            poor: {
              title: "Poor",
              description: "Obvious gaps and/or dirt streaks",
            },
            moderate: {
              title: "Moderate",
              description: "No mastic tape but no obvious gaps",
            },
            good: {
              title: "Good",
              description: "Some mastic tape",
            },
            excellent: {
              title: "Excellent",
              description: "All seams sealed with mastic tape",
            },
          },
        },
        rooms: {
          type: {
            label: "Room type?",
            enum: {
              BEDROOM: "Bedroom",
              BATHROOM: "Bathroom",
              KITCHEN: "Kitchen",
              DINING_ROOM: "Dining Room",
              LIVING_ROOM: "Living Room",
              MASTER_BEDROOM: "Bedroom",
              MASTER_BATHROOM: "Bathroom",
              REC_ROOM: "Recreation Room",
              MEDIA_ROOM: "Media Room",
              OFFICE: "Office",
              DEN: "Den",
              FOYER: "Foyer",
              LAUNDRY_ROOM: "Laundry Room",
            },
          },
          name: {
            label: "Room name?",
          },
          comfort: {
            label: "Comfort",
          },
          isComfortIssue: {
            label: "I have a comfort issue in this room",
          },
          comfortIssueFreqency: {
            label: "When does it occur?",
            enum: {
              always: "Always",
              summer: "Summer",
              winter: "Winter",
            },
          },
          comfortIssueInfo: {
            label: "Tell us about your comfort issue. (Optional)",
          },
          floor: {
            label: "Which floor?",
            enum: {
              basement: "Basement",
              "1": "1st floor",
              "2": "2nd floor",
              "3": "3rd floor",
              attic: "Attic",
            },
          },
          size: {
            label: "What size?",
            enum: {
              SMALL: "Small",
              MEDIUM: "Medium",
              LARGE: "Large",
              XL: "XL",
              XXL: "XXL",
            },
          },
          sqft: {
            label: "Override room size:",
          },
          windowSqft: {
            label: "Override window size:",
          },
          wallsWithWindows: {
            label: "How many windows?",
            enum: {
              none: "None",
              "just one": "Just one.",
              "a few": "A few.",
              "a lot": "A lot.",
              "huge ones": "Huge ones!",
            },
          },
          isCurrentlyHeated: {
            label: "Currently heat?",
          },
          isHeatComfortIssue: { label: "Comfort issues?" },
          heatComfortIssueInfo: { label: "Please explain. (Optional)" },
          isCurrentlyAirConditioned: { label: "Has A/C now?" },
          isAirConditionComfortIssue: { label: "Comfort Issues?" },
          airConditionComfortIssueInfo: { label: "Please explain. (Optional)" },
        },
        heatingEquipmentType: {
          label: "Primary Heating System Type",
          enum: {
            furnace_with_ducts: "Furnace with ductwork",
            boiler_with_radiators: "Boiler with radiators",
            electric_resistive_with_baseboards:
              "Electric resistive heat with baseboards",
            heat_pump_mini_split: "Heat pump, also known as a “mini split”",
            none_of_these: "None of these match my heating system",
          },
        },
        heatingEquipmentUsage: {
          enum: {
            always_on:
              "The system is always on - I rarely touch the thermostat.",
            frequency_adjustments:
              "I adjust the thermostat frequently - for example, when I leave the house.",
            rarely_use: "I don't use the system unless it's really cold out.",
          },
        },
        heatingEquipmentTypeMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        fuelSource: {
          label: "Primary Heating System Fuel",
          enum: {
            natural_gas: "Natural gas",
            fuel_oil: "Fuel oil",
            electricity: "Electricity",
            propane: "Propane",
            something_else: "Another fuel source",
            i_don_t_know: "I don't know",
          },
        },
        coolingEquipmentType: {
          label: "Primary Air Conditioning System Type",
          enum: {
            central_air_conditioning: "Central air conditioning",
            window_unit_air_conditioning: "Window-unit air conditioning",
            evaporative_cooling: "Evaporative cooling",
            heat_pump_mini_split: 'Heat pump, also known as a "Mini Split"',
            something_else: "Another system",
            no_air_conditioning: "No air conditioning",
          },
        },
        coolingEquipmentUsage: {
          enum: {
            always_on:
              "The system is always on - I rarely touch the thermostat.",
            frequency_adjustments:
              "I adjust the thermostat frequently - for example, when I leave the house.",
            rarely_use: "I don't use the system unless it's really warm out.",
          },
        },
        coolingEquipmentTypeMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        materials: {
          exteriorWalls: { label: "Exterior Walls" },
          interiorWalls: { label: "Interior Walls" },
        },
        exteriorWalls: {
          label: "Exterior Walls",
          enum: {
            stucco: "Stucco",
            vinyl: "Vinyl",
            fiber_cement: "Fiber Cement",
            brick: "Brick",
            wood: "Wood",
            stone: "Stone",
            metal: "Metal",
          },
        },
        interiorWalls: {
          label: "Interior Walls",
          enum: {
            stucco: "Stucco",
            drywall: "Drywall",
            plaster: "Plaster",
            brick: "Brick",
            wood_panel: "Wood Panel",
            concrete: "Concrete",
            ceramic_tile: "Ceramic Tile",
          },
        },
        numWindows: {
          enum: {
            a_few: "Few",
            average: "Average",
            a_lot: "Many",
          },
        },
        walls: {
          label: "How many walls have windows?",
        },
        wallRValue: {
          label: "Wall",
        },
        ceilingRValue: {
          label: "Ceiling",
        },
        floorRValue: {
          label: "Floor",
        },
        windowRValue: {
          label: "Window",
        },
        ach50: {
          label: "Blower Door - ACH50",
        },
        ductLossHeating: {
          label: "Winter Duct Losses",
        },
        ductLossCooling: {
          label: "Summer Duct Losses",
        },
        windowType: {
          enum: {
            single: "Single-pane",
            double: "Double-pane",
            triple: "Triple-pane",
            im_not_sure: "I don't know",
          },
        },
        upgrades: {
          label: "Upgrades",
          enum: {
            added_insulation: {
              title: "Added insulation",
              // description: "Lorem ipsum dolor sit amet",
            },
            upgraded_windows: {
              title: "Upgraded windows",
            },
            air_sealing: {
              title: "Air sealing",
            },
            upgraded_electrical_panel: {
              title: "Upgraded electrical panel",
            },
            duct_insulation_sealing: {
              title: "Duct sealing / insulation",
            },
            something_else: {
              title: "Something else",
            },
          },
        },
        upgradesMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        airSealingScope: {
          label: "Scope",
          enum: {
            whole: "Whole-Home",
            targeted: "Targeted",
            i_don_t_know: "I don't know",
          },
        },
        airSealingInstallation: {
          label: "Installation",
          enum: {
            professional: "Professional",
            diy: "DIY",
            i_don_t_know: "I don't know",
          },
        },
        ductSealingScope: {
          label: "Scope",
          enum: {
            tape_mastic: "Tape / Mastic",
            aeroseal: "AeroSeal",
            i_don_t_know: "I don't know",
          },
        },
        ductSealingInstallation: {
          label: "Installation",
          enum: {
            professional: "Professional",
            diy: "DIY",
            i_don_t_know: "I don't know",
          },
        },
        atticInsulation: {
          enum: {
            yes: "Attic / ceiling insulation",
          },
        },
        atticInsulationType: {
          label: "Insulation type",
          enum: {
            undefined: "",
            loose_fill_cellulose: "Loose-fill cellulose",
            dense_packed_cellulose: "Dense-packed cellulose",
            fiberglass_batts: "Fiberglass batts",
            rigid_foam_board: "Rigid foam board",
            i_don_t_know: "I don't know",
          },
        },
        basementInsulation: {
          enum: {
            yes: "Basement / floor insulation",
          },
        },
        basementInsulationType: {
          label: "Insulation type",
          enum: {
            loose_fill_cellulose: "Loose-fill cellulose",
            dense_packed_cellulose: "Dense-packed cellulose",
            fiberglass_batts: "Fiberglass batts",
            rigid_foam_board: "Rigid foam board",
            i_don_t_know: "I don't know",
          },
        },
        wallInsulation: {
          enum: {
            yes: "Wall insulation",
          },
        },
        wallInsulationType: {
          label: "Insulation type",
          enum: {
            undefined: "",
            loose_fill_cellulose: "Loose-fill cellulose",
            dense_packed_cellulose: "Dense-packed cellulose",
            fiberglass_batts: "Fiberglass batts",
            rigid_foam_board: "Rigid foam board",
            i_don_t_know: "I don't know",
          },
        },
        rimJoistInsulation: {
          enum: {
            yes: "Rim joist insulation",
          },
        },
        rimJoistInsulationType: {
          label: "Insulation type",
          enum: {
            undefined: "",
            loose_fill_cellulose: "Loose-fill cellulose",
            dense_packed_cellulose: "Dense-packed cellulose",
            fiberglass_batts: "Fiberglass batts",
            rigid_foam_board: "Rigid foam board",
            i_don_t_know: "I don't know",
          },
        },
        issues: {
          label: "Issues",
          enum: {
            roof_damage: "Roof damage",
            asbestos: "Asbestos",
            wall_damage: "Wall damage",
            visible_mold: "Visible mold",
            missing_windows: "Missing windows",
            standing_water: "Standing water",
            electrical_issues: "Electrical issues",
            indoor_air_quality: "Indoor air quality",
            structural: {
              title: "Structural damage",
              description: "(e.g. walls, windows, roof)",
            },
            electrical: {
              title: "Electrical issues",
              description: "(e.g. flickering, knob-and-tube)",
            },
            health_and_safety: {
              title: "Potential health and safety issues",
              description: "(e.g. asbestos, radon, mold / moisture)",
            },
          },
        },
        issuesMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        electricalPanelRating: {
          enum: {
            lt_100: "100 amps or less",
            between_100_200: "Between 100 and 200 amps",
            gt_200: "200 amps or more",
            i_don_t_know: "I don't know",
          },
        },
        heatingEquipmentTypeAge: {
          enum: {
            lt_5: "Less than 5 years old",
            between_5_10: "5 to 10 years old",
            between_10_15: "10 to 15 years old",
            gt_15: "More than 15 years old",
            i_don_t_know: "I don't know",
          },
        },
        coolingEquipmentTypeAge: {
          enum: {
            lt_5: "Less than 5 years old",
            between_5_10: "5 to 10 years old",
            between_10_15: "10 to 15 years old",
            gt_15: "More than 15 years old",
            i_don_t_know: "I don't know",
          },
        },
        shortCycles: {
          enum: {
            yes: "Yes",
            no: "No",
            i_don_t_know: "I don't know",
          },
        },
        noisyDuctwork: {
          enum: {
            yes: "Yes",
            no: "No",
            i_don_t_know: "I don't know",
          },
        },
        draftyStuffy: {
          // @DEPRECATED
          label: "Drafty / Stuffy",
          enum: {
            yes_drafty:
              "Yes, there can be cool drafts even when windows are shut.",
            yes_stuffy:
              "Yes, sometimes the air quality can feel stuffy or uncomfortable.",
            no: "All good!",
          },
        },
        draftyStuffyMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        drafty: {
          enum: {
            yes: "Yes",
            no: "No",
          },
        },
        stuffy: {
          enum: {
            yes: "Yes",
            no: "No",
          },
        },
        homeQuality: {
          label: "Home Quality",
          enum: {
            yes: "Yes!",
            no_always_cold: "No, too cold in the winter.",
            no_always_hot: "No, too hot in the summer.",
            uneven: "No, some rooms are too hot or too cold.",
          },
        },
        homeQualityMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        whyAreYouShoppingToday: {
          label: "Why Are You Shopping",
          enum: {
            equipmentBroken: "My equipment is broken.",
            equipmentLastLeg: "My equipment is old, I want to replace it.",
            moreEfficientSystem: "I want a more efficient system.",
            justBrowsing: "I'm just browsing.",
          },
        },
        whatsImportantToYou: {
          label: "What's Important To You",
          enum: {
            lowUpFrontCost: "Low up-front cost",
            lowerMonthlyBills: "Reduce energy costs",
            climateImpact: "Climate impact",
            betterComfort: "Comfort",
            somethingElse: "Other",
          },
        },
        whatsImportantToYouInfo: {
          label: "Anything else we should know? (Optional)",
        },
        ownOrRent: {
          enum: {
            own: "I own my home",
            rent: "I rent my home",
          },
        },
        projectTimeline: {
          enum: {
            asap: "ASAP",
            flexible: "Flexible",
            i_don_t_know: "I don't know",
          },
        },
        peopleLiveInHome: {
          label: "People Live In Home",
        },
        communicationPreference: {
          label: "Communication Preference",
          enum: {
            email: "Email",
            text: "Text",
            call: "Call",
          },
        },
      },
      step: {
        SchedulingAddressStep: {
          title: "What's your address?",
        },
        SchedulingCommunicationStep: {
          title: "Great, we can help with your {projectTypeClean} project.",
          description:
            "Please provide your name and contact information. Then, we'll schedule your audit.",
        },
        SchedulingStep: {
          title: "Thanks. Schedule your consultation.",
          description: "Reach us any time at {constants.PHONE_NUMBER}",
        },
        NotOperationalStep: {
          title: "We are not currently operating in {user.city}, {user.state}",
          description:
            "We’re serving Michigan today, but expanding quickly. If you're interested, we can add you to our outreach list and get in touch when we reach your area.",
        },
        BeInTouchStep: {
          title: "Consultation scheduled!",
          description: "Check your inbox for an email with more details.",
        },
        ProjectTypeStep: {
          title: "Welcome to Pearl Edison Concierge!",
          description: "What type of project are you working on?",
        },
        HeatingCoolingProjectScopeStep: {
          title: "Great! Tell us about your project.",
          description:
            "Do you need support with heating, air conditioning, or both?",
        },
        HeatingCoolingProjectStep: {
          title: "Great! Tell us a bit about your project.",
          description: "Do you want to...",
        },
        HeatingOrAirConditioningServiceAddressStep: {
          title: "What’s your address?",
          description:
            "We use this information to tailor our recommendation for your unique home.",
        },
        HeatingOrAirConditioningServiceStep: {
          title: "Great, let's schedule service.",
          description: "Please provide your name and contact information.",
        },
        HeatingOrAirConditioningScheduleServiceStep: {
          title: "Thanks. You can schedule time or call for immediate support.",
          description: "For immediate support, call {constants.PHONE_NUMBER}",
        },
        HeatingOrAirConditioningReplacementStep: {
          title: "Great, we can support you with that.",
          description: "How do you want to get started?",
        },
        HeatingOrAirConditioningReplaceEmergencyStep: {
          title: "We can help with your emergency.",
          description:
            "Please provide your name and contact information. Or, you can reach us anytime at {constants.PHONE_NUMBER}",
        },
        HeatingOrAirConditioningReplaceEmergencyScheduleStep: {
          title: "Thanks. You can schedule time or call for immediate support.",
          description: "For immediate support, call {constants.PHONE_NUMBER}",
        },
        HeatingOrAirConditioningWholeOrPartialStep: {
          title:
            "Will this system serve the entire home, or only part of the home?",
          description: "",
        },
        LetsGetStartedStep: {
          title: "Get started today!",
          description: "Pearl Edison is your Energy Efficiency Concierge.",
          buttons: {
            seeProcess: "See Our Process",
            startEstimate: "Start Your Estimate",
            learnMore: "Learn More",
          },
          dialogTitles: {
            process: "See Our Process",
            sample: "See A Sample",
            advisor: "Meet An Advisor",
          },
          todaySection: {
            title: "Today you can:",
            estimate: {
              title: "Get an instant estimate",
              timeframe: "Takes about 5 minutes.",
              description:
                "We use details about your project, home, and goals to give you an instant estimate, including rebates, so you can decide if you're interested.",
            },
            advisor: {
              title: "Connect with a Pearl Edison Advisor",
              timeframe: "Usually scheduled in one day.",
              description:
                "An energy efficiency expert certified by the Building Performance Institute will support you.",
              button: "Meet An Advisor",
            },
            sampleButton: "See A Sample",
          },
          futureSection: {
            title: "Then, when you're ready, you can:",
            assessment: {
              title: "Schedule a free home assessment",
              timeframe: "Usually scheduled within one week.",
              description:
                "We’ll perform a home assessment to finalize your system design options. After that, your price and scope are guaranteed.",
            },
            project: {
              title: "Approve your project",
              subtitle: "Move at your pace.",
              description:
                "Your Pearl Edison Advisor will help you select the right system for your unique home and goals.",
            },
            contractor: {
              title: "Meet your contractor",
              description:
                "Your install will be performed by a high-quality local installer. Every Pearl Edison contractor is licensed and rigorously vetted - and your Pearl Edison Advisor is there to support you every step of the way.",
            },
          },
        },
        UploadQuoteCommunicationStep: {
          title: "Great, we can help with reviewing your existing quote.",
          description: "Please provide your name and contact information.",
        },
        UploadQuoteStep: {
          title: "Upload your quote to get started.",
          description:
            "Your Pearl Edison Advisor will review and provide feedback.",
        },
        IncentivesAddressStep: {
          title: "What's your address?",
          description:
            "We'll search for available discounts, rebates and tax incentives.",
        },
        EligbleRebateStep: {
          title:
            "We found up to ${eligibleRebateTotalCap} in discounts, rebates and tax credits you could be eligible for.",
          description:
            "Do you want to check your eligibility now? It takes ~2 minutes.",
        },
        PotentiallyEligbleRebateStep: {
          title:
            "You're potentially eligible for up to ${eligibleRebateTotalCap}.",
          description:
            "We'll show you a few system design options for your project that are eligible for these rebates and incentives.",
        },
        RebateInformationStep: {
          title: "Great! A quick note before we get started.",
          description:
            "Your eligibility is determined by the rebate provider and depends on where you live, whether you own your home, how much money you make, how many dependents you support, and other home and project details. We will verify your eligibility during your home assessment.",
        },
        UtilityCompanyStep: {
          title: "Which utilities serve you?",
          description:
            "Note: This could be different for electricity and natural gas. Think about who sends your bill.",
        },
        SideBar: {
          collapsed: {
            title: "Full Estimate",
            estimatedCost: {
              label: "Upfront Cost",
            },
            billImpact: {
              label: "Bill Impact",
              perYear: "/yr",
            },
            climateImpact: {
              label: "Climate Impact",
              unit: "MT/yr",
            },
          },
          expanded: {
            title: "Live Estimate",
            systemSelection: {
              label: "When using:",
            },
            estimatedCost: {
              title: "Estimated Upfront Cost",
              subtitle: "Your up-front cost after applying instant rebates",
            },
            billImpact: {
              title: "Annual Bill Impact",
              subtitle: "Based on homes that are similar to yours",
              perYear: "/ yr",
            },
            climateImpact: {
              title: "Annual Climate Impact",
              subtitle: "Based on homes that are similar to yours",
              unit: "metric tons CO2",
            },
          },
        },
        LightEstimateOptionsStep: {
          title:
            "Based on the info you provided, here are a few initial options.",
          description:
            "Click ‘Next’ to refine your options with additional home details or discuss with an Advisor",
          readMore: {
            more: "Read More",
            less: "Show Less",
          },
          installedCost: {
            title: "Installed Cost",
            subtitle: "Before instant rebates",
          },
          yourPrice: {
            title: "Your Upfront Cost",
            subtitle: "After instant rebates",
            rangedPriceDescription:
              "Your price depends on unique details about your home. Based on what you've shared so far, we expect your price to fall in this range.",
          },
          potentialAdditionalIncentives: {
            title: "Potential Additional Incentives",
            subtitle: "Rebates and incentives that pay later",
          },
          monthlyCost: {
            title: "Monthly Cost",
            subtitle: "w/ Financing",
            perMonth: "/ mo",
          },
          instantRebates: {
            title: "Rebates",
          },
          annualBill: {
            title: "Annual Bill Impact",
            subtitle:
              "Estimate based on similar homes - your Pearl Edison advisor will refine after your Home Assessment",
            perYear: "/ yr",
          },
          climateImpact: {
            title: "Annual Climate Impact",
            subtitle: "Estimate based on similar homes",
            unit: "metric tons CO2",
          },
          lightOptionsCopy: {
            equipmentTitle: lightEstimateTemplates.equipmentTitle,
            equipmentSubtitle: lightEstimateTemplates.equipmentSubtitle,
            equipmentImageUrl: lightEstimateTemplates.equipmentImageUrl,
            description: lightEstimateTemplates.description,
            bullets: lightEstimateTemplates.bullets,
          },
        },
        InstantEstimateStep: {
          title: "Your Instant Estimate is ready!",
          description:
            "You can view it now or go back to edit your selections.",
        },
        HowWouldYouLikeToProceedStep: {
          title: "How would you like to proceed?",
        },
        HeatingAndCoolingSchedulingStep: {
          title: "Thanks! Please schedule your consultation.",
          description: "Reach us any time at {constants.PHONE_NUMBER}",
        },
        AccountCreationStep: {
          title: "Create an account to save your quote",
          description:
            "We'll send you a link so you can access your instant quote after it's complete.",
        },
        PostSaveProgressStep: {
          title: "Your progress has been saved",
          description: [
            "You can continue with your Instant Estimate now or we've sent an email with a login link that expires in 1 day so you can finish later. You may request a new magic link at any time from the ",
            <a href="/login">login page</a>,
            ".",
          ],
        },

        MeetYourAdvisorStep: {
          title: "You're ready for the next step.",
          description:
            "Now, we'll ask a few more questions about your home, your current systems, and your goals for this project.",
          // FYI: Bullets are not currently used per Evan's direct request
          bullets: {
            first: {
              text: "Below, meet your dedicated Pearl Edison Advisor. We're here anytime you need us.",
            },
            second: {
              text: "On the right you'll find your estimate summary. Keep an eye on this as you provide more information to see how it impacts your estimate.",
            },
            third: {
              text: "Now, we'll ask a few more questions about your home, your current systems, and your goals for this project.",
            },
          },
          footerTooltip: {
            label: "Your Pearl Edison Advisor",
            description:
              "Jake is a Michigan-based advisor for Pearl Edison. He is certified as a Building Analyst by the Building Performance Institute, as both a Building Analyst Technician (BA-T) and Building Analyst Professional (BA-P). He specializes in home energy auditing, HVAC system design, and duct design with a broader background in renewable energy.",
          },
          sidebarTooltip: {
            title: "Live Estimate",
            description:
              "As you provide more information, you’ll be able to see the impact. Feel free to switch between options as you go.",
          },
        },
        AddressStep: {
          title: "What’s your address?",
          description:
            "We use this information to tailor our recommendation for your unique home.",
        },
        OnboardingStep: { title: "", description: "" },
        GettingStartedStep: {
          title: "Let’s get started.",
          description:
            "We’ll help you design the right efficient heating and air conditioning system for your unique home and goals. It only takes 10 minutes.",
          step1Title: "Get your instant design and estimate",
          step1Description:
            "Tell us about your home and goals, then find your rebates. We’ll design the right system for your unique home and goals.",
          step1Time: "~10 min",
          step2Title: "Meet your advisor",
          step2Description:
            "We’ll confirm final details, answer your questions, and finalize your design.",
          step2Time: "within 1 day",
          step3Title: "Electrify your home",
          step3Description:
            "When you’re ready, we’ll schedule your installation with one of our licensed, vetted, experienced contractor partners.",
          step3Time: "within 2 weeks",
        },
        HomeConfirmationStep: {
          title: "Is this your home?",
          description: "",
        },
        FullNameStep: {
          title: "Tell us about yourself.",
          description: "We'll send you a link to view your estimate.",
        },
        HomeProfileStep: {
          title: "Please confirm details about your home.",
          description: "",
          yourHome: "Your Home",
          fullRecordTitle: "Here's what we found. Does this look right?",
          partialRecordTitle: "Here's what we found.",
          fullRecordDescription:
            "This information is important, so please review and verify.",
          partialRecordDescription:
            "There are a few gaps in our data. Please review and verify.",
          serviceMessage:
            "We're proud to serve the City of {city}, and excited for the opportunity to serve you.",
          sections: {
            homeDetails: "Home Details",
            hvacSystem: "HVAC System",
          },
        },
        FloorSizeStep: {
          title: "How large is each floor?",
          description: "",
        },
        BasementTypeStep: {
          title: "Which basement is most similar to yours?",
          description: "",
          whyTitle: "Why are we asking?",
          whyDescription:
            "This helps us estimate heat loss from your basement. Basements can account for about 25% of your home's total heat gain / loss.",
          sidebarTooltip: {
            title: "Express Estimate",
            description:
              "We've collapsed this for now so you can focus on selecting your basement type.",
          },
        },
        BasementFinishedStep: {
          title: "Is your basement finished?",
          description: "",
          whyTitle: "Why are we asking?",
          whyDescription:
            "This helps us estimate heat loss from your basement. Basements can account for about 25% of your home's total heat gain / loss.",
        },
        AtticTypeStep: {
          title: "Which attic is most similar to yours?",
          description: "",
          whyDescription:
            "This helps us estimate heat loss from your attic and exterior ceiling.  Your attic and exterior ceiling can account for as much as 40% of your home's total heat gain / loss.",
        },
        AtticFinishedStep: {
          title: "Is your attic finished?",
          description: "",
        },
        GarageStep: {
          title: "Which garage is most similar to yours?",
          description: "",
          whyDescription:
            "This helps us estimate heat loss through your unconditioned garage.",
        },
        FloorPlanStep: {
          title: "List all of the rooms in your home",
          description: "",
          whyDescription:
            "Your home doesn't have existing ductwork we can leverage, so we use this information to determine how to size and where to site indoor equipment in order to ensure you're comfortable.",
        },
        RoomsConfigurationStep: {
          title: "Tell us a bit more about each room.",
          description: "",
          whyDescription:
            "Your home doesn't have existing ductwork we can leverage, so we use this information to determine how to size and where to site indoor equipment in order to ensure you're comfortable.",
        },
        RValuesStep: {
          title: "R-values",
          description: "",
        },
        InfiltrationAndDuctLossesStep: {
          title: "Infiltration and Duct Losses",
          description: "",
        },
        NumberOfWindowsStep: {
          title: "How many windows does your home have?",
          description: "",
        },
        HeatingEquipmentStep: {
          title: "What kind of heating equipment do you currently have?",
          description: "",
        },
        FuelSourceStep: {
          title: 'What is your "fuel source" for heating?',
          description: "",
        },
        HeatRoomsStep: {
          title:
            "Which rooms do you heat today? Do you experience any winter comfort issues?",
          description: "",
        },
        AirConditioningStep: {
          title:
            "What kind of air conditioning equipment do you currently have?",
          description: "",
        },
        AirConditioningRoomsStep: {
          title:
            "Which rooms have air conditioning today? Do you experience any summer comfort issues?",
          description: "",
        },
        MaterialsSteps: {
          title: "What material are (most of) your walls made of?",
          description: "",
        },
        WindowsStep: {
          title: "What kind of windows do you have?",
          description: "",
          whyDescription:
            "This helps us estimate the heat loss from your windows. Windows can account about 25% or more of your home's total heat gain / loss.",
        },
        UpgradeStep: {
          title: "Have you made any recent energy efficiency investments?",
          description: "",
          whyDescription:
            "These investments make your home more efficient and reduce the size of the system you need to serve your home. If you made a recent investment, then we'll update our system design and recommendation accordingly.",
        },
        AirSealingInvestmentStep: {
          title:
            "Please share a few details about your air sealing investment.",
          description: "",
          whyDescription:
            "These investments make your home more efficient and reduce the size of the system you need to serve your home. If you made a recent investment, then we'll update our system design and recommendation accordingly.",
        },
        DuctSealingInvestmentStep: {
          title:
            "Please share a few details about your duct sealing investment.",
          description: "",
          whyDescription:
            "These investments make your home more efficient and reduce the size of the system you need to serve your home. If you made a recent investment, then we'll update our system design and recommendation accordingly.",
        },
        InsulationInvestmentStep: {
          title: "Please share a few details about your insulation investment.",
          description: "",
          whyDescription:
            "These investments make your home more efficient and reduce the size of the system you need to serve your home. If you made a recent investment, then we'll update our system design and recommendation accordingly.",
        },
        IssuesStep: {
          title: "Does your home have any of the following issues?",
          description: "",
          whyDescription:
            "These issues are typically show-stoppers. They need to be fixed before we can replace your heating or air conditioning system. Your Pearl Edison Advisor will discuss this with you during your virtual consultation.",
        },
        ElectricalPanelRatingStep: {
          title: "Do you know the rating of your electical panel?",
          description: "",
          whyDescription:
            "This helps us evaluate whether we need to upgrade your electical service as part of this project - or, to design a system that will work without upgrading your electrical service.",
        },
        HeatingEquipmentAgeStep: {
          title:
            "How old is your {heatingEquipmentFuelClean} {heatingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings. Older systems are typically less efficient, because older technology was less efficient and because performance degrades over time.",
        },
        HeatingEquipmentRatedEfficiencyStep: {
          title:
            "What is the rated efficiency of your {heatingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings. A less efficient system costs more to run.",
        },
        HeatingEquipmentRatedHSPFEfficiencyStep: {
          title:
            "What is the rated efficiency of your {heatingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings. Older systems are typically less efficient, because older technology was less efficient and because performance degrades over time.",
        },
        CoolingEquipmentAgeStep: {
          title: "How old is your {coolingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings. Older systems are typically less efficient, because older technology was less efficient and because performance degrades over time.",
        },
        HeatingEquipmentRatedCapacityStep: {
          title:
            "What is the rated capacity of your {heatingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings. An oversized system operates less efficiently - and over 90% of systems are oversized.",
        },
        CoolingEquipmentRatedEfficiencyStep: {
          title:
            "What is the rated efficiency of your {coolingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings. A less efficient system costs more to run.",
        },
        CoolingEquipmentRatedCapacityStep: {
          title:
            "What is the rated capacity of your {coolingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings. An oversized system operates less efficiently - and over 90% of systems are oversized.",
        },
        SystemShortCyclesStep: {
          title:
            "Does your heating or air conditioning system turn on and off frequently in short bursts?",
          description: "",
          whyDescription:
            'We use this information to analyze potential utility bill savings.  It\'s >10% less efficient for your system to frequently turn on and off - called "short cycling" - instead of running constantly.  This also reduces the life of your equipment!',
        },
        NoisyDuctworkStep: {
          title: "Is your ductwork noisy?",
          description: "",
          whyDescription:
            "We use this information to analyze potential utility bill savings.  If your ductwork is noisy, then static pressure is building up because your ductwork is undersized.  This decreases the efficiency of your system and reduces the life of the equipment.",
        },
        TemperatureStep: {
          title: "What temperature do you usually set the thermostat at?",
          description: "",
          whyDescription:
            "How you use your system impacts both system design and potential utility bill savings. We ask this question to improve the accuracy of our Instant Estimate.",
        },
        DraftyStuffyStep: {
          title: 'Does your home ever feel "drafty" or "stuffy"?',
          description: "",
        },
        DraftyStep: {
          title: 'Does your home ever feel "drafty"?',
          description: "",
          whyDescription:
            "This helps us estimate heat loss from infiltration. In a typical home, infiltration can account for up to 30% of total heat loss, meaning that a significant portion of heating energy is used to warm air that leaks into the building through cracks and gaps in the building envelope.",
        },
        StuffyStep: {
          title: 'Does your home ever feel "stuffy"?',
          description: "",
          whyDescription:
            "This helps us evaluate if your home is properly ventilated. If your home isn't properly ventilated, then this could be a sign of an indoor air quality issue. We'll check this during your Home Assessment.",
        },
        HomeQualityStep: {
          title: "Is the temperature of your home usually comfortable?",
          description: "",
        },
        HeatingEquipmentUsageStep: {
          title: "How do you typically use your {heatingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "How you use your system impacts both system design and potential utility bill savings.  We ask this question to improve the accuracy of our Instant Estimate.",
        },
        CoolingEquipmentUsageStep: {
          title: "How do you typically use your {coolingEquipmentTypeClean.0}?",
          description: "",
          whyDescription:
            "How you use your system impacts both system design and potential utility bill savings.  We ask this question to improve the accuracy of our Instant Estimate.",
        },
        ProjectTimelineStep: {
          title: "What's your timeline for this project?",
          description: "",
        },
        WhyAreYouShoppingStep: {
          title: "Why are you shopping today?",
          description: "",
        },
        WhatsImportantToYouStep: {
          title: "What's important to you?",
          description: "",
        },
        AlmostDone: {
          title: "Almost done.",
          description: "Let‘s find your rebates.",
        },
        OwnOrRentStep: {
          title: "Do you own your home?",
          description:
            "Note: Some rebate programs require that you own and occupy your home to be eligible.",
        },
        PeopleLiveInHomeStep: {
          title: "How many people live in your home?",
          description:
            "Note: This includes you, a spouse if you have one, your children, and other people that live with you - anyone that you financially support.",
        },
        HouseHoldIncomeStep: {
          title: "What is your annual household income?",
          description:
            "Note: This includes income earned by every member of the household. This includes you, a spouse if you have one, your children, and other people that live with you -- anyone that you financially support.",
        },
        CommunicationPreferencesStep: {
          title: "Communication Preference",
          description: "",
        },
        FinalStep: {
          title: "Are you ready to see your quote?",
          description: 'Pressing "submit" will generate your estimate.',
        },
        AssessmentFloorplans: {
          title: "Upload Your Floorplan",
          description: "Annotate your image in the next step.",
        },
        AssessmentSystemDesign: {},
        AssessmentWalkthroughPhotos: { title: "Upload Walkthrough Photos" },
        GetEstimates: { title: "Creating your estimates!", description: "" },
      },
      concierge: {
        title: ", here is your instant estimate!", // prepended by user's name
        loadingTitle: ", loading your instant estimate!", // prepended by user's name
        description:
          "Review your options for efficient heating and cooling. Then, connect with your Pearl Edison Advisor to finalize your quote.",
        ScheduleFooter: {
          finalizeButtonDescription: "Ready to finalize your quote?",
          finalizeButtonLabel: "Schedule a Home Assessment",
          consultationButtonDescription: "Still have questions?",
          consultationButtonLabel: "Schedule a 15-Minute Call",
          yourAdvisor: "Your Pearl Edison Advisor",
        },
        SchedulingDialog: {
          done: "Done",
          nextStep: "Take the next step!",
          schedule: "Schedule a", // appended with title
          assessment: {
            duration: "1 hr 30 min",
            title: "Home Assessment",
            subtitle: "Ready to finalize your quote?",
            eventDescription:
              "Please select a day and time that works for you to have a certified, Pearl Edison contractor visit your home for an in-person assessment.",
          },
          consultation: {
            duration: "1 hr",
            title: "Virtual Consultation",
            subtitle: "Still have questions?",
            eventDescription:
              "Please select a day and time that works for you to have a conversation with a Pearl Edison specialist. We are Looking forward to discussing your home!",
          },
        },
        SwiperCarousel: {
          title: "Your options for", // appended with address
        },
        PackageDetails: {
          PackageCard: {
            packageTitle: "Package", // for "{tier} Package"
            recommendedText: "Recommended for you",
            pricingOptions: {
              title: "Pricing Options",
              singlePayment: "Single Payment:",
              financeIntro: "Finance for as little as",
              financeDetails: "over 10 years, with $0 down.",
            },
            recommendations: {
              billImpactExplanation: {
                increase:
                  "Based on similar homes in {user.city}, we estimate that your bills will increase by about ${billImpactEstimateAbs} annually, with higher bills in the winter and lower bills in the summer. Note: This estimate is dependent on weather, energy prices, and your energy usage.",
                decrease:
                  "Based on similar homes in {user.city}, we estimate that your bills will decrease by about ${billImpactEstimateAbs} annually, with higher bills in the winter and lower bills in the summer. Note: This estimate is dependent on weather, energy prices, and your energy usage.",
                same: "Based on similar homes in {user.city}, we estimate that your bills will stay the same annually, with higher bills in the winter and lower bills in the summer. Note: This estimate is dependent on weather, energy prices, and your energy usage.",
              },
              climateImpactExplanation: {
                increase:
                  "Based on similar homes in {user.city}, we estimate that you will incrase your carbon footprint by at least {climateImpactEstimateAbs} metric tons annually, with climate benefits increasing across the life of your system.",
                decrease:
                  "Based on similar homes in {user.city}, we estimate that you will decrase your carbon footprint by at least {climateImpactEstimateAbs} metric tons annually, with climate benefits increasing across the life of your system.",
                same: "Based on similar homes in {user.city}, we estimate that your carbon footprint will stay the same.",
              },
            },
            impacts: {
              annual: {
                title: "Annual Bill Impact:",
                units: " / yr",
                tbd: "TBD",
              },
              climate: {
                title: "Annual Climate Impact:",
                units: " metric tons CO2",
                tbd: "TBD",
              },
            },
            footer: {
              currentlyViewing: "Currently Viewing this package",
              learnMore: "Learn More",
            },
          },
          SummaryContent: {
            subtitle: "Understanding Your System",
            learnMore: "Learn More",
            summaryQuote: "Summary Quote",
            cost: "Cost",
            rebates: "Instant Rebates",
            costAfterRebates: "Cost After Instant Rebates",
            potentialFinancing: `Pay as little as {amount} per month with <a href='${michiganSavesMarketingUrl}' target='_blank' rel='noopener noreferrer'>flexible financing</a> from Michigan Saves.`,
          },
          ScopeContent: {
            title: "Scope of Work",
            description:
              "We designed an efficient heating and cooling system based on your unique home and the goals and preferences you shared. See your detailed scope of work below. Your Pearl Edison Advisor can answer an of your questions!",
            total: "Total Cost Before Rebates",
            scope: "Scope",
            learnMore: "Learn More",
            otherOptions: "Other Options",
            installationNote:
              "Your installation will be performed by a Pearl Edison contractor.",
            options: {
              fasterInstallation: {
                title: "Need a faster installation?",
                description: "Have an urgent need? We've got you covered.",
                dialogSubtitle: "Have an urgent need?",
                dialogText:
                  "Call {PHONE_NUMBER} or confirm your number below and we'll be in touch ASAP.",
                dialogButtonText: "Contact Me",
              },
              customizeSystem: {
                title: "Want to customize your system?",
                description:
                  "You can modify the scope to better reflect your goals.",
                dialogButtonText: "Confirm Preference",
                options: {
                  waterHeater: {
                    title: "Water Heater",
                    description:
                      "Replacing your water heater could result in additional savings.",
                  },
                  airQuality: {
                    title: "Air Quality",
                    description:
                      "Consider investing in air purification, filtration, and humidification.",
                  },
                  insulation: {
                    title: "Insulation",
                    description:
                      "Adding insulation means better comfort and additional savings.",
                  },
                  other: {
                    title: "Something Else",
                  },
                },
              },
              brandPreference: {
                title: "Have a brand preference?",
                description:
                  "Let us know! Note: this often adds time and cost.",
                dialogSubtitle: "Tell us about your brand preferences",
                dialogFooter: "Note: this often adds time and cost.",
                dialogButtonText: "Save Message",
              },
              request: "Request",
            },
            TaskLineItems: {
              // # 27
              install_heat_pump_odu: {
                imageUrl: "/assets/scope/install_heat_pump_odu.png",
                customer: {
                  name: "Install heat pump Outdoor Unit (ODU)",
                  description:
                    "The Outdoor Unit absorbs heat from the outside air and transfers that heat into your home using a refrigerant. We'll install the ODU outside your home at a location of your choosing. We include an Outdoor Unit stand and pad to elevate your unit and protect it from moisture, pests, and debris and ensure optimal performance.",
                },
                contractor: {
                  name: "Install {equipment.count} {equipment.capacity} BTU / hr Air Source Heat Pump Outdoor Unit",
                  description:
                    "The Outdoor Unit(s) must be part of an AHRI-rated combination. The rated specifications of this AHRI-rated combination must exceed the minimum specifications described under System Design. These include, but are not limited to: Capacity ({equipment.capacity} BTU / hr), SEER2 (min {equipment.SEER2}, max {equipment.SEER2_max}), EER2 (min {equipment.EER2}, max {equipment.EER2_max}), HSPF2 (min {equipment.HSPF2}, max {equipment.HSPF2_max}).",
                },
              },
              // # 29
              install_heat_pump_idu: {
                imageUrl: "/assets/scope/install_heat_pump_idu.png",
                customer: {
                  name: "Install heat pump Indoor Unit(s) (IDU)",
                  description:
                    "The Indoor Units circulate conditioned air through the home. They're typically wall-mounted and deliver heating and cooling to a single room or space. These units allow for zoned comfort - for example, you you can have the heat on in one room without heating the rest of the home, saving you money. We'll run refrigerant lines from the Outdoor Unit to the Indoor Units and use line-hide to hide these lines from view. We'll also install condensate drains and pumps, if necessary, to avoid buildup of excess moisture, preventing damage to your home.",
                },
                contractor: {
                  name: "Install {equipment.count} Air Source Heat Pump Indoor Units - {equipment.capacity} BTU / hr",
                  description:
                    "The Indoor Unit(s) must be part of an AHRI-rated combination. The rated specifications of this AHRI-rated combination must exceed the minimum specifications described under System Design. These include, but are not limited to: SEER2 (min {equipment.SEER2}, max {equipment.SEER2_max}), EER2 (min {equipment.EER2}, max {equipment.EER2_max}), HSPF2 (min {equipment.HSPF2}, max {equipment.HSPF2_max}). The rated capacity of these Indoor Units must meet the minimum specifications described under System Design - 9000 BTU / hr. The Indoor Units must be sited according to the floor plan provided under System Design. Our expectation is a clean install that meets a high aesthetic bar.",
                },
              },
              // # 32
              install_heat_pump_ahu: {
                imageUrl: "/assets/scope/install_heat_pump_ahu.png",
                customer: {
                  name: "Install heat pump Air Handler Unit(s) (AHU)",
                  description:
                    "The Air Handler Unit circulates conditioned air throughout your home via the ductwork, delivering comfortable heating and air conditioning. It’s connected to both the heat pump and - if you have one - your backup furnace. We'll run refrigerant lines from the Outdoor Unit to the Air Handler Unit and use line-hide to hide these lines from view. We'll also install condensate drains and pumps, if necessary, to avoid buildup of excess moisture, preventing damage to your home.",
                },
                contractor: {
                  name: "Install {equipment.count} {equipment.capacity} BTU multiposition Air Source Heat Pump Air Handler Unit",
                  description:
                    "The Air Handler Unit(s) must be part of an AHRI-rated combination. The rated specifications of this AHRI-rated combination must exceed the minimum specifications described under System Design. These include, but are not limited to: SEER2 (min {equipment.SEER2}, max {equipment.SEER2_max}), EER2 (min {equipment.EER2}, max {equipment.EER2_max}), HSPF2 (min {equipment.HSPF2}, max {equipment.HSPF2_max}). The rated capacity of these Indoor Units must meet the minimum specifications described under System Design - {equipment.capacity} BTU / hr. The Indoor Units must be sited according to the floor plan provided under System Design. Our expectation is a clean install that meets a high aesthetic bar.",
                },
              },
              // # 30
              install_condensate_drain: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Install {equipment.count} condensate drains, one for each Indoor Unit",
                  description: "",
                },
              },
              // # 31
              install_condensate_pump_wall_unit: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Install a condensate pump for each Indoor Unit, as necessary",
                  description: "",
                },
              },
              // # 33
              install_condensate_pump_air_handler_unit: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Install a condensate pump for the Air Handler Unit, as necessary",
                  description: "",
                },
              },
              // # 36
              install_insulated_refrigerant_lineset_ducted: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Run refrigerant lineset from Air Source Heat Pump Outdoor Unit to Air Handler Unit",
                  description:
                    "Lineset should be insulated. Refrigerant line must be charged on-site in accordance with best practices. Any holes in the wall or foundation where the lineset enters the home must be sealed. Our expectation is a clean install that meets a high aesthetic bar.",
                },
              },
              // # 37
              install_insulated_refrigerant_lineset_ductless: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Run refrigerant lineset from Air Source Heat Pump Outdoor Unit to Indoor Units",
                  description:
                    "Linesets should be insulated. Refrigerant lines must be charged on-site in accordance with best practices. Any holes in the wall or foundation where the lineset enters the home must be sealed. Our expectation is a clean install that meets a high aesthetic bar.",
                },
              },
              // # 38
              install_line_hide_kit_ducted: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Install line-hide",
                  description:
                    "Install a Slimduct (or equivalent) line-hide kit for the refrigerant line from the Outdoor Unit to the Air Handler Unit. Our expectation is a clean install that meets a high aesthetic bar.",
                },
              },
              // # 39
              install_line_hide_kit_ductless: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Install line-hide",
                  description:
                    "Install a Slimduct (or equivalent) line-hide kit for the refrigerant line from the Outdoor Unit to the Indoor Units. Our expectation is a clean install that meets a high aesthetic bar.",
                },
              },
              // # 28
              install_outdoor_unit_stand: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Install {equipment.count} Outdoor Unit Stand(s)",
                  description: "",
                },
              },
              // # 28
              install_outdoor_unit_pad: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Install {equipment.count} Outdoor Unit Pad(s)",
                  description: "",
                },
              },
              // # 54
              install_service_panel_upgrade: {
                customer: {
                  name: "Upgrade service panel",
                  description:
                    "Your air-source heat pump will add electrical load to your home. We will upgrade your service 200 amps to ensure that you have enough capacity now and in the future.",
                },
                contractor: {
                  name: "Upgrade the customer's electrical service panel to 200 amps",
                  description:
                    "Install a 200-amp rated main breaker panel with 40-circuit capacity with integrated surge protection.",
                },
              },
              // # 35
              install_ehk: {
                imageUrl: "/assets/scope/install_ehk.jpg",
                customer: {
                  name: "Install backup resistive heating",
                  description:
                    "We'll install resistive heating to provide emergency-only supplementary heating at very cold temperatures. This ensures that you're capable at extremely low temperatures if your heat pump system is unable to maintain temperature on its own.",
                },
                contractor: {
                  name: "Install 10 kW auxiliary electric resistance resistance heating",
                  description:
                    "Auxiliary resistance heating should be configured for emergency-only supplementary heating.",
                },
              },
              // # 56
              install_disconnect: {
                customer: {
                  name: "Install disconnect and service switch",
                  description:
                    "A disconnect switch ensures that the electrical circuit is de-energized in the case of an emergency stoppage, service, or maintenance, protecting your new equipment.",
                },
                contractor: {
                  name: "Install an electrical disconnect",
                  description: "",
                },
              },
              // # 57
              install_service_switch: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Install a service switch",
                  description: "",
                },
              },
              // # 50
              install_wiring_ahu: {
                customer: {
                  name: "Wire the new system",
                  description:
                    "A licensed electrician will wire all of the components of your new system and ensure safety and code compliance.",
                },
                contractor: {
                  name: "Wire the customer's Air Handler Unit(s)",
                  description: "",
                },
              },
              // # 51
              install_wiring_ahu_pump: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Wire condensate pump(s) for the customer's Air Handler Unit(s), as necessary",
                  description: "",
                },
              },
              // # 55
              install_subpanel: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Install a subpanel for the air-source heat pump",
                  description: "",
                },
              },
              // # 43
              install_duct_sealing: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Complete basic duct repair and duct sealing",
                  description:
                    "Repair major gaps in ductwork, and seal ductwork with mastic tape or foil tape wherever there is an acute leakage issue.",
                },
              },
              // # 44
              install_duct_insulation: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Insulate semi-conditioned basement ductwork",
                  description:
                    "Insulate ductwork with fiberglass duct wrap with FSK (foil-scrim-kraft) facing or equivalent. Vapor barrier should have a maximum permeance rating of 0.02 perms. Use aluminum foil tape or mechanical fasteners appropriate for the application.",
                },
              },
              // # 59
              install_air_sealing: {
                customer: {
                  name: "Complete air sealing",
                  description: "",
                },
                contractor: {
                  name: "Complete blower-door assisted air sealing",
                  description: "",
                },
              },
              // # 60
              install_attic_insulation: {
                customer: {
                  name: "Insulate the attic floor",
                  description:
                    "Remove any existing attic floor insulation as necessary. Insulate the attic floor with blown-in fiberglass.",
                },
                contractor: {
                  name: "Insulate the attic floor",
                  description:
                    "Remove any existing attic floor insulation as necessary. Insulate the attic floor with blown-in fiberglass.",
                },
              },
              // # 61
              install_rim_joist_insulation: {
                customer: {
                  name: "Insulate the rim joist",
                  description:
                    "Install air-permeable insulation for the rim joist - spray foam or rigid foam boards.",
                },
                contractor: {
                  name: "Insulate the rim joist",
                  description:
                    "Install air-permeable insulation for the rim joist - spray foam or rigid foam boards.",
                },
              },
              // # 62
              install_basement_insulation: {
                customer: {
                  name: "Insulate semi-conditioned basement exterior wall cavities",
                  description: "",
                },
                contractor: {
                  name: "Insulate semi-conditioned basement exterior wall cavities",
                  description: "",
                },
              },
              // # 63
              install_wall_insulation: {
                customer: {
                  name: "Insulate conditioned exterior wall cavities",
                  description: "",
                },
                contractor: {
                  name: "Insulate conditioned exterior wall cavities",
                  description: "",
                },
              },
              // # 64
              install_windows: {
                customer: {
                  name: "Install double-pane windows",
                  description: "",
                },
                contractor: {
                  name: "Install double-pane windows",
                  description: "",
                },
              },
              // # 48
              install_commission: {
                customer: {
                  name: "Commission your new system",
                  description:
                    "When it comes to the performance and efficiency of your new system, installation quality is even more important than equipment quality. We vet our partners carefully and ensure that every installation reflects best-practice guidelines for installation and commissioning.",
                },
                contractor: {
                  name: "Commission the system in accordance with best practices",
                  description: "See Pearl Edison commissioning guidelines.",
                },
              },
              // # 45
              install_decommissioning: {
                customer: {
                  name: "Decomission your old system",
                  description:
                    "We'll decommission, demolish, haul away, and responsible dispose of your current system.",
                },
                contractor: {
                  name: "Decomission the customer's existing system",
                  description: "",
                },
              },
              // # 46
              install_demolition_removal: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Demolish and remove the customer's existing system",
                  description: "",
                },
              },
              // # 47
              install_permitting: {
                customer: {
                  name: "",
                  description: "",
                },
                contractor: {
                  name: "Pull a permit for the work",
                  description:
                    "Apply for and obtain all required permits before beginning the work. Submit complete documentation as required by applicable permitting processes. Our expectation is that our contractor partners will be scheduled and be present for required inspections, resolve any code compliance issues identified during inspection, and provide copies of all permits and inspection results to the homeowner and to Pearl Edison.",
                },
              },
              // # 34
              install_backup_heat: {
                imageUrl: "/assets/scope/install_backup_heat.png",
                customer: {
                  name: "Install a backup high-efficiency natural gas furnace",
                  description:
                    "Your furnace will run at lower temperatures, which helps to reduce your bills during cold winter months.",
                },
                contractor: {
                  name: "Install {equipment.count} {equipment.capacity} BTU {equipment.AFUE} AFUE natural gas backup furnace",
                  description:
                    "The Furnace must be part of an AHRI-rated combination. The rated specifications of this AHRI-rated combination must exceed the minimum specifications described under System Design. These include, but are not limited to: Capacity {equipment.capacity} BTU / hr), AFUE ({equipment.AFUE}). The balance point for this backup furnace should be configured according to the specification provided under System Design.",
                },
              },
              // # 34 @TODO THIS DOESN'T SEEM TO EXIST
              install_customer_heat: {
                imageUrl: "/assets/scope/install_backup_heat.png",
                customer: {
                  name: "Install a backup high-efficiency natural gas furnace",
                  description:
                    "Your furnace will run at lower temperatures, which helps to reduce your bills during cold winter months.",
                },
                contractor: {
                  name: "Install {equipment.count} {equipment.capacity} BTU {equipment.AFUE} AFUE natural gas backup furnace",
                  description:
                    "The Furnace must be part of an AHRI-rated combination. The rated specifications of this AHRI-rated combination must exceed the minimum specifications described under System Design. These include, but are not limited to: Capacity {equipment.capacity} BTU / hr), AFUE ({equipment.AFUE}). The balance point for this backup furnace should be configured according to the specification provided under System Design.",
                },
              },
            },
          },
          BillImpactContent: {
            weather: "Weather",
            energyUsage: "Energy Usage",
            energyPrices: "Energy Prices",
            savings:
              "We project that you'll save <strong>~ {totalSavings} per year initially</strong> - {winterSavings} in the winter on heating, {summerSavings} in the summer on air conditioning - and greater than <strong>~ {lifetimeSavings} over the life of your system.</strong>",
          },
          EnergyImpactContent: {
            weather: "Weather",
            energyUsage: "Energy Usage",
            energyPrices: "Energy Prices",
            savings:
              "We project that you'll {direction} your energy by <strong>{totalSavings} metric tons per year initially</strong> - {winterSavings} in the winter on heating, {summerSavings} in the summer on air conditioning - and <strong>{lifetimeSavings} metric tons over the life of your system.</strong>",
          },
          PricingContent: {
            subtitle: "Cost Breakdown",
            beforeRebates: "Before Rebates",
            totalPayment: "Total Payment",
            afterRebates: "After All Rebates + Discounts",
            afterTaxCredits: "After Tax Credits",
            disclaimer: {
              title: "Disclaimer: ",
              description:
                "Scope, pricing, and rebate eligibility will be verified during your home assessment. You will have to provide proof of identity, homeownership, and income to verify your eligibility for rebates. Pearl Edison is not a tax advisor. Financing rate is illustrative and may vary.",
            },
            introText:
              "We searched for every applicable discount, rebate, and tax incentive to lower your price. You're potentially eligible for <strong>{{eligibleAmount}}</strong>{{#if (gt potentialRebatesTotal 0)}}, and you could be eligible for an additional <strong>{{potentialAmount}}</strong>{{/if}} - we'll verify this during your home assessment.",
            eligibleRebates: "Rebates + Discounts that pay now",
            eligibleRebatesDescription:
              "Applied at point of sale by Pearl Edison, to lower your price",
            payLater: "Rebates + Discounts that pay later",
            payLaterDescription:
              "Applied after after you’ve paid for your new system and installation is complete",
            credits: "Tax Credits",
            creditsDescription:
              "Applied as a tax refund, which will save you money if you would otherwise owe in state or federal taxes.",
            totalAfterRebates: "Total Payment",
            totalAfterAllRebates: "After All Rebates + Discounts",

            rebates: {
              payNow: {
                title: "Rebates + discounts that pay now",
                description:
                  "Applied at point of sale by Pearl Edison, to lower your price",
              },
              payLater: {
                title: "Rebates + discounts that pay later",
                description:
                  "Applied after you've paid for your new system and installation is complete",
              },
              taxCredits: {
                title: "Tax Credits",
                description:
                  "Applied as a tax refund, which will save you money if you would otherwise owe in federal or state taxes",
              },
              additional: {
                title: "Additional Rebates",
                description:
                  "You could be eligible for up to {amount} in additional incentives. We will evaluate your eligibility during your home assessment.",
              },
              comingSoon: {
                title: "Coming soon!",
                description:
                  "{{#if name}}The {{name}}{{else}}This{{/if}} program is launching soon, according to the state's latest update. Pearl Edison will notify you when the rebate is available.",
                linkUrl:
                  "https://www.michigan.gov/egle/about/organization/materials-management/energy/rfps-loans/home-energy-rebate-programs/communities",
                linkTitle: "Learn more about the program",
              },
            },
            paymentOptions: {
              subtitle: "Payment Options",
              options: {
                first: {
                  title: "Option #1: Self-Finance",
                  dueAtAssessment: "Due at home assessment",
                  dueAtSigning: "Due at signing",
                  dueAfterInstallation: "Due after installation",
                },
                second: {
                  title: "Option #2: Low-Interest Loan",
                  downPayment: "Down payment",
                  apr: "APR",
                  loanTerm: "Month loan term",
                  monthlyPayment: "Monthly Payment",
                },
              },
            },
          },
          LoadCalculationContent: {
            title: "Load Calculation",
            description:
              "During your home assessment, we analyzed your heating and cooling load - the amount of heat we need to move to keep you warm in the winter and cool in the summer.",
          },
          EquipmentContent: {
            title: "Equipment & Tech Specs",
            description:
              "We only install high-quality equipment from reputable manufacturers that back their products with a 10-year warranty on parts and labor.",
            heating: "Heating",
            cooling: "Cooling",
            exampleUnit:
              "Example system that meets these technical specifications",
          },
          WhyPearlEdisonContent: {
            title: "Why Pearl Edison?",
            description:
              "We provide high-quality advice, properly design your system, and arrange installation through a vetted contractor partner.",
            howItWorks: {
              title: "How It Works",
              items: {
                responsible: {
                  title: "We are responsible for your project, end-to-end.",
                  description:
                    "We are responsible for the design, installation, and commissioning of your system. (We are not a lead generation service.) That means we're on the hook if you aren't happy.",
                },
                guarantee: {
                  title:
                    "We guarantee your price, including rebates + incentives.",
                  description:
                    "After your home assessment, we set a 'ceiling' for your price, including applicable rebates and incentives. We work on a fixed-price basis, even if we are going to lose money on the job.",
                },
                negotiate: {
                  title: "We negotiate on your behalf to get a good deal.",
                  description:
                    "We negotiate scoping and pricing with our network of contractor partners. We typically save our customers ~10% vs. going direct.",
                },
                money: {
                  title: "We make money if (and only if) you save money.",
                  description:
                    "We earn a fee on your project, as a percentage of the savings we negotiate. If we can’t identify a contractor willing to perform the work at your guaranteed price, then we pay the difference.",
                },
              },
            },
            ourStandards: {
              title: "Our Standards",
              items: {
                licenseCheck: {
                  title: "License Check",
                  description:
                    "We perform a license check to ensure that all Pearl Edison contractor and subcontractor partners are operating legally and ethically and are qualified by the state to work in your area.",
                },
                technicalVetting: {
                  title: "Technical Vetting",
                  description:
                    "Our Operations team evaluates every Pearl Edison contractor partner's technical credentials to ensure that they are capable of meeting rigorous standards for installation and commissioning.",
                },
                ethicalVetting: {
                  title: "Ethical Vetting",
                  description:
                    "We work with partners who share our values - our commitment to serving customers with integrity and our commitment to grounding system design in building science and best pratices.",
                },
                codeOfConduct: {
                  title: "Code of Conduct",
                  description:
                    "Every Pearl Edison contractor partner signs up to a code of conduct. Failure to abide by the code of conduct results in removal from the Pearl Edison network.",
                },
              },
            },
            featuredPartner: {
              title: "Featured Partner",
              companyName: "Air Doctors",
              description:
                "We are a proud partner in the Pearl Edison network. Our goals are aligned - delivering energy-efficient products, quality work, and outstanding customer service. Consider us a lifer!",
              founded: { label: "Founded", value: "2004" },
              headquarters: {
                label: "Headquarters",
                value: "Detroit, MI",
              },
              certifications: "Certifications",
            },
          },
          TestimonialContent: {
            title: "Testimonials",
            description:
              "Hear from neighbors who worked with Pearl Edison to buy an efficient heating and air conditioning system.",
            whyElectric: "Why did you go electric?",
            projectScopeLabel: "Project scope label",
            items: {
              julianne: {
                customerName: "Julianne L.",
                location: "Detroit, MI",
                why: "To add air conditioning and improve my summer comfort.",
                scopeLabel: "3-ton dual-fuel heat pump",
                testimonial:
                  "Pearl Edison has been so great to work with! They are communicative and helpful through what could be a very complicated process. They make it simple and easy.",
              },
              macon: {
                customerName: "Macon G.",
                location: "Detroit, MI",
                why: "My bones were achin' in the winter!",
                scopeLabel: "4-ton dual-fuel heat pump",
                testimonial:
                  "Pearl Edison helped us put our home in a better position with heating and cooling. The team was kind and very efficient. Thank you so much for your time and effort to make us comfortable in our home!",
              },
              arthur: {
                customerName: "Arthur M.",
                location: "Detroit, MI",
                why: "To get AC for the first time and reduce my bills.",
                scopeLabel: "2-ton dual-fuel heat pump",
                testimonial:
                  "Very happy with my new system. I'm 60 years old and never had air conditioning before. Just in time for summer. Go Pistons!",
                imageSrc: arthurImage,
              },
              debra: {
                customerName: "Debra A.",
                location: "Detroit, MI",
                why: "My bills were really hight in the winter.",
                scopeLabel: "2-ton dual-fuel heat pump",
                testimonial:
                  "My heating bill was $700 per month and I was always cold at my house. Pearl Edison helped me fixed the problem. My bills are much lower now!",
              },
            },
          },
        },
      },
    },
    "en-es": {
      step: {
        HomeConfirmationStep: {
          title: "Esta su casa?",
          description: "",
        },
      },
    },
  },
} as unknown as TennantConfig;

import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

import { baseConfig } from "../config";
import { baseOverrides } from "../overrides";

import Landing from "./Landing";
import logo from "./logo.png";
// import React from "react";

const FONT_LIGHT = 300;
const FONT_REGULAR = 400;
const FONT_NORMAL = 500;
const FONT_MEDIUM = 600;
const FONT_BOLD = 600;
const FONT_FAMILY = "Open Sans";

const SEAFOAM_SPLASH = "#59BEC9";

const config = merge({}, cloneDeep(baseConfig), {
  name: "DTE Energy",
  Landing,
  showBrands: false,
  tiers: {
    default: "pearl",
    available: ["base", "pearl", "edison"],
  },
  onboarding: {
    default: {
      payload: {
        projectType: "heating_cooling",
        heatingCoolingProjectScope: "heating",
        heatingCoolingProjectType: "replace",
        projectMotivation: "online_quote",
        wholeOrPartialHome: "whole",
      },
      slideKey: "LetsGetStartedStep",
    },
    invisible: [
      "ProjectTypeStep",
      "HeatingCoolingProjectScopeStep",
      "HeatingCoolingProjectStep",
      "HeatingOrAirConditioningServiceAddressStep",
      "HeatingOrAirConditioningServiceStep",
      "HeatingOrAirConditioningScheduleServiceStep",
      "HeatingOrAirConditioningReplacementStep",
      "HeatingOrAirConditioningReplaceEmergencyStep",
      "HeatingOrAirConditioningReplaceEmergencyScheduleStep",
      "HeatingOrAirConditioningWholeOrPartialStep",
      "UploadQuoteStep",
      "SchedulingAddressStep",
      "SchedulingCommunicationStep",
      "SchedulingStep",
    ],
  },
  language: {},
});

const overrides = merge({}, cloneDeep(baseOverrides), {
  logo: {
    imageSrc: logo,
    logoUrl: `${window.location.origin}/onboarding?s=true`,
    alt: "DTE Logo",
    width: 250,
    poweredBy: false,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          padding: 10,
        },
        textPrimary: {
          color: "#0072CE",
          "&:hover": {
            color: "#1E3575",
          },
        },
        containedPrimary: {
          backgroundColor: "#0072CE",
          "&:hover": {
            backgroundColor: "#1E3575",
          },
        },
        containedSecondary: {
          color: "#0072CE",
          border: "1px solid #0072CE",
          backgroundColor: "white",
          "&:hover": {
            color: "white",
            borderColor: "#1E3575",
            backgroundColor: "#1E3575",
          },
        },
      },
    },
  },
  //shadows:[],
  typography: {
    fontFamily: FONT_FAMILY,
    fontWeightLight: FONT_LIGHT,
    fontWeightRegular: FONT_REGULAR,
    fontWeightNormal: FONT_NORMAL,
    fontWeightMedium: FONT_MEDIUM,
    fontWeightBold: FONT_BOLD,
    h1: {
      fontWeight: FONT_REGULAR,
      fontSize: "28px",
      fontFamily: FONT_FAMILY,
    },
    h2: {
      fontWeight: FONT_REGULAR,
      fontSize: "25px",
      fontFamily: FONT_FAMILY,
    },
    h3: {
      fontWeight: FONT_REGULAR,
      fontSize: "22px",
      fontFamily: FONT_FAMILY,
    },
    h4: {
      fontWeight: FONT_MEDIUM,
      fontSize: "20px",
      fontFamily: FONT_FAMILY,
    },
    h5: {
      fontWeight: FONT_MEDIUM,
      fontSize: "18px",
      fontFamily: FONT_FAMILY,
    },
    body1: {
      fontWeight: 400,
      "@media (max-width:600px)": {
        fontSize: "0.8rem",
      },
      fontFamily: FONT_FAMILY,
    },
    body2: {
      fontWeight: 400,
      fontFamily: FONT_FAMILY,
    },
  },
  palette: {
    common: {
      accent: SEAFOAM_SPLASH,
    },
    primary: {
      main: "#1e3575",
      dark: "#152655",
      light: "#334881",
      lighter: "#dee6fc",
    },
    secondary: {
      main: "#0072CE",
      light: "#59BEC9",
    },
    accent: {
      main: "#D2D755",
    },
    success: {
      main: "#0072CE",
      light: "#7CB518",
    },
    warning: {
      main: "#8DC8E8",
    },
  },
});

export default {
  ...overrides,
  config,
};

import { useTheme } from "@mui/material";
import React from "react";

export const Homestead = ({ styles }: { styles?: object }) => {
  const theme = useTheme();
  return (
    <svg
      width="72"
      height="40"
      viewBox="0 0 72 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M13.8713 16.6147H13.9072C15.366 16.6147 16.5505 17.7991 16.5505 19.2579V26.6988H11.228V19.2579C11.228 17.7991 12.4124 16.6147 13.8713 16.6147Z"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.2033 16.6147H40.2392C41.6981 16.6147 42.8825 17.7991 42.8825 19.258V26.6988H37.5601V19.258C37.5601 17.7991 38.7444 16.6147 40.2033 16.6147Z"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5474 34.1691V22.6397C22.5474 20.1501 24.5656 18.1318 27.0551 18.1318C29.5447 18.1318 31.5629 20.1501 31.5629 22.6397V34.1691"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0927 1H7.14269L5.43555 12.9188H48.6757L46.9686 1H30.1046"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.86719 13.127V34.7495H47.2432V13.127"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.965 21.0683L65.8916 34.7459"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.9178 16.4593C61.8152 16.1451 61.7593 15.8137 61.7593 15.4705C61.7593 14.567 62.1372 13.7417 62.7597 13.1043C62.5526 12.7137 62.4338 12.2801 62.4338 11.8213C62.4338 10.1314 64.0018 8.76135 65.9359 8.76135C67.8701 8.76135 69.4381 10.1314 69.4381 11.8213C69.4381 12.2801 69.3193 12.7137 69.1122 13.1043C69.7348 13.7417 70.1127 14.567 70.1127 15.4705C70.1127 16.0251 69.967 16.5485 69.7137 17.0193C70.4229 17.7635 70.8501 18.72 70.8501 19.7641C70.8501 22.1354 68.6499 24.0577 65.9359 24.0577C64.7466 24.0577 63.656 23.6886 62.8059 23.0743"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.8444 24.9458C61.4935 24.9458 63.641 22.8123 63.641 20.1804C63.641 17.5486 61.4935 15.4149 58.8444 15.4149C56.1953 15.4149 54.0479 17.5486 54.0479 20.1804C54.0479 22.8123 56.1953 24.9458 58.8444 24.9458Z"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.8335 21.0682V34.7494"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.8495 34.7495H1V38.742H70.8495V34.7495Z"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Homestead;

import { useTheme } from "@mui/material";
import React from "react";

export const EVCharging = ({ styles }: { styles?: object }) => {
  const theme = useTheme();
  return (
    <svg
      width="96"
      height="99"
      viewBox="0 0 96 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M65.2464 87.3591V7.23475C65.2464 4.04987 62.6645 1.46802 59.4797 1.46802H13.0538C9.86896 1.46802 7.28711 4.04987 7.28711 7.23475V87.3591"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <rect
        x="0.944336"
        y="87.8589"
        width="70.6459"
        height="10.1728"
        rx="1.42224"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <rect
        x="17.9141"
        y="12.0356"
        width="36.7084"
        height="20.4957"
        rx="1.42224"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M86.9006 22.3994V69.7905C86.9006 73.7999 83.6504 77.0501 79.641 77.0501C75.6317 77.0501 72.3815 73.7999 72.3815 69.7905L72.3816 29.7221C72.3816 25.7128 69.1314 22.4626 65.1221 22.4626"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M78.7402 10.7668H95.0582V20.7744C95.0582 21.5599 94.4214 22.1967 93.636 22.1967H80.1625C79.377 22.1967 78.7402 21.5599 78.7402 20.7744V10.7668Z"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <line
        x1="83.0703"
        y1="1.46802"
        x2="83.0703"
        y2="10.2666"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <line
        x1="91.7285"
        y1="1.46802"
        x2="91.7285"
        y2="10.2666"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M36.9692 47.6783L32.4848 53.8996L39.8299 54.0087L35.5654 60.8818"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.2672 65.4552C42.2305 65.4552 47.0646 60.621 47.0646 54.6578C47.0646 48.6945 42.2305 43.8604 36.2672 43.8604C30.304 43.8604 25.4697 48.6945 25.4697 54.6578C25.4697 60.621 30.304 65.4552 36.2672 65.4552Z"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EVCharging;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { Box, Grid2, useTheme } from "@mui/material";
import DropzoneArea from "@library/components/DropzoneArea";
import FileRow from "@library/components/FileRow";
import ConfirmationDialog from "@library/components/ConfirmationDialog";
import api from "@library/api";
import { Root, classes } from "./styles";

export const AssessmentWalkthroughPhotos = ({
  slideState,
  setSlideState,
}: {
  slideState: any;
  setSlideState: (val: any) => void;
}) => {
  const theme = useTheme();
  const job = slideState.job;

  const [walkthroughPhotos, setWalkthroughPhotos] = useState<
    Array<{
      id?: string;
      name: string;
      size?: string;
      url?: string;
    }>
  >([]);
  const [fetchedImageIds, setFetchedImageIds] = useState<string[]>([]);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [fileToDelete, setFileToDelete] = useState<{ index: number } | null>(
    null
  );

  useEffect(() => {
    if (!job?.Image?.length) {
      setWalkthroughPhotos([]); // Clear photos if no images exist
      setFetchedImageIds([]);
      return;
    }

    const walkthroughImages = job.Image.filter(
      (image: any) => image.type === "walkthrough"
    );

    if (!walkthroughImages.length) {
      setWalkthroughPhotos([]); // Clear photos if no walkthrough images
      setFetchedImageIds([]);
      return;
    }

    // Get all images that haven't been fetched yet
    const unfetchedImages = walkthroughImages.filter(
      (image: any) => !fetchedImageIds.includes(image.id)
    );

    if (unfetchedImages.length === 0) return;

    const promises = unfetchedImages.map((image: any) =>
      api.get("image", { id: image.id })
    );

    Promise.all(promises)
      .then((responses) => {
        if (responses?.length) {
          const newPhotos = responses.map((response) => {
            const imageData = response.data;
            return {
              name: imageData.title,
              id: imageData.id,
              url: imageData.sizes?.small ?? imageData.sizes?.original,
              size: imageData.size
                ? `${(imageData.size / 1024).toFixed(0)}kb`
                : undefined,
            };
          });

          // Update state with new photos while preserving order
          setWalkthroughPhotos((prevPhotos) => {
            const updatedPhotos = [...prevPhotos];
            newPhotos.forEach((newPhoto) => {
              const existingIndex = updatedPhotos.findIndex(
                (photo) => photo.id === newPhoto.id
              );
              if (existingIndex === -1) {
                updatedPhotos.push(newPhoto);
              } else {
                updatedPhotos[existingIndex] = newPhoto;
              }
            });
            return updatedPhotos;
          });

          setFetchedImageIds((prev) => [
            ...prev,
            ...unfetchedImages.map((img: any) => img.id),
          ]);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch walkthrough photos:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?.Image]); // Only depend on job.Image changes

  const updateJobInSlideState = (newImage: any) => {
    const updatedJob = {
      ...job,
      Image: [...(job.Image || []), newImage],
    };
    setSlideState((prevState: any) => ({
      ...prevState,
      job: updatedJob,
    }));
  };

  const handleFileUpload = async (acceptedFiles: File[], title?: string) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      name: file.name,
      size: `${(file.size / 1024).toFixed(0)}kb`,
      id: "",
    }));

    for (const file of newFiles) {
      const form = new FormData();
      form.append("title", title || "Home Walkthrough Photo");
      form.append("type", "walkthrough");
      form.append("jobId", job.id ?? "");
      form.append(file.name, file.file);

      try {
        const response = await api.post(
          "image",
          form,
          {},
          { "Content-Type": "multipart/form-data" }
        );

        // Update the file with the response data
        file.id = response.data.images[0].id;

        updateJobInSlideState({
          id: response.data.images[0].id,
          type: "walkthrough",
          title: title || "Home Walkthrough Photo",
          jobId: job.id,
        });
      } catch (error) {
        console.error("Failed to upload walkthrough photo:", error);
      }
    }

    return newFiles;
  };

  const handleConfirmDelete = async () => {
    if (!fileToDelete) return;

    const { index } = fileToDelete;
    const fileId = walkthroughPhotos[index].id;

    if (fileId) {
      try {
        await api.delete(`image/${fileId}`);

        // Update slideState by removing the deleted image
        setSlideState((prevState: any) => ({
          ...prevState,
          job: {
            ...prevState.job,
            Image: prevState.job.Image.filter((img: any) => img.id !== fileId),
          },
        }));

        setWalkthroughPhotos((prevFiles) =>
          prevFiles.filter((_, i) => i !== index)
        );
      } catch (error) {
        console.error("Failed to delete file from server:", error);
      }
    }

    setIsDeleteConfirmationOpen(false);
    setFileToDelete(null);
  };

  const handleReupload = async (index: number, newFile: File) => {
    const oldImageId = walkthroughPhotos[index].id;

    try {
      // First, delete the old image
      if (oldImageId) {
        await api.delete(`image/${oldImageId}`);
      }

      // Then upload the new image
      const form = new FormData();
      form.append("title", newFile.name);
      form.append("type", "walkthrough");
      form.append("jobId", job.id ?? "");
      form.append(newFile.name, newFile);

      const response = await api.post(
        "image",
        form,
        {},
        { "Content-Type": "multipart/form-data" }
      );

      const newImageId = response.data.images[0].id;
      const newImageData = {
        id: newImageId,
        type: "walkthrough",
        title: newFile.name,
        jobId: job.id,
      };

      // Update slideState first
      setSlideState((prevState: any) => {
        const currentImages = [...(prevState.job.Image || [])];
        // Remove old image
        const filteredImages = currentImages.filter(
          (img: any) => img.id !== oldImageId
        );
        // Add new image
        return {
          ...prevState,
          job: {
            ...prevState.job,
            Image: [...filteredImages, newImageData],
          },
        };
      });

      // Then update local state
      const updatedFile = {
        name: newFile.name,
        id: newImageId,
        size: `${(newFile.size / 1024).toFixed(0)}kb`,
        url: URL.createObjectURL(newFile),
      };

      setWalkthroughPhotos((prevFiles) => {
        const newFiles = [...prevFiles];
        newFiles[index] = updatedFile;
        return newFiles;
      });

      // Update fetchedImageIds to include the new image ID
      setFetchedImageIds((prev) => [...prev, newImageId]);
    } catch (error) {
      console.error("Failed to reupload walkthrough photo:", error);
      // TODO: reveal to user
    }
  };

  const handleWalkthroughPhotosAdded = async (acceptedFiles: File[]) => {
    const newFiles = await handleFileUpload(
      acceptedFiles,
      "Home Walkthrough Photo"
    );
    setWalkthroughPhotos((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDeleteFile = (index: number) => {
    setFileToDelete({ index });
    setIsDeleteConfirmationOpen(true);
  };

  return (
    <Root>
      <Box>
        <Grid2 container className={classes.homeAssessmentContainer}>
          <Grid2 size={12}>
            <DropzoneArea onFilesAdded={handleWalkthroughPhotosAdded} />
          </Grid2>
          <Grid2 size={12}>
            {walkthroughPhotos.map((file, index) => (
              <FileRow
                key={file.id || index}
                fileUrl={file.url}
                fileName={file.name}
                fileSize={file.size || ""}
                onReupload={(newFile) => handleReupload(index, newFile)}
                onDelete={() => handleDeleteFile(index)}
              />
            ))}
          </Grid2>
        </Grid2>
      </Box>
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        handleConfirm={handleConfirmDelete}
        backgroundColor={theme.palette.error.main}
        text="Are you sure you want to remove this photo?"
      />
    </Root>
  );
};

export default AssessmentWalkthroughPhotos;

import { Dialog, styled } from "@mui/material";

const PREFIX = "WithdrawQuoteDialog";

export const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
  errorIcon: `${PREFIX}-errorIcon`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  confirmButton: `${PREFIX}-confirmButton`,
  cancelButton: `${PREFIX}-cancelButton`,
  multilineTextField: `${PREFIX}-multilineTextField`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogContent}`]: {
    color: theme.palette.common.white,
  },
  [`& .${classes.errorIcon}`]: {
    fontSize: 30,
  },
  [`& .${classes.buttonContainer}`]: {
    textAlign: "right",
  },
  [`& .${classes.confirmButton}`]: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  [`& .${classes.cancelButton}`]: {
    marginLeft: theme.spacing(2),
    color: `${theme.palette.common.white} !important`,
  },
  [`& .${classes.multilineTextField}`]: {
    width: "100%",
  },
}));

import React, { useState } from "react";
import {
  Button,
  DialogContent,
  Grid2,
  Typography,
  TextField,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { StyledDialog, classes } from "./styles";

interface ConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirm: (note?: string) => void;
  handleCancel?: () => void;
  text: string;
  backgroundColor?: string;
  includeTextField?: boolean;
  textFieldLabel?: string;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  onClose,
  text = "",
  handleConfirm,
  handleCancel,
  backgroundColor = "#0088D1",
  includeTextField = false,
  confirmText = "Confirm",
  cancelText = "Cancel",
}) => {
  const [note, setNote] = useState("");

  const handleConfirmClick = () => {
    handleConfirm(includeTextField ? note : undefined);
    setNote(""); // Reset the note after confirming
  };

  const handleCancelClick = () => {
    if (handleCancel) {
      handleCancel();
    }
    onClose();
    setNote(""); // Reset the note after canceling
  };

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <DialogContent className={classes.dialogContent} sx={{ backgroundColor }}>
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            <ErrorOutlineIcon className={classes.errorIcon} />
          </Grid2>
          <Grid2 size={12}>
            <Typography>{text}</Typography>
          </Grid2>
          {includeTextField && (
            <Grid2 sx={{ mb: 2 }} size={12}>
              <TextField
                multiline
                minRows={3}
                value={note}
                onChange={(e: { target: { value: string } }) =>
                  setNote(e.target.value)
                }
                className={classes.multilineTextField}
              />
            </Grid2>
          )}
          <Grid2 className={classes.buttonContainer} size={12}>
            <Button
              className={classes.confirmButton}
              variant="contained"
              onClick={handleConfirmClick}
            >
              {confirmText}
            </Button>
            <Button
              className={classes.cancelButton}
              onClick={handleCancelClick}
            >
              {cancelText}
            </Button>
          </Grid2>
        </Grid2>
      </DialogContent>
    </StyledDialog>
  );
};

export default ConfirmationDialog;

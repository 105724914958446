import onboardingSchema from "@library/common/schema";
import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

export const baseSchema = {
  definitions: onboardingSchema.definitions,
  type: "object",
};

export const baseUISchema = {
  "ui:ObjectFieldTemplate": ObjectFieldTemplate,
  "ui:autocomplete": "off",
  width: "100%",
  maxWidth: "900px",
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
};

import { styled } from "@mui/material";

const PREFIX = "AssessmentSystemDesign";

export const classes = {
  systemDesignTitleContainer: `${PREFIX}-systemDesignTitleContainer`,
  homeAssessmentContainer: `${PREFIX}-homeAssessmentContainer`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.systemDesignTitleContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.homeAssessmentContainer}`]: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(8),
  },
}));

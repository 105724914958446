import React from "react";
import { FieldProps, UiSchema } from "@rjsf/utils";
import toNumber from "lodash/toNumber";
import {
  Box,
  Stack,
  Typography,
  useTheme,
  Grid2,
  MenuItem,
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
  Divider,
  useMediaQuery,
} from "@mui/material";
import StreetView from "@library/components/StreetView";

const baseLanguageLocation = "step.HomeProfileStep";

interface OneOfOption {
  const: string;
  title: string;
}

interface HomeDetailsFieldProps extends FieldProps {
  uiSchema?: UiSchema;
}

const HomeDetailsField = ({
  formData,
  onChange,
  schema,
  uiSchema = {},
  errorSchema,
}: HomeDetailsFieldProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const renderField = (fieldName: string) => {
    const fieldSchema = schema.properties[fieldName];
    const fieldUiSchema = uiSchema[fieldName] || {};
    const fieldError = errorSchema?.[fieldName]?.__errors;
    const hasError = Boolean(fieldError && fieldError.length);

    // Handle select fields (checking all possible locations for options)
    if (fieldSchema.enum || fieldSchema.items?.enum || fieldSchema.oneOf) {
      const label =
        theme.t(`labels.${fieldName}.label`) ||
        theme.t(`labels.${fieldName}`) ||
        fieldSchema.title;
      let options: Array<{ value: string; label: string }> = [];

      if (fieldSchema.enum) {
        options = fieldSchema.enum.map((value: string) => ({
          value,
          label: value,
        }));
      } else if (fieldSchema.items?.enum) {
        options = fieldSchema.items.enum.map((value: string) => ({
          value,
          label: value,
        }));
      } else if (fieldSchema.oneOf) {
        options = (fieldSchema.oneOf as OneOfOption[]).map(
          ({ const: value, title: label }) => ({
            value,
            label,
          })
        );
      }

      return (
        <FormControl
          fullWidth
          variant="outlined"
          sx={{ width: "100%" }}
          error={hasError}
        >
          <InputLabel
            sx={{ backgroundColor: theme.palette.common.white, px: 1 }}
          >
            <Typography color="text.secondary">{label}</Typography>
          </InputLabel>
          <Select
            label={label}
            displayEmpty
            value={formData?.[fieldName] || ""}
            onChange={(event) => {
              const value = event.target.value;
              const newFormData = {
                ...formData,
                [fieldName]: fieldUiSchema.multiple ? [value] : value,
              };
              onChange(newFormData);
            }}
            multiple={fieldUiSchema.multiple && fieldUiSchema.max !== 1}
            input={<OutlinedInput notched={false} />}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.12)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.24)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.main,
              },
            }}
          >
            {options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {theme.t(`labels.${fieldName}.enum.${value}.title`) ||
                  theme.t(`labels.${fieldName}.enum.${value}`) ||
                  label ||
                  value}
              </MenuItem>
            ))}
          </Select>
          {hasError && (
            <Typography color="error" variant="caption" sx={{ mt: 1 }}>
              {fieldError?.join(", ")}
            </Typography>
          )}
        </FormControl>
      );
    }

    // For non-select fields
    const label =
      theme.t(`labels.${fieldName}.label`) ||
      theme.t(`labels.${fieldName}`) ||
      fieldSchema.title;
    return (
      <FormControl
        fullWidth
        variant="outlined"
        sx={{ width: "100%" }}
        error={hasError}
      >
        <InputLabel sx={{ backgroundColor: theme.palette.common.white, px: 1 }}>
          <Typography color="text.secondary">{label}</Typography>
        </InputLabel>
        <OutlinedInput
          notched={false}
          value={formData?.[fieldName] || ""}
          error={hasError}
          type={fieldSchema.type === "integer" ? "number" : "text"}
          onChange={(event) => {
            let value: string | number = event.target.value;
            if (fieldSchema.type === "integer") {
              value = toNumber(event.target.value);
            }
            const newFormData = {
              ...formData,
              [fieldName]: value,
            };
            onChange(newFormData);
          }}
          endAdornment={
            fieldUiSchema.endAdornment && (
              <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                {fieldUiSchema.endAdornment}
              </Typography>
            )
          }
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0, 0, 0, 0.12)",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0, 0, 0, 0.24)",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
          }}
        />
        {hasError && (
          <Typography color="error" variant="caption" sx={{ mt: 1 }}>
            {fieldError?.join(", ")}
          </Typography>
        )}
      </FormControl>
    );
  };

  return (
    <Box
      sx={{
        py: 2,
        px: 3,
        borderRadius: 1,
        backgroundColor: theme.palette.common.white,
        width: "100%",
        maxWidth: 800,
      }}
    >
      <Stack spacing={3}>
        {/* Home Image and Address Section */}
        <Grid2 container>
          <Grid2
            size={{
              xs: 12,
              md: 5,
            }}
          >
            <Box
              sx={{
                height: 200,
                borderRadius: 1,
                overflow: "hidden",
                mr: isMobile ? 0 : 3,
              }}
            >
              <StreetView
                lat={formData.user?.lat}
                lng={formData.user?.lng}
                width="100%"
                height="100%"
              />
            </Box>
          </Grid2>
          <Grid2
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            size={{
              xs: 12,
              md: 7,
            }}
          >
            <Typography
              variant="subtitle1"
              color="primary.main"
              sx={{ fontWeight: 500, mb: 1 }}
            >
              {theme.t(`${baseLanguageLocation}.yourHome`)}
            </Typography>
            <Typography variant="h6">
              {formData?.user?.address?.split(",")[0]}
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {formData?.user?.address?.split(",").slice(1).join(",")}
            </Typography>
            <Typography color="text.secondary">
              {theme.t(`${baseLanguageLocation}.serviceMessage`, {
                city: formData?.user?.address?.split(",")[1],
              })}
            </Typography>
          </Grid2>
        </Grid2>

        <Divider />

        {/* Home Details Section */}
        <Grid2 container>
          <Grid2
            size={{
              xs: 12,
              md: 3,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
              {theme.t(`${baseLanguageLocation}.sections.homeDetails`)}
            </Typography>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              md: 9,
            }}
          >
            <Grid2 container spacing={2}>
              <Grid2 size={12}>{renderField("homeType")}</Grid2>
              <Grid2 size={6}>{renderField("sqFootage")}</Grid2>
              <Grid2 size={6}>{renderField("yearBuilt")}</Grid2>
              <Grid2 size={6}>{renderField("aboveGroundStories")}</Grid2>
              <Grid2 size={6}>{renderField("numBedrooms")}</Grid2>
            </Grid2>
          </Grid2>
        </Grid2>

        <Divider />

        {/* HVAC System Section */}
        <Grid2 container>
          <Grid2
            size={{
              xs: 12,
              md: 3,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
              {theme.t(`${baseLanguageLocation}.sections.hvacSystem`)}
            </Typography>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              md: 9,
            }}
          >
            <Grid2 container spacing={2}>
              <Grid2 size={12}>{renderField("heatingEquipmentType")}</Grid2>
              <Grid2 size={12}>{renderField("fuelSource")}</Grid2>
              <Grid2 size={12}>{renderField("coolingEquipmentType")}</Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Stack>
    </Box>
  );
};

export default HomeDetailsField;

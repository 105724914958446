{
  /* For bill and energy impact graph dropdowns */
}
export const weatherOptions = ["Mild", "Average", "Extreme"];

export const energyUsageOptions = [
  // "More than normal",
  "Status Quo",
  // "Less than normal",
];

export const energyPricesOptions = [
  // "Expensive",
  "Status Quo",
  // "Less than normal",
];

import { PolyvectorPayload } from "./estimate";
import { Home, Room } from "./home";
import { Job } from "./job";
import { User } from "./user";

export interface Utility {
  id: string;
  name: string;
  naturalGas: boolean;
  electricity: boolean;
  serviceAreaId: string;
  region: string;
  createdAt: Date;
  updatedAt: Date;
}

export const initialOnboardingPayload = {
  ceilingHeight: 8,
  temperatureSummer: 72,
  temperatureWinter: 68,
  user: {
    supabaseId: "",
    firstName: "",
    lastName: "",
    email: "",
    formatted_address: "",
    address: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    fipsCountyCode: "",
    communicationPreference: "",
    phoneNumber: "",
    lat: undefined,
    lng: undefined,
    channelId: "pearl",
  },
};

export interface OnboardingUser {
  supabaseId: string;
  firstName: string;
  lastName: string;
  email: string;
  formatted_address: string;
  address: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  fipsCountyCode: string;
  communicationPreference: string;
  phoneNumber: string;
  lat: number;
  lng: number;
  channelId: string;
}

export interface OnboardingPayload {
  heatingEquipmentType: string[];
  heatingEquipmentTypeMoreInfo: string;
  coolingEquipmentType: string[];
  coolingEquipmentTypeMoreInfo: string;
  atticFinish: string;
  atticType: string;
  basementFinished: string;
  basementType: string;
  draftyStuffy: string;
  exteriorWalls: string;
  fuelSource: string;
  floorSize: string;
  garageType: string;
  houseHoldIncome: number;
  interiorWalls: string;
  temperatureSummer: number;
  temperatureWinter: number;
  upgradesMoreInfo: string;
  windowType: string;
  homeType: string;
  basement: string;
  attic: string;
  garage: string;
  sqFootage: number;
  yearBuilt: number;
  ceilingHeight: number;
  numBedrooms: number;
  numBathrooms: number;
  aboveGroundStories: number;
  user: Partial<OnboardingUser>;
  rooms: Partial<Room>[] | undefined;
  upgrades: string[];
  issues: string[];
  homeQuality: string[];
  homeQualityMoreInfo: string;
  whyAreYouShoppingToday: string;
  whatsImportantToYou: string[];
  ownOrRent: string;
  whatsImportantToYouInfo: string;
  peopleLiveInHome: number;
  communicationPreference: string;
  phoneNumber: string;
  rebateCheck?: boolean;
  projectType?: string;
  heatingCoolingProjectType?: string;
  heatingCoolingProjectScope?: string;
}

export interface Onboarding {
  id: string;
  jobId: string;
  quoteId: string;
  data?: OnboardingPayload;
  polyvectorOverrides?: Partial<PolyvectorPayload>;
  step: {
    activeStep: number;
    activeStepKey: string;
    onboardingCompleted: boolean;
  };
  stepJSON?: string;
  dataJSON?: string;
  createdAt: Date;
  updatedAt: Date;
  status?: string;
  channelId?: string;
  Home?: Home;
  Job?: Job;
  User?: User;
}

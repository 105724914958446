import { Dialog, styled } from "@mui/material";

const PREFIX = "OptionsDialog";

export const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  footerContainer: `${PREFIX}-footerContainer`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitle}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: theme.spacing(1),
    justifyContent: "space-between",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    "& svg": {
      color: theme.palette.common.white,
    },
  },
  [`& .${classes.footerContainer}`]: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    justifyContent: "space-between",
  },
}));

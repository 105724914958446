import { styled } from "@mui/material";

const PREFIX = "SwiperCarousel";

export const classes = {
  swiperNavRowContainer: `${PREFIX}-swiperNavRowContainer`,
  swiperNavContainer: `${PREFIX}-swiperNavContainer`,
  swiperNavButton: `${PREFIX}-swiperNavButton`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.swiperNavRowContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 1), // Add padding for small screens
  },
  [`& .${classes.swiperNavContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  [`& .${classes.swiperNavButton}`]: {
    borderRadius: 5,
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    "& svg": {
      color: theme.palette.primary.main,
    },
  },

  // Swiper-specific styles
  [`& .swiper-slide`]: {
    height: "auto !important",
    display: "flex",
    "& > div": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },

  [`& .swiper`]: {
    paddingBottom: "20px",
  },

  [`& .swiper-wrapper`]: {
    height: "auto !important",
    alignItems: "stretch",
  },
}));

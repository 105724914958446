import { styled } from "@mui/material";

const PREFIX = "ImageMarker";

export const classes = {
  plotPoint: `${PREFIX}-plotPoint`,
  plotAndAddContainer: `${PREFIX}-plotAndAddContainer`,
  roomListItem: `${PREFIX}-roomListItem`,
  roomName: `${PREFIX}-roomName`,
  floorAndButtonsContainer: `${PREFIX}-floorAndButtonsContainer`,
  pointListContainer: `${PREFIX}-pointListContainer`,
  pointsAndAddContainer: `${PREFIX}-pointsAndAddContainer`,
  pointsByFloorContainer: `${PREFIX}-pointsByFloorContainer`,
  svg: `${PREFIX}-svg`,
  addPointButton: `${PREFIX}-addPointButton`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.plotPoint}`]: {
    width: 30,
    height: 30,
    fontSize: 14,
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.plotAndAddContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  [`& .${classes.roomListItem}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.roomName}`]: {
    fontWeight: "bold",
  },
  [`& .${classes.floorAndButtonsContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.pointListContainer}`]: {
    width: 24,
    height: 24,
    fontSize: 15,
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  [`& .${classes.pointsAndAddContainer}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  [`& .${classes.pointsByFloorContainer}`]: {
    display: "flex",
    flexWrap: "wrap",
  },
  [`& .${classes.svg}`]: {
    width: "100%",
    height: "100%",
    display: "block",
  },
  [`& .${classes.addPointButton}`]: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    "&:hover": {
      borderColor: theme.palette.common.white,
    },
  },
}));

import capitalize from "lodash/capitalize";

import {
  Equipment,
  Estimate,
  PolyvectorPayload,
} from "@library/domain/estimate";
import { APIModel } from ".";
import { Theme } from "@mui/material";

export const castEstimates = (estimates: Estimate[], theme: Theme) => {
  return estimates.map((e) => {
    return {
      ...e,
      polyvectorPayload: new PolyvectorPayloadModel(
        { ...e.polyvectorPayload, FinancingPartner: e.FinancingPartner },
        theme
      ),
    };
  });
};

const calculateMonthlyPayment = (
  principal: number,
  annualRate: number,
  months: number
) => {
  const monthlyRate = annualRate / 12; // Convert annual rate (APY) to monthly rate
  return (
    (principal * monthlyRate * Math.pow(1 + monthlyRate, months)) /
    (Math.pow(1 + monthlyRate, months) - 1)
  );
};

export const calculateImputedValues = (pp: PolyvectorPayload, theme: Theme) => {
  let apr: number | null = null;
  const financingPartners = pp.FinancingPartner || [];
  financingPartners.forEach((fp) => {
    if (fp.config?.apr) {
      if (!apr || fp.config.apr < apr) {
        apr = fp.config.apr;
      }
    }
  });
  if (!apr) {
    apr = 0.08;
  }
  const equipment = (pp.equipment || {}) as Equipment;
  const installedCostTotal = pp.installedCostTotal || 0;
  const rebates = pp.rebates || [];
  const rebatesNow = pp.discountBreakdown?.rebates_now || 0;
  const rebatesLater = pp.discountBreakdown?.rebates_later || 0;
  const creditsLater = pp.discountBreakdown?.credits_later || 0;
  const potentiallyEligible =
    pp.discountBreakdown?.all_potentially_eligible || 0;
  let upFrontCostTotal = installedCostTotal - rebatesNow;
  if (upFrontCostTotal < 0) upFrontCostTotal = 0;
  const netPrice = upFrontCostTotal - rebatesLater - creditsLater;
  const incentivesTotal = rebatesNow + rebatesLater + creditsLater;
  const potentialAdditionalIncentives =
    rebatesLater + creditsLater + potentiallyEligible;
  const newSystemType = `${capitalize(pp.pricingSelectedTier as string)} ${pp.equipmentScope?.new?.primary_heating?.type || pp.equipmentScope?.new?.cooling?.type}`;
  const pricingSelectedTier = pp.pricingSelectedTier || "";
  const equipmentCapacity =
    Math.max(
      ...(equipment?.[pricingSelectedTier] || []).map((o) => o.capacity)
    ) / 12000;

  const billImpactDirection =
    pp.billImpactEstimate === 0
      ? "same"
      : pp.billImpactEstimate > 0
        ? "increase"
        : "decrease";
  const billImpactExplanation =
    theme.t(
      `concierge.PackageDetails.PackageCard.recommendations.billImpactExplanation.${billImpactDirection}`,
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...((pp || {}) as unknown as any),
        billImpactEstimateAbs: Math.abs((pp.billImpactEstimate as number) || 0),
      }
    ) || "";
  const climateImpactDirection =
    pp.climateImpactEstimate === 0
      ? "same"
      : pp.climateImpactEstimate > 0
        ? "increase"
        : "decrease";
  const climateImpactExplanation =
    theme.t(
      `concierge.PackageDetails.PackageCard.recommendations.climateImpactExplanation.${climateImpactDirection}`,
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...((pp || {}) as unknown as any),
        climateImpactEstimateAbs: Math.ceil(
          Math.abs(pp.climateImpactEstimate as number) || 0
        ),
      }
    ) || "";
  let afterEligibleRebates = installedCostTotal - rebatesNow;
  if (afterEligibleRebates < 0) afterEligibleRebates = 0;
  const afterAllEligibleRebates =
    installedCostTotal - rebatesNow - rebatesLater;
  const afterAllEligibleIncentives =
    installedCostTotal - rebatesNow - rebatesLater - creditsLater;
  const monthlyPayment = calculateMonthlyPayment(
    afterEligibleRebates,
    apr,
    120
  );

  return {
    ...pp,
    upFrontCostTotal,
    incentivesTotal,
    netPrice,
    potentialAdditionalIncentives,
    newSystemType,
    equipmentCapacity,
    billImpactExplanation,
    climateImpactExplanation,
    afterEligibleRebates,
    afterAllEligibleRebates,
    afterAllEligibleIncentives,
    monthlyPayment,
    rebates,
    rebatesNow,
    rebatesLater,
    creditsLater,
    potentiallyEligible,
  } as PolyvectorPayload;
};

export class PolyvectorPayloadModel extends APIModel {
  constructor(data: PolyvectorPayload, theme: Theme) {
    super(data, theme);
  }

  initialize() {
    this._data = calculateImputedValues(this._data, this.theme!);
    super.initialize();
  }
}

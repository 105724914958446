import { styled } from "@mui/material";

const PREFIX = "MagicLinkForm";

export const classes = {
  loginLinkButton: `${PREFIX}-loginLinkButton`,
  leftOffText: `${PREFIX}-leftOffText`,
  backButton: `${PREFIX}-backButton`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.loginLinkButton}`]: {
    height: 50,
    borderRadius: 25,
    marginTop: theme.spacing(3),
  },
  [`& .${classes.leftOffText}`]: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  [`& .${classes.backButton}`]: {
    color: theme.palette.text.primary,
  },
}));

import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Grid2,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useContext, useEffect, useState } from "react";

import { TextField } from "@mui/material";

import logo from "@library/theme/multitenancy/pearl/logo.png";
import bg from "@library/theme/multitenancy/home-background.jpg";
import { SettingsContext } from "@library/settings/provider";
import supabase from "@library/api/supabase";

import GoogleSignInButton from "@library/components/GoogleSignInButton";
import { Link, useNavigate } from "react-router-dom";
import { classes, Root } from "./styles";
import MagicLinkForm from "./components/MagicLinkForm";
import { ArrowBack } from "@mui/icons-material";

const baseLanguageLocation = "LoginPageView";

const MagicLinkFormWithSwitch = ({
  onSwitchView,
}: {
  onSwitchView: () => void;
}) => {
  const theme = useTheme();
  return (
    <>
      <Stack className={classes.formContainer}>
        <MagicLinkForm />
      </Stack>
      <Button
        onClick={onSwitchView}
        variant="outlined"
        className={classes.loginAnotherWayButton}
      >
        {theme?.t(`${baseLanguageLocation}.logInAnotherWay`)}
      </Button>
      {/* <Typography color="primary.contrastText" textAlign="center">
        {theme?.t(`${baseLanguageLocation}.noAccount`)}{" "}
        <Link to="/signup" className={classes.signUpButton}>
          {theme?.t(`${baseLanguageLocation}.signUp`)}
        </Link>
      </Typography> */}
    </>
  );
};

const LoginForm = ({
  handleProviderSignIn,
  authError,
  setAuthError,
  onSwitchView,
}: {
  handleProviderSignIn: (provider: string, token: string) => void;
  authError: string;
  setAuthError: (error: string) => void;
  onSwitchView: () => void;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated } = useContext(SettingsContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    setIsLoading(false);
    if (response?.error) {
      setAuthError(response?.error?.message ?? "An unknown error occured.");
    } else {
      setIsAuthenticated(true);
    }
  };

  useEffect(() => {
    if (window.location.href.includes("/login")) {
      if (!isAuthenticated) return;
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const isError = Boolean(authError);

  return (
    <form onSubmit={handleOnSubmit}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          {authError && <Alert severity="error">{authError}</Alert>}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton className={classes.backButton} onClick={onSwitchView}>
              <ArrowBack />
            </IconButton>
            <Typography color="text.secondary">
              {theme?.t(`${baseLanguageLocation}.logInWithEmail`)}
            </Typography>
            {/* Invisible element to balance the space */}
            <Box sx={{ width: 40 }} /> {/* Width matching IconButton */}
          </Stack>
          <TextField
            name="email"
            label="Email"
            defaultValue={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            InputProps={{ error: isError }}
          />
          <TextField
            name="password"
            label="Password"
            defaultValue={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            InputProps={{
              type: "password",
              error: isError,
            }}
          />
          <Button
            type="submit"
            variant="contained"
            data-testid="submit"
            disabled={isLoading}
            className={classes.loginButton}
          >
            {theme?.t(`${baseLanguageLocation}.logIn`)}
          </Button>
          <Link to="/reset-password" className={classes.forgotPasswordLink}>
            <Button className={classes.forgotPasswordButton}>
              {theme?.t(`${baseLanguageLocation}.forgotPassword`)}
            </Button>
          </Link>
        </Stack>
        <Divider sx={{ my: "8px !important" }}>
          <Chip label="Or" size="small" />
        </Divider>
        <Stack alignItems="center" spacing={2}>
          <Typography color="text.secondary">
            {theme?.t(`${baseLanguageLocation}.logInWithGoogle`)}
          </Typography>
          <GoogleSignInButton onSignUp={handleProviderSignIn} />
        </Stack>
      </Stack>
    </form>
  );
};

export const LoginPageView = () => {
  const theme = useTheme();
  const { setIsAuthenticated, setIsLoading } = useContext(SettingsContext);
  const [authError, setAuthError] = React.useState<string>("");
  const [loginView, setLoginView] = React.useState<
    "magic-link" | "traditional"
  >("magic-link");

  const handleProviderSignIn = async (provider: string, token: string) => {
    const response = await supabase.auth.signInWithIdToken({
      provider,
      token,
    });
    if (response?.error) {
      setAuthError(response?.error?.message ?? "An unknown error occured.");
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setIsAuthenticated(true);
    }
  };

  const handleSwitchView = () => {
    setLoginView(loginView === "magic-link" ? "traditional" : "magic-link");
    setAuthError(""); // Clear any existing errors when switching views
  };

  const renderLoginContent = () => {
    if (loginView === "magic-link") {
      return <MagicLinkFormWithSwitch onSwitchView={handleSwitchView} />;
    }

    return (
      <Stack className={classes.formContainer}>
        <LoginForm
          handleProviderSignIn={handleProviderSignIn}
          authError={authError}
          setAuthError={setAuthError}
          onSwitchView={handleSwitchView}
        />
      </Stack>
    );
  };

  return (
    <Root>
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        flexDirection="row"
        sx={{
          position: "absolute",
          top: 0,
          zIndex: 1100,
        }}
      >
        <Grid2
          container
          spacing={0}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{ width: "100%", minHeight: "100vh" }}
        >
          <Grid2
            sx={{
              display: ["none", "none", "initial"],
              minHeight: "100vh",
              backgroundColor: "green",
              backgroundImage: `url(${bg})`,
            }}
            size={{
              md: 6,
              sm: 0,
              xs: 0,
            }}
          />
          <Grid2
            alignItems="center"
            justifyContent="center"
            bgcolor="primary.main"
            sx={{ minHeight: "100vh" }}
            size={{
              md: 6,
              sm: 12,
              xs: 12,
            }}
          >
            <Stack justifyContent="center" alignItems="center" height="100vh">
              <Link to={window.location.origin}>
                <img
                  alt="Pearl Logo"
                  src={logo}
                  style={{
                    width: "297px",
                  }}
                />
              </Link>
              <Stack
                alignSelf="center"
                color="primary.contrastText"
                sx={{ mb: 3 }}
              >
                {theme?.t(`${baseLanguageLocation}.pearlDescription`)}
              </Stack>
              {renderLoginContent()}
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </Root>
  );
};

export default LoginPageView;

import React from "react";
import { Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { UploadFile } from "@mui/icons-material";
import { Root, classes } from "./styles";

interface ImageUploadWithPlaceholderProps {
  id: string;
  onImageUpload: (id: string, file: File) => void;
  imageUrl?: string;
  index?: string;
  disabled?: boolean;
  inverted?: boolean;
}

const ImageUploadWithPlaceholder: React.FC<ImageUploadWithPlaceholderProps> = ({
  id = "",
  onImageUpload,
  imageUrl,
  index = "",
  disabled = false,
  inverted = false,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png", ".jpg", ".gif", ".svg"],
    },
    onDrop: (acceptedFiles) => {
      if (!disabled && acceptedFiles.length > 0) {
        onImageUpload(id, acceptedFiles[0]);
      }
    },
    disabled: disabled, // Disable the dropzone when the component is disabled
  });

  if (disabled && !imageUrl) {
    return null; // Don't render anything if disabled and no image
  }

  return (
    <Root>
      <Box
        {...getRootProps()}
        id={index}
        className={`${classes.placeholderBox} ${disabled ? classes.disabled : ""} ${inverted ? classes.inverted : ""}`}
      >
        <input {...getInputProps()} disabled={disabled} />
        {imageUrl ? (
          <Box className={classes.imageContainer}>
            <img
              src={imageUrl}
              alt={`Annotation ${index}`}
              className={classes.image}
            />
          </Box>
        ) : !disabled ? (
          <UploadFile color="primary" />
        ) : null}
        <Box className={classes.marker}>
          <Typography>{index}</Typography>
        </Box>
        {imageUrl && !disabled && (
          <Box className={classes.reuploadMarker}>
            <UploadFile color="primary" />
          </Box>
        )}
      </Box>
    </Root>
  );
};

export default ImageUploadWithPlaceholder;

import React from "react";
import { Typography, Box, Grid2, Card, Divider, useTheme } from "@mui/material";
import { Root, classes } from "./styles";
import { SectionContentProps } from "../../types";
import {
  formatCurrency,
  formatPricingData,
  languageJoinArray,
} from "@library/common";
import { parseTextForKeywords } from "../../utils/textParser";
import Language from "@library/components/Language";
import ComingSoon from "@library/components/ConciergeV3/components/ComingSoon";
import moment from "moment";

const happyCustomer = "/assets/happyCustomer.png";

const baseLanguageLocation = "concierge.PackageDetails.SummaryContent";

export const SummaryContent: React.FC<SectionContentProps> = ({
  job,
  package: pkg,
  theme: _theme,
}) => {
  let theme = useTheme();
  theme = _theme || theme;
  const formattedSections = formatPricingData(pkg?.polyvectorPayload);

  const eligibleRebate = pkg?.polyvectorPayload?.rebatesNow;
  const monthlyPayment = pkg?.polyvectorPayload?.monthlyPayment;
  const afterEligibleRebates = pkg?.polyvectorPayload?.afterEligibleRebates;

  const bi = pkg?.polyvectorPayload?.billImpactEstimate || 0;
  let billImpactSeverity = "same";
  if (bi <= -300) billImpactSeverity = "greatly_decrease";
  else if (bi <= -100) billImpactSeverity = "decrease";
  else if (bi < 100) billImpactSeverity = "same";
  else if (bi >= 100) billImpactSeverity = "increase";
  else if (bi >= 300) billImpactSeverity = "greatly_increase";

  const ci = pkg?.polyvectorPayload?.climateImpactEstimate || 0;
  let climateImpactSeverity = "";
  if (ci <= -2) climateImpactSeverity = "greatly_decrease";
  else if (ci <= 0) climateImpactSeverity = "decrease";
  else if (ci > 1) climateImpactSeverity = "increase";
  const templateData = {
    ...pkg,
    tier: theme.t(`tier.${pkg?.tier || ""}.name`),
    // @TODO these fields will be replaced with meta values
    newSystemType:
      pkg?.polyvectorPayload?.equipmentScope?.new?.primary_heating?.type,
    newSystemCapacity: Math.ceil(
      (pkg?.polyvectorPayload?.equipmentScope?.new?.primary_heating?.capacity ||
        0) / 12000
    ),
    newSystemDistribution:
      pkg?.polyvectorPayload?.pricingSelectedTier || "ducted",
    newSystemFuelType:
      pkg?.polyvectorPayload?.equipmentScope?.new?.primary_heating
        ?.energy_type === "electricity" &&
      (pkg?.polyvectorPayload?.equipmentScope?.new?.secondary_heating
        ?.energy_type === "electricity" ||
        !pkg?.polyvectorPayload?.equipmentScope?.new?.secondary_heating
          ?.energy_type)
        ? "electric"
        : "dual_fuel",
    weatherizationItems: languageJoinArray(
      (pkg?.polyvectorPayload?.lineItems || [])
        .filter((li) => li.type === "Weatherization")
        .map((li) => li.name.toLowerCase()) || ([] as string[])
    ),
    newSystemScope:
      pkg?.polyvectorPayload?.equipmentScope?.new?.primary_heating?.type &&
      pkg?.polyvectorPayload?.equipmentScope?.new?.cooling?.type
        ? "heat_cool"
        : pkg?.polyvectorPayload?.equipmentScope?.new?.primary_heating?.type
          ? "heat"
          : pkg?.polyvectorPayload?.equipmentScope?.new?.cooling?.type
            ? "cool"
            : "",
    billImpactSeverity,
    climateImpactSeverity,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as unknown as any;
  const { decoratedText, footnotes } = parseTextForKeywords(
    theme.t(`tier.${pkg?.tier || ""}.summary`, templateData) ||
      theme.t(`tier.summary`, templateData) ||
      ""
  );

  const comingSoon = pkg?.polyvectorPayload?.rebates?.find(
    (i) => moment(i.startDate) > moment()
  );

  const financingPartners = (job?.FinancingPartner || []).sort(
    (a, b) => (a.config?.apr || 100) - (b.config?.apr || 100)
  );

  return (
    <Root>
      <Box>
        {decoratedText}
        <Typography
          variant={"h5"}
          className={classes.sectionContentSubtitle}
          sx={{ mt: 2, mb: 1 }}
        >
          {theme?.t(`${baseLanguageLocation}.subtitle`)}:
        </Typography>
        <Grid2 container spacing={3}>
          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <Box>
              {footnotes.map((footnote, index) => (
                <Box className={classes.smallCardContainer} key={index}>
                  <Card className={classes.smallCard}>
                    <Typography
                      component="div"
                      className={classes.footnoteIndex}
                    >
                      {index + 1}
                    </Typography>
                    <Grid2 container>
                      <Grid2
                        className={classes.smallCardIconContainer}
                        size={3}
                      >
                        {footnote.icon ? (
                          <img
                            src={footnote.icon}
                            alt={footnote.term}
                            style={{
                              width: 80,
                              height: 80,
                              objectFit: "contain",
                            }}
                          />
                        ) : null}
                      </Grid2>
                      <Grid2
                        className={classes.smallCardTextContainer}
                        size={9}
                      >
                        <Typography className={classes.boldText}>
                          {footnote.term}
                        </Typography>
                        <Typography variant="body2">
                          {footnote.description}
                        </Typography>
                        {footnote.learnMoreLink ? (
                          <a href={footnote.learnMoreLink}>
                            {theme?.t(`${baseLanguageLocation}.learnMore`)}
                          </a>
                        ) : null}
                      </Grid2>
                    </Grid2>
                  </Card>
                </Box>
              ))}
            </Box>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <Card className={classes.largeCard}>
              <Typography
                variant={"h5"}
                className={classes.largeCardTitle}
                sx={{ ml: 2, mb: 2 }}
              >
                {theme?.t(`${baseLanguageLocation}.summaryQuote`)}
              </Typography>
              <img src={happyCustomer} style={{ width: "100%" }} />
              {formattedSections.map((section, index) => (
                <React.Fragment key={`${section.title}-${index}`}>
                  <Box
                    className={classes.contentLineItem}
                    sx={{ py: 1, px: 2 }}
                  >
                    <Typography>{section.title}</Typography>
                    <Typography>${section.total.toLocaleString()}</Typography>
                  </Box>
                </React.Fragment>
              ))}
              <Divider sx={{ mt: 2 }} />
              <Box className={classes.contentLineItem} sx={{ pt: 2, px: 2 }}>
                <Typography>
                  {theme?.t(`${baseLanguageLocation}.cost`)}
                </Typography>
                <Typography>
                  {formatCurrency(
                    pkg?.polyvectorPayload?.installedCostTotal || 0
                  )}
                </Typography>
              </Box>
              <Box className={classes.contentLineItem} sx={{ pt: 1, px: 2 }}>
                <Typography>
                  {theme?.t(`${baseLanguageLocation}.rebates`)}
                </Typography>
                <Typography>- {formatCurrency(eligibleRebate)}</Typography>
              </Box>
              {comingSoon ? (
                <Box className={classes.contentLineItem} sx={{ pt: 1, px: 2 }}>
                  <ComingSoon incentive={comingSoon} />
                </Box>
              ) : null}
              <Box className={classes.contentLineItem} sx={{ pt: 1, px: 2 }}>
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.costAfterRebates`)}
                </Typography>
                <Typography className={classes.boldText}>
                  {" "}
                  {formatCurrency(afterEligibleRebates)}
                </Typography>
              </Box>
              {financingPartners.map((fp) => {
                return (
                  <Box key={fp.uuid} className={classes.financeBoxContainer}>
                    {fp.imageUrl ? (
                      <a href={fp.url} target="_blank">
                        <img src={fp.imageUrl} width="100%" />
                      </a>
                    ) : null}
                    <Box>
                      <Language
                        languageString={fp.description}
                        markdown={true}
                        values={{
                          ...fp,
                          amount: formatCurrency(monthlyPayment),
                        }}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Card>
          </Grid2>
        </Grid2>
      </Box>
    </Root>
  );
};

import { Dialog, styled } from "@mui/material";

const PREFIX = "RepresentativeDialog";

export const classes = {
  repDialogTitle: `${PREFIX}-repDialogTitle`,
  avatarAndInfoContainer: `${PREFIX}-avatarAndInfoContainer`,
  userInfoContainer: `${PREFIX}-userInfoContainer`,
  boldText: `${PREFIX}-boldText`,
  emailAndPhoneContainer: `${PREFIX}-emailAndPhoneContainer`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.repDialogTitle}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.avatarAndInfoContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.userInfoContainer}`]: {
    padding: `0 ${theme.spacing(2)}`,
  },
  [`& .${classes.boldText}`]: {
    fontWeight: 500,
  },
  [`& .${classes.emailAndPhoneContainer}`]: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${theme.spacing(1)} 0`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

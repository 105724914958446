import React, { useContext, useEffect } from "react";
import { Alert, Box, Grid2, Toolbar } from "@mui/material";

import "@library/custom.d.ts";

import { SettingsContext } from "@library/settings/provider";
import { useNavigate } from "react-router-dom";

import { EMAIL, PHONE_NUMBER } from "@library/common";
import { Job } from "@library/domain/job";
import { Quote } from "@library/domain/quote";

const ConciergePageView = () => {
  const navigate = useNavigate();
  const { user } = useContext(SettingsContext);

  useEffect(() => {
    if (user.email) {
      const jobs = user.Home?.[0]?.Job || [];
      if (jobs.length) {
        const sortedJobs = jobs.sort((a: Job, b: Job) => {
          const aDate = new Date(a.createdAt).valueOf();
          const bDate = new Date(b.createdAt).valueOf();
          return bDate - aDate;
        });
        const mostRecentJob = sortedJobs[0];
        if (mostRecentJob) {
          // @TODO THIS ALL GOES AWAY AS SOON AS WE HAVE A USER DASHBOARD
          const quotes = mostRecentJob.Quote || [];
          const sortedQuotes = quotes.sort((a: Quote, b: Quote) => {
            const aDate = new Date(a.createdAt).valueOf();
            const bDate = new Date(b.createdAt).valueOf();
            return bDate - aDate;
          });
          if (mostRecentJob?.Onboarding?.status === "COMPLETED") {
            const mostRecentQuote = sortedQuotes[0];
            if (mostRecentQuote?.id) {
              navigate(`/job/${mostRecentJob.id}/${mostRecentQuote.id}`);
            } else {
              navigate(`/job/${mostRecentJob.id}`);
            }
          } else {
            navigate("/onboarding");
          }
        }
      }
    } else {
      navigate("/onboarding");
    }
  }, [user, navigate]);

  return (
    <>
      <Box component={"main"}>
        <Toolbar />{" "}
        <Grid2
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "calc(100vh - 128px)" }}
          p={5}
        >
          <Grid2 size={3}>
            <Alert severity="error">
              {" "}
              We are unable to complete this request. Please contact us via the
              chat widget below, by email at {EMAIL} or by phone at{" "}
              {PHONE_NUMBER}.
            </Alert>
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default ConciergePageView;

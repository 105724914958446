/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import {
  Box,
  Grid2,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import CardWithHeader from "@library/components/CardWithHeader";
import ImageMarker from "@library/components/ImageMarker";
import { Job } from "@library/domain/job";
import { Annotation, AnnotationLink } from "@library/domain/image";
import uniqBy from "lodash/uniqBy";
import api from "@library/api";
import { Root, classes } from "./styles";

export const AssessmentSystemDesign = ({ slideState }: { slideState: any }) => {
  const job = slideState.job;

  const [systemType, setSystemType] = useState("ducted");
  const [fetchedImageIds, setFetchedImageIds] = useState<string[]>([]);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [annotationLinks, setAnnotationLinks] = useState<AnnotationLink[]>([]);
  const [floorplanFiles, setFloorplanFiles] = useState<
    Array<{
      file?: File;
      url?: string;
      name: string;
      size?: string;
      floor?: string;
      id?: string;
    }>
  >([]);

  // useEffect to fetch and process floorplan images and annotations
  useEffect(() => {
    if (!job?.Image?.length) {
      console.log("No job or images available yet");
      return;
    }

    const floorplanImages = job.Image.filter(
      (image: any) =>
        image.type === "floorplan" && !fetchedImageIds.includes(image.id)
    );

    if (!floorplanImages.length) return;

    const promises = floorplanImages.map((image: any) =>
      api.get("image", { id: image.id })
    );

    Promise.all(promises)
      .then((responses) => {
        if (!responses?.length) return;

        const updatedFloorplanFiles = [...floorplanFiles];
        const seenFloors = new Set();
        let newAnnotations: Annotation[] = [];
        let newAnnotationLinks: AnnotationLink[] = [];

        responses.forEach((response) => {
          const imageData = response.data;
          const jobImage = job?.Image?.find(
            (img: { id: string }) => img.id === imageData.id
          );

          if (jobImage && imageData.type === "floorplan") {
            const floor = imageData.title.split(" ")[0].toLowerCase();
            if (!seenFloors.has(floor)) {
              seenFloors.add(floor);
              const existingIndex = updatedFloorplanFiles.findIndex(
                (f) => f.id === imageData.id
              );
              if (existingIndex !== -1) {
                updatedFloorplanFiles[existingIndex] = {
                  ...updatedFloorplanFiles[existingIndex],
                  url: imageData.sizes?.default ?? imageData.sizes?.original,
                };
              } else {
                updatedFloorplanFiles.push({
                  url: imageData.sizes?.default ?? imageData.sizes?.original,
                  name: imageData.title,
                  floor: floor,
                  id: imageData.id,
                });
              }

              if (imageData.Annotation) {
                newAnnotations = [
                  ...newAnnotations,
                  ...imageData.Annotation.map((annotation: Annotation) => ({
                    ...annotation,
                    floor: floor,
                  })),
                ];

                // Fetch annotation links
                imageData.Annotation.forEach((annotation: Annotation) => {
                  if (annotation.sourceLinks) {
                    newAnnotationLinks = [
                      ...newAnnotationLinks,
                      ...annotation.sourceLinks,
                    ];
                  }
                  if (annotation.targetLinks) {
                    newAnnotationLinks = [
                      ...newAnnotationLinks,
                      ...annotation.targetLinks,
                    ];
                  }
                });
              }
            }
          }
        });

        setFetchedImageIds((prev) => [
          ...prev,
          ...floorplanImages.map((img: any) => img.id),
        ]);
        setFloorplanFiles(updatedFloorplanFiles);
        setAnnotations((prev) =>
          uniqBy([...prev, ...newAnnotations], (e) => e.id)
        );
        setAnnotationLinks((prev) =>
          uniqBy([...prev, ...newAnnotationLinks], (e) => e.id)
        );
      })
      .catch((error) => {
        console.error("Error fetching image data:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?.Image]);

  const handleSystemTypeChange = (event: SelectChangeEvent) => {
    setSystemType(event.target.value as string);
  };

  return (
    <Root>
      <Box className={classes.systemDesignTitleContainer}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          System Design
        </Typography>
        <Select
          value={systemType}
          onChange={handleSystemTypeChange}
          sx={{ mb: 2, ml: 2, height: 40 }}
        >
          <MenuItem value="ducted">Ducted</MenuItem>
          <MenuItem value="ductless">Ductless</MenuItem>
        </Select>
      </Box>
      <CardWithHeader
        padding={false}
        sx={{ mb: 2 }}
        title={systemType === "ducted" ? "Registers" : "Indoor Units"}
      >
        <Grid2 container className={classes.homeAssessmentContainer}>
          <Grid2 size={12}>
            <ImageMarker
              job={job as Job}
              type={systemType === "ducted" ? "register" : "indoorUnits"}
              key={annotations.length}
              floorplans={floorplanFiles.map((file) => ({
                floor: file.floor || "",
                imageFile: file.file,
                url: file.url,
                imageId: file.id || "",
              }))}
              rooms={[
                // as an example
                { id: "1", name: "Kitchen" },
                { id: "2", name: "Living Room" },
                { id: "3", name: "Bedroom" },
                { id: "4", name: "Laundry Room" },
              ]}
              annotations={annotations}
              annotationLinks={annotationLinks}
            />
          </Grid2>
        </Grid2>
      </CardWithHeader>
    </Root>
  );
};

export default AssessmentSystemDesign;

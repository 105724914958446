import { useTheme } from "@mui/material";
import React from "react";

export const Checklist = ({
  styles,
  dark,
}: {
  styles?: object;
  dark?: boolean;
}) => {
  const theme = useTheme();
  return (
    <svg
      width="58"
      height="80"
      viewBox="0 0 58 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M22.8386 7.18213C22.8386 3.77903 25.5974 1.02002 29.0007 1.02002C32.404 1.02002 35.1628 3.77903 35.1628 7.18213H39.0616C41.58 7.18213 43.6216 8.95722 43.6216 11.1472V17.5027H14.3799V11.1472C14.3799 8.95722 16.4214 7.18213 18.9398 7.18213H22.8386Z"
        style={{
          stroke: dark
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.955 12.166H56.6421V78.9797H1.3584V12.166H13.9524"
        style={{
          stroke: dark
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.8325 12.6418V70.5139H9.16797V12.6418"
        style={{
          stroke: dark
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5986 57.9492H26.5352"
        style={{
          stroke: dark
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5986 43.3408H26.5352"
        style={{
          stroke: dark
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5986 28.7324H26.5352"
        style={{
          stroke: dark
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.9902 26.1016L39.2517 31.363"
        style={{
          stroke: dark
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.2517 26.1016L33.9902 31.363"
        style={{
          stroke: dark
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.6367 43.7021L35.9844 46.0498L41.4021 40.6321"
        style={{
          stroke: dark
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.6367 58.3105L35.9844 60.6584L41.4021 55.2405"
        style={{
          stroke: dark
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Checklist;

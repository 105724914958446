import React from "react";
import { KEYWORD_LIBRARY } from "./constants";
import { KeywordData, ParsedContent } from "./types";
import { Box, Typography } from "@mui/material";
import { classes, Root } from "./styles";

export const parseTextForKeywords = (text: string): ParsedContent => {
  // Store found keywords to avoid duplicates and maintain footnote ordering
  const foundKeywords: KeywordData[] = [];

  // Split input text into array of individual words
  const words = text.split(" ");

  // Array to store our final JSX elements (both regular text and highlighted keywords)
  const result: JSX.Element[] = [];

  // Iterate through each word in the text
  for (let i = 0; i < words.length; i++) {
    // Flag to track if we found a keyword (single or multi-word)
    let found = false;

    // Check each possible keyword from our library
    for (const keyword of Object.keys(KEYWORD_LIBRARY)) {
      // Count how many words are in this keyword (e.g., "heat pump" has 2 words)
      const wordCount = keyword.split(" ").length;

      // Create a potential phrase starting from current word
      // Example: if we're at "heat" and checking "heat pump":
      // words.slice(i, i + 2) would give us ["heat", "pump"]
      // .join(' ') converts it back to "heat pump"
      const possiblePhrase = words
        .slice(i, i + wordCount)
        .join(" ")
        .toLowerCase();

      // Check if our possible phrase matches the current keyword
      if (possiblePhrase === keyword.toLowerCase()) {
        // Get the keyword data from our library
        const keywordData = KEYWORD_LIBRARY[keyword];

        // Add to foundKeywords if we haven't seen it before
        if (
          !foundKeywords.some(
            (k) => k.term.toLowerCase() === keyword.toLowerCase()
          )
        ) {
          foundKeywords.push(keywordData);
        }

        // Find index using lowercase comparison
        const footnoteIndex =
          foundKeywords.findIndex(
            (k) => k.term.toLowerCase() === keyword.toLowerCase()
          ) + 1;

        // Create the highlighted text with footnote
        result.push(
          <React.Fragment key={i}>
            <Typography
              component="span"
              sx={{
                fontWeight: "bold",
                color: "primary.main",
                display: "inline",
                position: "relative",
                fontSize: 20,
              }}
            >
              {words.slice(i, i + wordCount).join(" ")}
              <Typography
                component="sup"
                className={classes.superscriptContainer}
              >
                {footnoteIndex}
              </Typography>
            </Typography>{" "}
          </React.Fragment>
        );

        // Skip ahead by (wordCount - 1) words since we've processed them
        // Example: if we processed "heat pump", we skip 1 word
        // because the main loop will increment by 1 more
        i += wordCount - 1;
        found = true;
        break;
      }
    }

    // If we didn't find any keywords, just add the word as plain text
    if (!found) {
      result.push(<React.Fragment key={i}>{words[i]} </React.Fragment>);
    }
  }

  return {
    decoratedText: (
      <Root>
        <Box sx={{ fontSize: 20 }}>{result}</Box>
      </Root>
    ),
    footnotes: foundKeywords,
  };
};

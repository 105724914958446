import { RegistryFieldsType } from "@rjsf/utils";

import CommunicationsField from "./CommunicationsField";
import HomeDetailsField from "./HomeDetailsField";
import RoomsConfiguration from "./RoomsConfiguration";
// import SketchPad from "./SketchPad";

export const widgets: RegistryFieldsType = {
  CommunicationsField,
  RoomsConfiguration,
  HomeDetailsField,
  // SketchPad,
};

export default widgets;

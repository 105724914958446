import React from "react";
import { Box, Card, Grid2, Typography, useTheme } from "@mui/material";

import Logos from "@library/components/Logos";

const brands = [
  "amana",
  "hisense",
  "mitsubishi",
  "bosch",
  "bryant",
  "carrier",
  "coleman",
  "daikin",
  "fujitsu",
  "ge",
  "goodman",
  "hitachi",
  "lennox",
  "lg",
  "midea",
  "rheem",
  "samsung",
  "toshiba",
  "trane",
  "york",
];

const BrandsWeInstallGrid = () => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        boxShadow: "none",
        border: `1px solid ${theme.palette.divider}`,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          padding: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
          bgcolor: "background.paper",
        }}
      >
        <Typography color="primary.main" fontSize={16} fontWeight={400}>
          Brands we install:
        </Typography>
      </Box>
      <Grid2 container>
        {brands.map((brand, rowIndex) => (
          <Grid2
            key={rowIndex}
            sx={{
              padding: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
              "& img": {
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              },
            }}
            size={3}
          >
            <Logos brand={brand} />
          </Grid2>
        ))}
      </Grid2>
    </Card>
  );
};

export default BrandsWeInstallGrid;

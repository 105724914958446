import { Card, styled } from "@mui/material";

const PREFIX = "CardWithHeader";

export const classes = {
  headerContainer: `${PREFIX}-headerContainer`,
  cardContentWithoutPadding: `${PREFIX}-cardContentWithoutPadding`,
  cardContentWithPadding: `${PREFIX}-cardContentWithPadding`,
  headerAndIconBoxContainer: `${PREFIX}-headerAndIconBoxContainer`,
};

export const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.headerContainer}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "& svg": {
      marginRight: theme.spacing(1),
    },
  },
  [`& .${classes.cardContentWithPadding}`]: {
    padding: theme.spacing(3),
  },
  [`& .${classes.cardContentWithoutPadding}`]: {
    padding: 0,
  },
  [`& .${classes.headerAndIconBoxContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

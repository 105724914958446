import React from "react";
import { useTheme } from "@mui/material";

export const Hatch = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 218 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H210C214.418 0.5 218 4.08172 218 8.5V152.5C218 156.918 214.418 160.5 210 160.5H8C3.58173 160.5 0 156.918 0 152.5V8.5Z"
        style={{ fill: theme.palette.grey["900"] }}
      />
      <path
        d="M149.271 85.5H69.2715"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M61.674 70.9998L62.8367 77.5942L154.606 61.4128L153.443 54.8184L61.674 70.9998Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.271 63.5V118.5H69.2715V77.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.271 118.5H55.2715"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.885 116.434L126.885 87.6519"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M134.885 116.434L134.885 87.6519"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M128.532 114.043H133.237"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M128.532 108.043H133.237"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M128.532 102.043H133.237"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M128.532 96.043H133.237"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M128.532 90.043H133.237"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M102.271 117.5V95.5H88.2715V117.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="92.2715" cy="108.5" r="1" fill="white" />
    </svg>
  );
};
export default Hatch;

import React from "react";
import { useTheme } from "@mui/material";

export const AboveGrade = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 218 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H210C214.418 0.5 218 4.08172 218 8.5V152.5C218 156.918 214.418 160.5 210 160.5H8C3.58173 160.5 0 156.918 0 152.5V8.5Z"
        style={{ fill: theme.palette.grey["900"] }}
      />
      <path
        d="M149 124.5V99.5H69V124.5"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M62 67.5L109 37.5L156 67.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59 61.5L109 29.5L159 61.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188 124.5H55"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.5 119.982V105H84.5V119.982"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M93 112.5L86 112.482"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M94.5 89.9824V75H84.5V89.9824"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.5 82.4912L84.5 82.4912"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.5 119.982V105H123.5V119.982"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M132 112.5L125 112.482"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M133.5 89.9824V75H123.5V89.9824"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.5 82.4912L123.5 82.4912"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.333 122.8V105.318H103.333V122.8"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <circle
        cx="106.333"
        cy="115.318"
        r="1"
        style={{ fill: theme.palette.secondary.main }}
      />
      <path
        d="M115.333 97.3184V76.3184H103.333V97.3184"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="106.333" cy="88.3184" r="1" fill="white" />
      <path
        d="M149 63.5V97.5M69 97.5V63.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M181 122.5V118.232H174.898V112.744H168.797V107.256H163.203V101.768H157.102V97.5H151"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default AboveGrade;

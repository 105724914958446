import React from "react";
import { Typography, Box, Grid2, Card, Divider } from "@mui/material";
import { Root, classes } from "./styles";
import { SectionContentProps } from "../../types";
import { formatCurrency, formatNumber } from "@library/common";
import { Rebate } from "@library/domain/estimate";
// import sumBy from "lodash/sumBy";
import Language from "@library/components/Language";
import ComingSoon from "../ComingSoon";
import moment from "moment";

const baseLanguageLocation = "concierge.PackageDetails.PricingContent";

export const PricingContent: React.FC<SectionContentProps> = ({
  job,
  theme,
  package: pkg,
}) => {
  const installedCostTotal = pkg?.polyvectorPayload?.installedCostTotal || 0;
  const eligibleRebatesTotal = pkg?.polyvectorPayload?.incentivesTotal || 0;
  const potentialRebatesTotal =
    pkg?.polyvectorPayload?.potentiallyEligible || 0;

  // Calculate costs after rebates
  const afterEligibleRebates =
    pkg?.polyvectorPayload?.afterEligibleRebates || 0;

  const afterAllEligibleRebates =
    pkg?.polyvectorPayload?.afterAllEligibleRebates || 0;
  const afterAllEligibleIncentives =
    pkg?.polyvectorPayload?.afterAllEligibleIncentives || 0;

  // Payment options calculations
  const dueAtSigning = Math.round(afterEligibleRebates * 0.5); // 50% down

  // Monthly payment
  const monthlyPayment = pkg?.polyvectorPayload?.monthlyPayment;

  const financingPartners = (job?.FinancingPartner || []).sort(
    (a, b) => (a.config?.apr || 100) - (b.config?.apr || 100)
  );
  const selfFinanceLineItems = [
    {
      name: theme?.t(
        `${baseLanguageLocation}.paymentOptions.options.first.dueAtAssessment`
      ),
      value: "0",
    },
    {
      name: theme?.t(
        `${baseLanguageLocation}.paymentOptions.options.first.dueAtSigning`
      ),
      value: dueAtSigning.toLocaleString(),
    },
    {
      name: theme?.t(
        `${baseLanguageLocation}.paymentOptions.options.first.dueAfterInstallation`
      ),
      value: (afterEligibleRebates - dueAtSigning).toLocaleString(),
    },
  ];

  const loanLineItems = [
    {
      name: theme?.t(
        `${baseLanguageLocation}.paymentOptions.options.second.downPayment`
      ),
      value: "0",
    },
    {
      name: theme?.t(
        `${baseLanguageLocation}.paymentOptions.options.second.apr`
      ),
      value: formatNumber((financingPartners?.[0]?.config?.apr || 0.08) * 100, {
        units: "%",
        toFixed: 1,
      }),
    },
    {
      name: theme?.t(
        `${baseLanguageLocation}.paymentOptions.options.second.loanTerm`
      ),
      value: "120",
    },
  ];

  return (
    <Root>
      <Box>
        <Typography className={classes.sectionContentDescription}>
          <Language
            languageKey={`${baseLanguageLocation}.introText`}
            markdown={true}
            values={{
              eligibleRebatesTotal,
              eligibleAmount: formatCurrency(eligibleRebatesTotal),
              potentialRebatesTotal,
              potentialAmount: formatCurrency(potentialRebatesTotal),
              totalAmount: formatCurrency(
                (eligibleRebatesTotal || 0) + (potentialRebatesTotal || 0)
              ),
            }}
          />
        </Typography>
        <Typography
          variant={"h4"}
          className={classes.sectionContentSubtitle}
          sx={{ mt: 2, mb: 1 }}
        >
          {theme?.t(`${baseLanguageLocation}.subtitle`)}:
        </Typography>
        <Grid2 container spacing={3}>
          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <Card className={classes.lightPurpleCard}>
              <Box
                className={`${classes.outlinedLightBorderBox} ${classes.contentLineItem}`}
              >
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.beforeRebates`)}
                </Typography>
                <Typography className={classes.boldText}>
                  {formatCurrency(installedCostTotal)}
                </Typography>
              </Box>

              <Box className={classes.rebateDetailContainer}>
                <Grid2 container className={classes.contentLineItem}>
                  <Grid2 size={8}>
                    <Typography
                      className={classes.boldText}
                      sx={{ fontSize: "0.9rem" }}
                    >
                      {theme?.t(`${baseLanguageLocation}.eligibleRebates`)}
                    </Typography>
                  </Grid2>
                  <Grid2 sx={{ textAlign: "right" }} size={4}>
                    <Typography>
                      -{formatCurrency(pkg?.polyvectorPayload?.rebatesNow || 0)}
                    </Typography>
                  </Grid2>
                </Grid2>
                <Grid2 size={8}>
                  <Typography variant="body2">
                    {theme?.t(
                      `${baseLanguageLocation}.eligibleRebatesDescription`
                    )}
                  </Typography>
                </Grid2>
                <Grid2 sx={{ mb: 2 }} size={4}></Grid2>
                {pkg?.polyvectorPayload?.rebates
                  ?.filter(
                    (r) =>
                      r.totals?.eligible > 0 &&
                      r.type === "rebate" &&
                      r.timing === "instant"
                  )
                  .map((r: Rebate) => {
                    // const instantlyEligible = r.items.filter(
                    //   (i) => i.eligibility === "eligible"
                    // );
                    const total = r.totals?.eligible || 0;
                    if (total <= 0) return null;
                    return (
                      <Grid2 container sx={{ mt: 0.5 }}>
                        <Grid2 size={4}>
                          <Typography variant="body2">
                            -{formatCurrency(total)}
                          </Typography>
                        </Grid2>
                        <Grid2 size={8}>
                          <Typography variant="body2">{r.name}</Typography>
                        </Grid2>
                        {moment(r.startDate) > moment() ? (
                          <Grid2 size={12} mt={2} mb={2}>
                            <ComingSoon incentive={r} />
                          </Grid2>
                        ) : null}
                      </Grid2>
                    );
                  })}
              </Box>
              <Box
                className={`${classes.filledDarkBox} ${classes.contentLineItem}`}
              >
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.totalAfterRebates`)}
                </Typography>
                <Typography className={classes.boldText}>
                  {formatCurrency(afterEligibleRebates)}
                </Typography>
              </Box>

              <Box className={classes.rebateDetailContainer}>
                <Grid2 container className={classes.contentLineItem}>
                  <Grid2 size={8}>
                    <Typography
                      className={classes.boldText}
                      sx={{ fontSize: "0.9rem" }}
                    >
                      {theme?.t(`${baseLanguageLocation}.payLater`)}
                    </Typography>
                  </Grid2>
                  <Grid2 sx={{ textAlign: "right" }} size={4}>
                    <Typography>
                      -
                      {formatCurrency(
                        pkg?.polyvectorPayload?.rebatesLater || 0
                      )}
                    </Typography>
                  </Grid2>
                </Grid2>
                <Grid2 size={8}>
                  <Typography variant="body2">
                    {theme?.t(`${baseLanguageLocation}.payLaterDescription`)}
                  </Typography>
                </Grid2>
                <Grid2 sx={{ mb: 2 }} size={4}></Grid2>
                {pkg?.polyvectorPayload?.rebates
                  ?.filter(
                    (r) =>
                      r.totals?.eligible > 0 &&
                      r.type === "rebate" &&
                      r.timing === "paid_later"
                  )
                  .map((r: Rebate) => {
                    // const paidLater = r.items.filter(
                    //   (i) => i.eligibility === "eligible"
                    // );
                    const total = r.totals?.eligible || 0;
                    if (total <= 0) return null;
                    return (
                      <Grid2 container sx={{ mt: 0.5 }}>
                        <Grid2 size={4}>
                          <Typography variant="body2">
                            -{formatCurrency(total)}
                          </Typography>
                        </Grid2>
                        <Grid2 size={8}>
                          <Typography variant="body2">{r.name}</Typography>
                        </Grid2>
                      </Grid2>
                    );
                  })}
              </Box>
              <Box
                className={`${classes.outlinedBox} ${classes.contentLineItem}`}
              >
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.totalAfterAllRebates`)}
                </Typography>
                <Typography className={classes.boldText}>
                  {formatCurrency(afterAllEligibleRebates)}
                </Typography>
              </Box>

              <Box className={classes.rebateDetailContainer}>
                <Grid2 container className={classes.contentLineItem}>
                  <Grid2 size={8}>
                    <Typography
                      className={classes.boldText}
                      sx={{ fontSize: "0.9rem" }}
                    >
                      {theme?.t(`${baseLanguageLocation}.credits`)}
                    </Typography>
                  </Grid2>
                  <Grid2 sx={{ textAlign: "right" }} size={4}>
                    <Typography>
                      -
                      {formatCurrency(
                        pkg?.polyvectorPayload?.creditsLater || 0
                      )}
                    </Typography>
                  </Grid2>
                </Grid2>
                <Grid2 size={8}>
                  <Typography variant="body2">
                    {theme?.t(`${baseLanguageLocation}.creditsDescription`)}
                  </Typography>
                </Grid2>
                <Grid2 sx={{ mb: 2 }} size={4}></Grid2>
                {pkg?.polyvectorPayload?.rebates
                  ?.filter(
                    (r) =>
                      r.totals?.eligible > 0 &&
                      r.type === "credit" &&
                      r.timing === "paid_later"
                  )
                  .map((r: Rebate) => {
                    // const paidLater = r.items.filter(
                    //   (i) => i.eligibility === "eligible"
                    // );
                    const total = r.totals?.eligible || 0;
                    if (total <= 0) return null;
                    return (
                      <Grid2 container sx={{ mt: 0.5 }}>
                        <Grid2 size={4}>
                          <Typography variant="body2">
                            -{formatCurrency(total)}
                          </Typography>
                        </Grid2>
                        <Grid2 size={8}>
                          <Typography variant="body2">{r.name}</Typography>
                        </Grid2>
                      </Grid2>
                    );
                  })}
              </Box>
              <Box
                className={`${classes.outlinedBox} ${classes.contentLineItem}`}
              >
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.afterTaxCredits`)}
                </Typography>
                <Typography className={classes.boldText}>
                  {formatCurrency(afterAllEligibleIncentives)}
                </Typography>
              </Box>
            </Card>

            {potentialRebatesTotal > 0 && (
              <Card className={classes.lightPurpleCard} sx={{ mt: 2 }}>
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.rebates.additional.title`)}
                </Typography>
                <Typography variant="body2">
                  {theme?.t(
                    `${baseLanguageLocation}.rebates.additional.description`,
                    {
                      amount: formatCurrency(potentialRebatesTotal),
                    }
                  )}
                </Typography>
              </Card>
            )}
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <Card>
              <Typography
                sx={{ p: 2 }}
                variant="h5"
                color="primary.light"
                className={classes.largeCardTitle}
              >
                {theme?.t(`${baseLanguageLocation}.paymentOptions.subtitle`)}
              </Typography>
              <Divider />

              {/* Self-Finance Option */}
              <Box sx={{ p: 2 }} className={classes.bottomBorder}>
                <Typography
                  className={classes.paymentOptionTitle}
                  sx={{ mb: 1 }}
                >
                  {theme?.t(
                    `${baseLanguageLocation}.paymentOptions.options.first.title`
                  )}
                </Typography>
                {selfFinanceLineItems.map((lineItem, index) => (
                  <Box
                    key={index}
                    className={classes.leftAlignedContentLineItem}
                    sx={{ mb: 1 }}
                  >
                    <Typography
                      variant="body2"
                      className={classes.initialContentLineItem}
                    >
                      {formatCurrency(lineItem.value)}
                    </Typography>
                    <Typography variant="body2">{lineItem.name}</Typography>
                  </Box>
                ))}
                <Box
                  className={`${classes.filledDarkBox} ${classes.leftAlignedContentLineItem}`}
                >
                  <Typography
                    className={`${classes.boldText} ${classes.initialContentLineItem}`}
                  >
                    {formatCurrency(afterEligibleRebates)}
                  </Typography>
                  <Typography className={classes.boldText} sx={{ ml: 2 }}>
                    {theme?.t(`${baseLanguageLocation}.totalPayment`)}
                  </Typography>
                </Box>
              </Box>

              {/* Financing Option */}
              <Box sx={{ p: 2 }}>
                <Typography
                  className={classes.paymentOptionTitle}
                  sx={{ mb: 1 }}
                >
                  {theme?.t(
                    `${baseLanguageLocation}.paymentOptions.options.second.title`
                  )}
                </Typography>
                <Box className={classes.leftAlignedContentLineItem}>
                  <Typography className={classes.boldText} variant="body2">
                    {formatCurrency(monthlyPayment)}/month
                  </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                {loanLineItems.map((lineItem, index) => (
                  <Box
                    key={index}
                    className={classes.leftAlignedContentLineItem}
                    sx={{ mb: 1 }}
                  >
                    <Typography
                      variant="body2"
                      className={classes.initialContentLineItem}
                    >
                      {lineItem.value}
                    </Typography>
                    <Typography variant="body2">{lineItem.name}</Typography>
                  </Box>
                ))}
                {financingPartners.map((fp) => {
                  return (
                    <Box key={fp.uuid} className={classes.miSaveslogoContainer}>
                      {fp.imageUrl ? (
                        <a href={fp.url} target="_blank">
                          <img src={fp.imageUrl} width="100%" />
                        </a>
                      ) : null}
                    </Box>
                  );
                })}
              </Box>
            </Card>
          </Grid2>
        </Grid2>
      </Box>
    </Root>
  );
};

import { useTheme } from "@mui/material";
import React from "react";

export const ElectricalUpgrades = ({ styles }: { styles?: object }) => {
  const theme = useTheme();
  return (
    <svg
      width="70"
      height="88"
      viewBox="0 0 70 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <rect
        x="1.4541"
        y="1.44189"
        width="67.0939"
        height="85.1156"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <rect
        x="8.12891"
        y="9.88232"
        width="53.7457"
        height="68.2347"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <rect
        x="59.4375"
        y="53.6204"
        width="4.86379"
        height="11.1513"
        rx="1.22489"
        fill={theme.palette.primary.main}
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <rect
        x="59.4375"
        y="23.9685"
        width="4.86379"
        height="11.1513"
        rx="1.22489"
        fill={theme.palette.primary.main}
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M18.5388 44.8965L35.0007 16.3838L51.4625 44.8965H18.5388Z"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M36.464 27.1641L31.9863 34.7015H37.9566L34.0013 41.1942"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <line
        x1="15.2119"
        y1="56.249"
        x2="34.299"
        y2="56.249"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <line
        x1="15.2119"
        y1="69.0107"
        x2="34.299"
        y2="69.0107"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <line
        x1="15.2119"
        y1="62.6296"
        x2="34.299"
        y2="62.6296"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <circle
        cx="45.612"
        cy="58.2121"
        r="2.91373"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <circle
        cx="45.612"
        cy="67.2201"
        r="2.91373"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
    </svg>
  );
};

export default ElectricalUpgrades;

import { styled } from "@mui/material";

const PREFIX = "ConciergePageView";

export const classes = {
  contentContainer: `${PREFIX}-contentContainer`,
  loadingContainer: `${PREFIX}-loadingContainer`,
  titleContainer: `${PREFIX}-titleContainer`,
  titleText: `${PREFIX}-titleText`,
  footerContainer: `${PREFIX}-footerContainer`,
  footerButton: `${PREFIX}-footerButton`,
  swiperNavRowContainer: `${PREFIX}-swiperNavRowContainer`,
  swiperNavContainer: `${PREFIX}-swiperNavContainer`,
  swiperNavButton: `${PREFIX}-swiperNavButton`,
  bottomBorderContainer: `${PREFIX}-bottomBorderContainer`,
  impactArrowIcon: `${PREFIX}-impactArrowIcon`,
  boldText: `${PREFIX}-boldText`,
  lightWeightText: `${PREFIX}-  lightWeightText`,
  lightIcon: `${PREFIX}-lightIcon`,
  recommendedStrongText: `${PREFIX}-recommendedStrongText`,
  recommendedPackageText: `${PREFIX}-recommendedPackageText`,
  cardFooterContent: `${PREFIX}-cardFooterContent`,
  cardFooterViewingText: `${PREFIX}-cardFooterViewingText`,
  cardFooterButton: `${PREFIX}-cardFooterButton`,
  packageContainer: `${PREFIX}-packageContainer`,
  selectedPackageContainer: `${PREFIX}-selectedPackageContainer`,
  selectedPackageArrow: `${PREFIX}-selectedPackageArrow`,
  packageDetailsContainer: `${PREFIX}-packageDetailsContainer`,
  packageDetailsTitleContainer: `${PREFIX}-packageDetailsTitleContainer`,
  packageDetailsTitleOuterContainer: `${PREFIX}-packageDetailsTitleOuterContainer`,
  packageDetailsTitle: `${PREFIX}-packageDetailsTitle`,
  mobileInterestedContainer: `${PREFIX}-mobileInterestedContainer`,
  packageDetailsSummary: `${PREFIX}-packageDetailsSummary`,
  interestedContainer: `${PREFIX}-interestedContainer`,
  thumbUpButton: `${PREFIX}-thumbUpButton`,
  thumbDownButton: `${PREFIX}-thumbDownButton`,
  verticalNavContainer: `${PREFIX}-verticalNavContainer`,
  verticalNavButton: `${PREFIX}-verticalNavButton`,
  verticalNavButtonActive: `${PREFIX}-verticalNavButtonActive`,
  mobileFooterContainer: `${PREFIX}-mobileFooterContainer`,
  mobileFooterIcons: `${PREFIX}-mobileFooterIcons`,
  mobileFooterButton: `${PREFIX}-mobileFooterButton`,
  mobileFooterTitle: `${PREFIX}-mobileFooterTitle`,
  mobileFooterButtonContent: `${PREFIX}-mobileFooterButtonContent`,
  mobileAdvisorDetails: `${PREFIX}-mobileAdvisorDetails`,
  advisorInfo: `${PREFIX}-advisorInfo`,
  contactRow: `${PREFIX}-contactRow`,
  disclaimerContainer: `${PREFIX}-disclaimerContainer`,
  disclaimerTitle: `${PREFIX}-disclaimerTitle`,
  disclaimerDescription: `${PREFIX}-disclaimerDescription`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.contentContainer}`]: {
    minHeight: "100vh",
    marginTop: "0 !important",
    padding: theme.spacing(3),
  },
  [`& .${classes.loadingContainer}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
  [`& .${classes.titleContainer}`]: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
  },
  [`& .${classes.titleText}`]: {
    color: theme.palette.primary.light,
  },
  [`& .${classes.swiperNavRowContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.swiperNavContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.swiperNavButton}`]: {
    borderRadius: 5,
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  [`& .${classes.bottomBorderContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.impactArrowIcon}`]: {
    marginBottom: -6,
  },
  [`& .${classes.boldText}`]: {
    fontWeight: 600,
  },
  [`& .${classes.lightWeightText}`]: {
    fontWeight: 300,
  },
  [`& .${classes.lightIcon}`]: {
    paddingLeft: theme.spacing(0.5),
    marginBottom: `-${theme.spacing(1)}`,
    color: theme.palette.primary.light,
  },
  [`& .${classes.footerContainer}`]: {
    zIndex: 2,
    width: "100%",
    bottom: 0,
    position: "fixed",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      minHeight: 60,
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  [`& .${classes.recommendedStrongText}`]: {
    color: theme.palette.primary.light,
  },
  [`& .${classes.recommendedPackageText}`]: {
    width: 200,
    borderRadius: 10,
    textAlign: "center",
    marginTop: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
  },
  [`& .${classes.cardFooterContent}`]: {
    width: "100%",
    textAlign: "center",
  },
  [`& .${classes.cardFooterViewingText}`]: {
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  [`& .${classes.cardFooterButton}`]: {
    width: "100%",
    borderRadius: 5,
    padding: theme.spacing(1.5),
  },
  [`& .${classes.packageContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    boxShadow: "none",
    borderRadius: "8px",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    height: "100%",
  },
  [`& .${classes.selectedPackageContainer}`]: {
    border: `3px solid ${theme.palette.primary.main} !important`,
  },
  [`& .${classes.selectedPackageArrow}`]: {
    position: "absolute",
    bottom: -20,
    left: "50%",
    transform: "translateX(-50%)",
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderTop: `20px solid ${theme.palette.primary.main}`,
    zIndex: 9999,
  },
  [`& .${classes.footerButton}`]: {
    height: "auto",
    minHeight: 40,
    fontSize: "1.125rem", // 18px equivalent
    fontWeight: 400,
    borderRadius: 5,
    padding: theme.spacing(1, 2),
    whiteSpace: "normal",
    lineHeight: 1.2,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem", // 14px equivalent
      padding: theme.spacing(0.75, 1.5),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8125rem", // 13px equivalent
      padding: theme.spacing(0.5, 1),
    },
  },
  [`& .${classes.packageDetailsContainer}`]: {
    borderRadius: 5,
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.packageDetailsTitleOuterContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.packageDetailsTitleContainer}`]: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.packageDetailsTitle}`]: {
    color: theme.palette.primary.light,
  },
  [`& .${classes.mobileInterestedContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  [`& .${classes.packageDetailsSummary}`]: {
    color: theme.palette.primary.light,
    fontWeight: 500,
  },
  [`& .${classes.interestedContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.thumbUpButton}`]: {
    borderColor: theme.palette.info.main,
    "&:hover": {
      borderColor: theme.palette.info.main,
    },
  },
  [`& .${classes.thumbDownButton}`]: {
    borderColor: theme.palette.error.main,
    "&:hover": {
      borderColor: theme.palette.error.main,
    },
  },
  [`& .${classes.verticalNavContainer}`]: {
    display: "flex",
    borderRadius: 10,
    position: "sticky",
    gap: theme.spacing(1),
    top: theme.spacing(10),
    flexDirection: "column",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },

  [`& .${classes.verticalNavButton}`]: {
    justifyContent: "flex-start",
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[100],
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
    textAlign: "left",
  },
  [`& .${classes.verticalNavButtonActive}`]: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light + "20", // 20 is for opacity
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.primary.light + "30",
    },
  },
  [`& .${classes.mobileFooterContainer}`]: {
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  [`& .${classes.mobileFooterTitle}`]: {
    color: "white",
    fontSize: "1rem",
    fontWeight: 500,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  [`& .${classes.mobileFooterButton}`]: {
    height: "auto",
    padding: theme.spacing(1),
    fontSize: "0.875rem",
    whiteSpace: "normal",
    lineHeight: 1.2,
  },
  [`& .${classes.mobileFooterIcons}`]: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  [`& .${classes.mobileFooterButtonContent}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  [`& .${classes.mobileAdvisorDetails}`]: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.common.white}`,
  },
  [`& .${classes.advisorInfo}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  [`& .${classes.contactRow}`]: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: theme.palette.common.white,
    "& svg": {
      fontSize: 18,
    },
    "& Typography": {
      fontSize: 14,
    },
  },
  [`& .${classes.disclaimerContainer}`]: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "75vw",
    },
    marginBottom: 100,
    padding: theme.spacing(2),
  },
  [`& .${classes.disclaimerTitle}`]: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  [`& .${classes.disclaimerDescription}`]: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  [`& .swiper-slide`]: {
    height: "auto",
    display: "flex",
    "& > div": {
      // The Box wrapper around the Card
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },
  [`& .swiper`]: {
    paddingBottom: "20px", // Add padding to the bottom of the swiper
  },

  [`& .swiper-wrapper`]: {
    height: "auto !important", // Override any fixed height
  },
}));

import React from "react";
import { Box, Typography, Grid2, Card } from "@mui/material";
import { SectionContentProps } from "../../types";
import { Root, classes } from "./styles";
import PlaceholderImage from "@library/components/PlaceholderImage";

const testimonialKeys = ["julianne", "macon", "arthur", "debra"];
const baseLanguageLocation = "concierge.PackageDetails.TestimonialContent";

const getTestimonialImage = (key: string) => {
  try {
    return `/assets/testimonial${key.charAt(0).toUpperCase() + key.slice(1)}.jpeg`;
  } catch (error) {
    return "";
  }
};

export const TestimonialContent: React.FC<SectionContentProps> = ({
  theme,
}) => (
  <Root>
    <Box>
      <Typography className={classes.sectionContentDescription} sx={{ mb: 2 }}>
        {theme?.t(`${baseLanguageLocation}.description`)}
      </Typography>
      <Grid2 container spacing={3}>
        <Grid2 size={12}>
          {testimonialKeys.map((key) => (
            <Card key={key} className={classes.lightPurpleCard} sx={{ mb: 2 }}>
              <Grid2 container spacing={3}>
                <Grid2
                  size={{
                    xs: 12,
                    md: 3,
                  }}
                >
                  {getTestimonialImage(key) ? (
                    <img
                      src={getTestimonialImage(key)}
                      className={classes.testimonialImage}
                    />
                  ) : (
                    <PlaceholderImage imageHeight={200} />
                  )}
                </Grid2>
                <Grid2
                  className={classes.customerInformationContainer}
                  size={{
                    xs: 12,
                    md: 3,
                  }}
                >
                  <Typography
                    color="primary.light"
                    className={classes.boldText}
                  >
                    {theme?.t(
                      `${baseLanguageLocation}.items.${key}.customerName`
                    )}
                  </Typography>
                  <Typography color="primary.light">
                    {theme?.t(`${baseLanguageLocation}.items.${key}.location`)}
                  </Typography>
                  <Typography className={classes.boldText}>
                    {theme?.t(`${baseLanguageLocation}.whyElectric`)}
                  </Typography>
                  <Typography variant="body2">
                    {theme?.t(`${baseLanguageLocation}.items.${key}.why`)}
                  </Typography>
                  <Typography className={classes.boldText}>
                    {theme?.t(`${baseLanguageLocation}.projectScopeLabel`)}:
                  </Typography>
                  <Typography variant="body2">
                    {theme?.t(
                      `${baseLanguageLocation}.items.${key}.scopeLabel`
                    )}
                  </Typography>
                </Grid2>
                <Grid2
                  className={classes.testimonialContainer}
                  size={{
                    xs: 12,
                    md: 6,
                  }}
                >
                  <Typography sx={{ fontSize: 20 }} color="primary.light">
                    "
                    {theme?.t(
                      `${baseLanguageLocation}.items.${key}.testimonial`
                    )}
                    "
                  </Typography>
                </Grid2>
              </Grid2>
            </Card>
          ))}
        </Grid2>
      </Grid2>
    </Box>
  </Root>
);

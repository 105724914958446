import React from "react";
import { Box, Stack, useTheme } from "@mui/material";
import AdvisorTooltipContent from "../../AdvisorTooltipContent";

// const baseLanguageLocation = "step.MeetYourAdvisorStep"; // FYI: Bullets are not currently used per Evan's direct request

const MeetYourAdvisorStep = ({
  slideState,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slideState: any;
}) => {
  const theme = useTheme();

  return (
    <Stack spacing={1} sx={{ width: "100%" }} alignItems={"center"}>
      {/* Desktop view - bullets */}

      {/* FYI: Bullets are not currently used per Evan's direct request */}

      {/* <Box
        sx={{
          width: ["100%", "500px"],
          display: { xs: "none", md: "block" }, // Hide on mobile, show on desktop
        }}
      >
        <Typography component="li" sx={{ mb: 1 }}>
          {theme.t(`${baseLanguageLocation}.bullets.first.text`)}
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          {theme.t(`${baseLanguageLocation}.bullets.second.text`)}
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          {theme.t(`${baseLanguageLocation}.bullets.third.text`)}
        </Typography>
      </Box> */}

      {/* Mobile view - tooltip content */}
      <Box
        sx={{
          width: "100%",
          padding: 2,
          borderRadius: 1,
          mt: "16px !important",
          backgroundColor: theme.palette.common.white,
          display: { xs: "block", md: "none" }, // Show on mobile, hide on desktop
          border: `1px solid ${theme.palette.primary.light} !important`,
        }}
      >
        <AdvisorTooltipContent theme={theme} advisor={slideState?.advisor} />
      </Box>
    </Stack>
  );
};

export default MeetYourAdvisorStep;

import React from "react";
import pearl from "@library/theme/multitenancy/pearl/logo.png";
const hisense = "/assets/brandLogos/Hisense.png";
const mitsubishi = "/assets/brandLogos/Mitsubishi.png";
const amana = "/assets/brandLogos/Amana.png";
const bosch = "/assets/brandLogos/Bosch.png";
const bryant = "/assets/brandLogos/Bryant.png";
const carrier = "/assets/brandLogos/Carrier.png";
const coleman = "/assets/brandLogos/Coleman.png";
const daikin = "/assets/brandLogos/Daikin.png";
const fujitsu = "/assets/brandLogos/Fujitsu.png";
const ge = "/assets/brandLogos/GE.png";
const goodman = "/assets/brandLogos/Goodman.png";
const hitachi = "/assets/brandLogos/Hitachi.png";
const lennox = "/assets/brandLogos/Lennox.png";
const lg = "/assets/brandLogos/LG.png";
const midea = "/assets/brandLogos/Midea.png";
const rheem = "/assets/brandLogos/Rheem.png";
const samsung = "/assets/brandLogos/Samsung.png";
const toshiba = "/assets/brandLogos/Toshiba.png";
const trane = "/assets/brandLogos/Trane.png";
const york = "/assets/brandLogos/York.png";

const brands = {
  pearl,
  amana,
  hisense,
  mitsubishi,
  bosch,
  bryant,
  carrier,
  coleman,
  daikin,
  fujitsu,
  ge,
  goodman,
  hitachi,
  lennox,
  lg,
  midea,
  rheem,
  samsung,
  toshiba,
  trane,
  york,
};

export const BrandLogos = ({
  brand,
  styles = {},
  ...props
}: {
  brand: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles?: any;
}) => {
  return (
    <img src={brands[brand as keyof typeof brands]} style={styles} {...props} />
  );
};

export default BrandLogos;

import React from "react";
import { useTheme } from "@mui/material";

export const AttachedLivingSpace = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 218 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H210C214.418 0.5 218 4.08172 218 8.5V152.5C218 156.918 214.418 160.5 210 160.5H8C3.58173 160.5 0 156.918 0 152.5V8.5Z"
        style={{ fill: theme.palette.grey["900"] }}
      />
      <path
        d="M45 59L48.4743 37.5H129.526L133 59H45Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118 117.5H108V104.013C108 101.52 110.239 99.5 113 99.5C115.761 99.5 118 101.52 118 104.013V117.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70 117.5H60V104.013C60 101.52 62.2387 99.5 65.0001 99.5C67.7615 99.5 70 101.52 70 104.013V117.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118 84.5H108V71.013C108 68.5205 110.239 66.5 113 66.5C115.761 66.5 118 68.5205 118 71.013V84.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94 84.5H84V71.013C84 68.5205 86.2387 66.5 89.0001 66.5C91.7615 66.5 94 68.5205 94 71.013V84.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70 84.5H60V71.013C60 68.5205 62.2387 66.5 65.0001 66.5C67.7615 66.5 70 68.5205 70 71.013V84.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.211 61.2229L164.177 66.8419L165.015 71.4363L130.211 66.3782"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.23 99.1714V124.171"
        style={{ stroke: theme.palette.common.accent }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.23 71.1714V92.1714"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.677 83.4998L156.139 83.4998L156.139 75.5762L137.677 75.5762"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.908 83.4998L146.908 75.5762"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.115 124.594L134.115 102.406L164.115 102.406L164.115 124.594"
        style={{ stroke: theme.palette.common.accent }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.998 107.5L161.234 107.5"
        style={{ stroke: theme.palette.common.accent }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.998 113.5L161.234 113.5"
        style={{ stroke: theme.palette.common.accent }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.998 119.5L161.234 119.5"
        style={{ stroke: theme.palette.common.accent }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183 125.5H35"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.178 94.5479L173.016 99.1423L129.212 94.0842L129.212 88.9289L172.178 94.5479Z"
        style={{ stroke: theme.palette.common.accent }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129 59.5V125.5H49V59.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.627 123.171V106.873C82.627 103.353 85.48 100.5 88.9994 100.5C92.5188 100.5 95.3718 103.353 95.3718 106.873V123.171"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="86.0488" cy="114.5" r="1" fill="white" />
    </svg>
  );
};
export default AttachedLivingSpace;

import { styled } from "@mui/material";

const PREFIX = "ImageUploadWithPlaceholder";

export const classes = {
  image: `${PREFIX}-image`,
  imageContainer: `${PREFIX}-imageContainer`,
  placeholderBox: `${PREFIX}-placeholderBox`,
  marker: `${PREFIX}-marker`,
  reuploadMarker: `${PREFIX}-reuploadMarker`,
  disabled: `${PREFIX}-disabled`,
  inverted: `${PREFIX}-inverted`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.placeholderBox}`]: {
    height: 150,
    borderRadius: 5,
    border: `2px dashed ${theme.palette.primary.main}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
    overflow: "hidden", // This prevents content from bleeding outside
  },
  [`& .${classes.imageContainer}`]: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  [`& .${classes.image}`]: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  [`& .${classes.marker}`]: {
    top: 4,
    left: 4,
    width: 20,
    height: 20,
    color: "white",
    display: "flex",
    fontWeight: "bold",
    borderRadius: "50%",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.reuploadMarker}`]: {
    top: 30,
    left: 4,
    width: 20,
    height: 20,
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
    "& svg": {
      fontSize: 15,
    },
  },
  [`& .${classes.disabled}`]: {
    cursor: "default",
    pointerEvents: "none",
  },
  [`& .${classes.inverted}`]: {
    color: "white",
    border: `2px dashed ${theme.palette.common.white}`,
    [`& .${classes.reuploadMarker}`]: {
      top: 5,
      [`& svg`]: {
        color: `${theme.palette.primary.main} !important`,
        fill: `${theme.palette.primary.main} !important`,
      },
    },
    [`& .${classes.image}`]: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      objectPosition: "center",
    },
    [`& .${classes.placeholderBox}`]: {
      border: `2px dashed ${theme.palette.common.white}`,
    },
    [`& svg`]: {
      borderColor: theme.palette.common.white,
      fontColor: theme.palette.common.white,
      fill: `${theme.palette.common.white} !important`,
    },
  },
}));

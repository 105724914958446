import { Dialog, styled } from "@mui/material";

const PREFIX = "InformationDialog";

export const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    [`& .${classes.dialogContent}`]: {
      backgroundColor: theme.palette.common.white,
    },
    [`& .${classes.buttonContainer}`]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  };
});

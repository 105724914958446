import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Typography,
  useTheme,
  Card,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PublicIcon from "@mui/icons-material/Public";
// import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { formatCurrency } from "@library/common";
import SwiperCarousel from "@library/components/SwiperCarousel";
import {
  EquipmentScope,
  EstimateData,
  PolyvectorPayload,
} from "@library/domain/estimate";

import { classes, Root } from "./styles";
import EquipmentImage from "./components/EquipmentImage";
import uniq from "lodash/uniq";
import { formatNumber } from "../../../../../common/index";
import InformationModalTrigger from "@library/components/InformationalModalTrigger";
import ComingSoon from "@library/components/ConciergeV3/components/ComingSoon";
import moment from "moment";

const baseLanguageLocation = "step.LightEstimateOptionsStep";

interface LightEstimateOptionsStepProps {
  estimates: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  showDoubt?: boolean;
}

interface EstimateItem {
  tier: string;
  data: EstimateData & PolyvectorPayload;
  isSelected: boolean;
  doubt: number;
}

interface HeightData {
  fullHeight: number;
  collapsedHeight: number;
}

const LightEstimateOptionsStep: React.FC<LightEstimateOptionsStepProps> = ({
  estimates,
  payload,
  showDoubt = true,
}) => {
  // const [showRebates, setShowRebates] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxDescriptionHeight, setMaxDescriptionHeight] = useState<number>(0);

  const descriptionRefs = useRef<Array<HTMLDivElement | null>>([]);
  const theme = useTheme();
  const tiers = theme.config?.tiers?.available ?? ["base", "replace", "pearl"];

  const estimateArray = Object.entries(estimates)
    .map(([tier, estimate]) => ({
      tier,
      data: { ...estimate.data },
      doubt: estimate.doubt >= 0.0 ? estimate.doubt : 0.0,
      isSelected: false, // Always false since this view is non-selectable
    }))
    .sort((a, b) => tiers.indexOf(a.tier) - tiers.indexOf(b.tier));

  const updateDescriptionHeights = useCallback(() => {
    const heights: HeightData[] = descriptionRefs.current.map((ref) => {
      if (!ref)
        return {
          fullHeight: 0,
          collapsedHeight: 0,
        };

      // Temporarily expand to measure full height
      ref.classList.add("temp-expanded");
      const fullHeight = ref.scrollHeight;
      ref.classList.remove("temp-expanded");

      // Measure collapsed height
      const initialContentElement = ref.querySelector(
        ".initial-content"
      ) as HTMLElement;
      const collapsedHeight = initialContentElement?.scrollHeight || 0;

      return {
        fullHeight,
        collapsedHeight,
      };
    });

    // Set height based on expanded state
    const newHeight = isExpanded
      ? Math.max(...heights.map((h) => h.fullHeight))
      : Math.max(...heights.map((h) => h.collapsedHeight));

    setMaxDescriptionHeight(newHeight);
  }, [isExpanded]);

  // Update heights when expanded state changes
  useEffect(() => {
    updateDescriptionHeights();
  }, [isExpanded, updateDescriptionHeights]);

  // FYI: Now toggles all cards at once
  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderRebateSection = (_estimate: EstimateItem) => {
    return null; // intentionally not rendered
    // if (!estimate.data?.rebates?.length) return null;

    // return (
    //   <>
    //     <Collapse in={showRebates}>
    //       <Typography variant="subtitle2" className={classes.bottomBorder}>
    //         {theme.t(`${baseLanguageLocation}.instantRebates.title`)}
    //       </Typography>
    //       {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
    //       {estimate.data.rebates.map((rebate: any, index: number) => (
    //         <Box
    //           key={index}
    //           className={`${classes.costRow} ${classes.bottomBorder}`}
    //           sx={{ py: 1 }}
    //         >
    //           <Box sx={{ lineHeight: 1 }}>
    //             <Typography variant="body2" color="textPrimary">
    //               {rebate.name}
    //             </Typography>
    //             <Typography variant="caption" color="textSecondary">
    //               {rebate.description}
    //             </Typography>
    //           </Box>
    //           <Typography>{formatCurrency(rebate.total)}</Typography>
    //         </Box>
    //       ))}
    //     </Collapse>
    //   </>
    // );
  };

  const renderEstimateCard = (estimate: EstimateItem, index: number) => {
    const equipmentScope = (estimate.data.equipmentScope ||
      {}) as EquipmentScope;

    const templateVars = {
      tier: estimate.tier,
      ...payload,
      primaryHeatingEquipment: payload.heatingEquipmentType[0],
      primaryCoolingEquipment: payload.coolingEquipmentType[0],
      heatingCoolingProjectScope:
        payload.heatingCoolingProjectScope === "cooling"
          ? "cooling"
          : "heating",
      ducted:
        payload.heatingEquipmentType[0] === "furnace_with_ducts" ||
        payload.coolingEquipmentType[0] === "central_air_conditioning",
      primaryEnergyType: theme
        .t(`energyTypes.${equipmentScope.new?.primary_heating?.energy_type}`)
        .trim(),
      secondaryEnergyType: theme
        .t(`energyTypes.${equipmentScope.new?.secondary_heating?.energy_type}`)
        .trim(),
      coolingEnergyType: theme
        .t(`energyTypes.${equipmentScope.new?.cooling?.energy_type}`)
        .trim(),
    };

    const comingSoon = estimate?.data?.rebates?.find(
      (i) => moment(i.startDate) > moment()
    );

    return (
      <Card className={classes.card} sx={{ p: { xs: 1.5, sm: 2 } }}>
        {/* Title Section */}
        <Box className={classes.titleSection}>
          <Typography variant="h6">
            {theme
              .t(
                `${baseLanguageLocation}.lightOptionsCopy.equipmentTitle`,
                templateVars
              )
              .trim()}
          </Typography>
          <Typography variant="body2">
            {theme
              .t(
                `${baseLanguageLocation}.lightOptionsCopy.equipmentSubtitle`,
                templateVars
              )
              .trim()}
          </Typography>
        </Box>

        {/* Image Section */}
        <Box className={classes.imageContainer}>
          <EquipmentImage
            payload={payload}
            url={theme
              .t(
                `${baseLanguageLocation}.lightOptionsCopy.equipmentImageUrl`,
                templateVars
              )
              .trim()}
          />
        </Box>

        {/* Description Section */}
        <Box
          className={classes.descriptionSection}
          ref={(el: HTMLDivElement | null) => {
            if (descriptionRefs.current) {
              descriptionRefs.current[index] = el;
            }
          }}
          style={{
            height: maxDescriptionHeight || "auto",
            transition: "height 0.3s ease-in-out",
          }}
        >
          <Box className={classes.expandableContent}>
            <Box className="initial-content">
              <Typography>
                {" "}
                {theme
                  .t(
                    `${baseLanguageLocation}.lightOptionsCopy.description`,
                    templateVars
                  )
                  .trim()}
              </Typography>
              {theme
                .t(
                  `${baseLanguageLocation}.lightOptionsCopy.bullets`,
                  templateVars
                )
                .trim().length ? (
                <Typography
                  className={classes.readMore}
                  onClick={toggleReadMore}
                >
                  {isExpanded
                    ? theme.t(`${baseLanguageLocation}.readMore.less`)
                    : theme.t(`${baseLanguageLocation}.readMore.more`)}
                </Typography>
              ) : null}
            </Box>
            {isExpanded && (
              <List>
                {uniq(
                  theme
                    .t(
                      `${baseLanguageLocation}.lightOptionsCopy.bullets`,
                      templateVars
                    )
                    .trim()
                    .split("\n")
                ).map((bullet, bulletIndex) => (
                  <ListItem key={bulletIndex} sx={{ py: 0.5 }}>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={bullet.trim()}
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontSize: "0.875rem",
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Box>

        {/* Cost Section */}
        <Box className={classes.costSection}>
          <Box className={classes.costRow}>
            <PaymentsIcon color="action" />
            {/* <IconButton
              onClick={() => setShowRebates(!showRebates)}
              size="small"
            >
              {showRebates ? <RemoveIcon /> : <AddIcon />}
            </IconButton> */}
          </Box>
          <Box className={classes.costRow}>
            <Box sx={{ maxWidth: "50%" }}>
              <Typography variant="subtitle2" sx={{ mb: 0 }}>
                {theme.t(`${baseLanguageLocation}.installedCost.title`)}
              </Typography>
              <Typography variant="caption">
                {theme.t(`${baseLanguageLocation}.installedCost.subtitle`)}
              </Typography>
            </Box>
            <Typography className={classes.strikeThrough}>
              {showDoubt && estimate.doubt ? (
                <>
                  {formatCurrency(
                    estimate.data.installedCostTotal -
                      estimate.data.installedCostTotal * estimate.doubt,
                    {
                      roundTo: 100,
                    }
                  )}{" "}
                  -{" "}
                  {formatCurrency(
                    estimate.data.installedCostTotal +
                      estimate.data.installedCostTotal * estimate.doubt,
                    {
                      roundTo: 100,
                    }
                  )}
                </>
              ) : (
                <>
                  {formatCurrency(estimate.data.installedCostTotal, {
                    roundTo: 100,
                  })}{" "}
                </>
              )}
            </Typography>
          </Box>
          <Box className={classes.costRow}>
            <Box sx={{ maxWidth: "50%" }}>
              <Typography variant="subtitle2" color="primary">
                {theme.t(`${baseLanguageLocation}.yourPrice.title`)}
              </Typography>
              <Typography variant="caption">
                {theme.t(`${baseLanguageLocation}.yourPrice.subtitle`)}
              </Typography>
            </Box>
            <Typography color="primary.main">
              {showDoubt && estimate.doubt ? (
                <>
                  {estimate.data.upFrontCostTotal
                    ? `${formatCurrency(
                        estimate.data.upFrontCostTotal -
                          estimate.data.upFrontCostTotal * estimate.doubt,
                        { roundTo: 100 }
                      )}
                    -
                    ${formatCurrency(
                      estimate.data.upFrontCostTotal +
                        estimate.data.upFrontCostTotal * estimate.doubt,
                      { roundTo: 100 }
                    )}`
                    : "N/A"}
                  <InformationModalTrigger
                    description={theme.t(
                      `${baseLanguageLocation}.yourPrice.rangedPriceDescription`
                    )}
                    triggerSx={{ ml: 1 }}
                  />
                </>
              ) : (
                <>
                  {formatCurrency(estimate.data.upFrontCostTotal, {
                    roundTo: 100,
                  })}
                </>
              )}
            </Typography>
          </Box>
          <Box sx={{ my: 1 }}>
            {" "}
            {comingSoon ? <ComingSoon incentive={comingSoon} /> : null}
          </Box>
          <Box className={classes.costRow}>
            <Box sx={{ maxWidth: "50%" }}>
              <Typography variant="subtitle2" color="primary">
                {theme.t(
                  `${baseLanguageLocation}.potentialAdditionalIncentives.title`
                )}
              </Typography>
              <Typography
                variant="caption"
                sx={{ lineHeight: "1.0rem !important", display: "block" }}
              >
                {theme.t(
                  `${baseLanguageLocation}.potentialAdditionalIncentives.subtitle`
                )}
              </Typography>
            </Box>
            <Typography color="primary.main">
              {showDoubt && estimate.doubt ? (
                <>
                  {estimate.data.potentialAdditionalIncentives
                    ? formatCurrency(
                        estimate.data.potentialAdditionalIncentives +
                          estimate.data.potentialAdditionalIncentives *
                            estimate.doubt,
                        { roundTo: 100 } // Display upper bound only
                      )
                    : "N/A"}
                </>
              ) : (
                <>{formatCurrency(estimate.data.netPrice, { roundTo: 100 })}</>
              )}
            </Typography>
          </Box>
          <Box className={classes.costRow}>
            <Box sx={{ maxWidth: "50%" }}>
              <Typography variant="subtitle2" color="primary">
                {theme.t(`${baseLanguageLocation}.monthlyCost.title`)}
              </Typography>
              <Typography variant="caption">
                {theme.t(`${baseLanguageLocation}.monthlyCost.subtitle`)}
              </Typography>
            </Box>
            <Typography color="primary.main">
              {showDoubt && estimate.doubt ? (
                <>
                  {estimate.data.monthlyPayment
                    ? `${formatCurrency(
                        estimate.data.monthlyPayment -
                          estimate.data.monthlyPayment * estimate.doubt,
                        { roundTo: 10 }
                      )}
                -
                ${formatCurrency(
                  estimate.data.monthlyPayment +
                    estimate.data.monthlyPayment * estimate.doubt,
                  { roundTo: 10 }
                )}`
                    : "N/A"}
                </>
              ) : (
                <>
                  {formatCurrency(estimate.data.monthlyPayment, {
                    roundTo: 10,
                  })}
                </>
              )}{" "}
              {theme.t(`${baseLanguageLocation}.monthlyCost.perMonth`)}
            </Typography>
          </Box>
          {renderRebateSection(estimate)}
        </Box>

        {/* Bill Impact Section */}
        <Box className={classes.billImpactSection}>
          <ReceiptLongIcon color="action" />
          <Box className={classes.costRow}>
            <Box>
              <Typography variant="subtitle2">
                {theme.t(`${baseLanguageLocation}.annualBill.title`)}
              </Typography>
              <Typography className={classes.subtitle}>
                {theme.t(`${baseLanguageLocation}.annualBill.subtitle`)}
              </Typography>
            </Box>
            <Typography>
              {showDoubt && estimate.doubt ? (
                <>
                  {estimate.data.billImpactEstimate
                    ? `${formatCurrency(
                        estimate.data.billImpactEstimate -
                          estimate.data.billImpactEstimate * estimate.doubt,
                        { roundTo: 10, showNegative: false, arrow: true }
                      )}
                -
                ${formatCurrency(
                  estimate.data.billImpactEstimate +
                    estimate.data.billImpactEstimate * estimate.doubt,
                  { roundTo: 10, showNegative: false }
                )}`
                    : "N/A"}
                </>
              ) : (
                <>
                  {formatCurrency(estimate.data.billImpactEstimate, {
                    roundTo: 10,
                    showNegative: false,
                  })}
                </>
              )}
            </Typography>
          </Box>
        </Box>

        {/* Climate Impact Section */}
        <Box className={classes.climateImpactSection}>
          <PublicIcon color="action" />
          <Box className={classes.costRow}>
            <Box>
              <Typography variant="subtitle2">
                {theme.t(`${baseLanguageLocation}.climateImpact.title`)}
              </Typography>
              <Typography className={classes.subtitle}>
                {theme.t(`${baseLanguageLocation}.climateImpact.subtitle`)}
              </Typography>
            </Box>
            <Typography>
              {formatNumber(estimate.data.climateImpactEstimate, {
                toFixed: 1,
                arrow: true,
                showNegative: false,
              })}{" "}
              {theme.t(`${baseLanguageLocation}.climateImpact.unit`)}
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  };

  return (
    <Root>
      <Box
        sx={{
          width: "100vw", // Take up available viewport width
          mx: "auto",
          px: { xs: 1, sm: 2, md: 3 }, // Reduced padding on mobile
          paddingBottom: 8,
        }}
      >
        <SwiperCarousel
          selectable={false}
          items={estimateArray}
          subTitle=""
          renderItem={renderEstimateCard}
        />
      </Box>
    </Root>
  );
};

export default LightEstimateOptionsStep;

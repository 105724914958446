import {
  Alert,
  Box,
  Button,
  Grid2,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import KeyIcon from "@mui/icons-material/Key";

// import TextField from "@library/form/inputs/TextField";

import logo from "@library/theme/multitenancy/pearl/logo.png";
import bg from "@library/theme/multitenancy/home-background.jpg";
import { SettingsContext } from "@library/settings/provider";
import supabase from "@library/api/supabase";

import { Link, useNavigate } from "react-router-dom";

const ResetPasswordForm = () => {
  const { isAuthenticated } = useContext(SettingsContext);
  const [authError, setAuthError] = useState<string | undefined>();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleOnSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/update-password`,
    });
    setIsLoading(false);
    if (response?.error) {
      setAuthError(response?.error?.message ?? "An unknown error occured.");
    } else {
      setSubmitted(true);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const isError = Boolean(authError);

  return (
    <form onSubmit={handleOnSubmit}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          {authError && <Alert severity="error">{authError}</Alert>}
          {submitted && (
            <Alert severity="info">
              If the user exists, an email was sent to your inbox (or your spam
              folder) with your one time reset password link!
            </Alert>
          )}
          {!submitted && (
            <>
              <TextField
                name="email"
                label="Email"
                defaultValue={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                InputProps={{ error: isError }}
              />
              <Button
                type="submit"
                variant="contained"
                data-testid="submit"
                disabled={isLoading}
              >
                Submit
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </form>
  );
};

export const ResetPasswordPageView = () => {
  const theme = useTheme();
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      flexDirection="row"
      sx={{
        position: "absolute",
        top: 0,
        zIndex: 1100,
      }}
    >
      <Grid2
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        sx={{ width: "100%", minHeight: "100vh" }}
      >
        <Grid2
          sx={{
            display: ["none", "none", "initial"],
            minHeight: "100vh",
            backgroundColor: "green",
            backgroundImage: `url(${bg})`,
          }}
          size={{
            md: 6,
            sm: 0,
            xs: 0,
          }}
        ></Grid2>
        <Grid2
          alignItems="center"
          justifyContent="center"
          bgcolor="primary.main"
          sx={{ minHeight: "100vh" }}
          size={{
            md: 6,
            sm: 12,
            xs: 12,
          }}
        >
          <Stack justifyContent="center" alignItems="center" height="100vh">
            <Link to={window.location.origin}>
              <img
                alt="Pearl Logo"
                src={logo}
                style={{
                  width: "297px",
                }}
              />
            </Link>
            <Stack
              width={400}
              p={5}
              borderRadius={3}
              spacing={5}
              sx={{ backgroundColor: theme.palette.primary.contrastText }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <KeyIcon />
                <Typography variant="h5">Reset Password</Typography>
              </Stack>
              <ResetPasswordForm />
            </Stack>
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default ResetPasswordPageView;

import React, { useContext } from "react";
import {
  Typography,
  Box,
  Avatar,
  DialogTitle,
  Grid2,
  DialogContent,
  Button,
} from "@mui/material";
import { StyledDialog, classes } from "./styles";
import { SettingsContext } from "@library/settings/provider";
import { Advisor } from "../AdvisorProfile/types";
import { OnboardingPayload } from "@library/domain/onboarding";
import { PHONE_NUMBER } from "@library/common";

interface RepresentativeDialogProps {
  open: boolean;
  toggle: () => void;
  person?: Partial<Advisor>;
  payload?: OnboardingPayload;
}

const RepresentativeDialog: React.FC<RepresentativeDialogProps> = ({
  open,
  toggle,
  person,
  payload,
}) => {
  const { user } = useContext(SettingsContext);

  // Use person data if provided, otherwise fall back to user data
  const displayPerson = person || {
    firstName: `${user.firstName}`,
    lastName: `${user.lastName}`,
    email: user.email,
    phoneNumber: user.phoneNumber,
    phoneNumberTel: "",
    image: "",
  };

  const phoneNumber = displayPerson.phoneNumber;
  let phoneNumberTel = displayPerson.phoneNumberTel;
  if (phoneNumber) {
    phoneNumberTel = String(phoneNumber).replace(/\D/g, "");
  }

  return (
    <StyledDialog open={open} onClose={toggle}>
      <DialogTitle className={classes.repDialogTitle}>
        {person ? "Your Advisor" : ""}
      </DialogTitle>
      <DialogContent sx={{ mt: 3 }}>
        <Grid2 container className={classes.avatarAndInfoContainer}>
          <Grid2 size={3}>
            <Avatar
              alt="Profile picture"
              src={displayPerson.image || ""}
              sx={{ width: 50, height: 50 }}
            />
          </Grid2>
          <Grid2 size={9}>
            <Box className={classes.userInfoContainer}>
              <Typography variant="body1" sx={{ lineHeight: 1.2 }}>
                {displayPerson.firstName} {displayPerson.lastName}
              </Typography>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2
          container
          sx={{ mt: 2 }}
          className={classes.emailAndPhoneContainer}
          size={12}
        >
          <Grid2>
            <Typography className={classes.boldText} sx={{ pr: 3 }}>
              Email
            </Typography>
          </Grid2>
          <Grid2>
            <Button
              component="a"
              href={`mailto:${displayPerson.email}?subject=${encodeURIComponent(`Please contact me regarding the quote for ${payload?.user?.formatted_address ? payload.user.formatted_address : "my home"}`)}`}
              target="_blank"
              sx={{
                textTransform: "none",
                justifyContent: "flex-start",
                padding: "8px 0",
                width: "100%",
              }}
            >
              <Typography>{displayPerson.email}</Typography>
            </Button>
          </Grid2>
        </Grid2>
        <Grid2 container className={classes.emailAndPhoneContainer} size={12}>
          <Grid2>
            <Typography className={classes.boldText}>Phone</Typography>
          </Grid2>
          <Grid2>
            <Button
              component="a"
              href={`tel:+${phoneNumberTel}`}
              target="_blank"
              sx={{
                textTransform: "none",
                justifyContent: "flex-start",
                padding: "8px 0",
                width: "100%",
              }}
            >
              <Typography>
                {displayPerson.phoneNumber || PHONE_NUMBER}
              </Typography>
            </Button>
          </Grid2>
        </Grid2>
      </DialogContent>
    </StyledDialog>
  );
};

export default RepresentativeDialog;

import React, { useEffect, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PublicIcon from "@mui/icons-material/Public";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import CloseIcon from "@mui/icons-material/Close";

import { SlideComponent } from "@library/components/Sherpa/functions";
import { EquipmentScope, Estimate } from "@library/domain/estimate";

import {
  CALENDLY_URL_VIRTUAL_ASSESSMENT,
  formatCurrency,
  formatNumber,
} from "@library/common";
import Language from "@library/components/Language";
import InformationModalTrigger from "@library/components/InformationalModalTrigger";

export const Title = ({
  stepName,
  payload,
  slideState,
  color,
  slide,
}: SlideComponent) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const hasSidebar = slide?.Sidebar || false;
  return (
    <React.Fragment>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: !hasSidebar ? 5 : isMobile ? 10 : isTablet ? 0 : 15 }}
        className="Title"
      >
        {theme.t(`step.${stepName}.title`, { ...payload, ...slideState }) ? (
          theme
            .t(`step.${stepName}.title`, { ...payload, ...slideState })
            .split("\n")
            .map((text, i) => {
              return (
                <Typography
                  key={i}
                  gutterBottom={false}
                  textAlign="center"
                  role="heading"
                  style={{
                    fontWeight: "600",
                    marginTop: i > 0 ? 5 : theme.spacing(1, "auto"),
                  }}
                  sx={{
                    px: 2,
                    lineHeight: [1, 1, "48px"],
                    width: ["100%", "90vw"],
                    fontSize: ["1.5rem", "2rem", "2.5rem"],
                    display: "inline-block",
                    textAlign: "center",
                    color,
                  }}
                >
                  {text}{" "}
                  {theme.t(`step.${stepName}.whyDescription`) ? (
                    <InformationModalTrigger
                      title={
                        theme.t(`step.${stepName}.whyTitle`) ||
                        "Why are we asking?"
                      }
                      description={theme.t(`step.${stepName}.whyDescription`)}
                    />
                  ) : null}
                </Typography>
              );
            })
        ) : (
          <Typography
            gutterBottom={false}
            textAlign="center"
            role="heading"
            style={{
              fontWeight: theme.typography.fontWeightBold,
              marginTop: theme.spacing(1, "auto"),
            }}
            sx={{
              fontSize: ["1.5rem", "2rem", "2.5rem"],
              display: "inline-block",
              fontWeight: "bold",
              px: 2,
              textAlign: "center",
              color,
              width: ["100%", 600, 900],
            }}
          >
            {stepName}
          </Typography>
        )}
      </Stack>
    </React.Fragment>
  );
};

export const Description = ({ stepName }: SlideComponent) => {
  const theme = useTheme();
  if (!theme.t(`step.${stepName}.description`)) return null;
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Typography
        variant="h4"
        className="Description"
        sx={{
          display: "inline-block",
          fontWeight: "400",
          marginTop: 2,
          marginBottom: 1,
          width: ["100%", 600, 900],
          color: theme.palette.text.secondary,
          textAlign: ["center"],
          px: [2, 0],
          fontSize: ["0.8rem", "1.375rem !important"],
        }}
      >
        <Language
          languageKey={`step.${stepName}.description`}
          markdown={true}
        />
      </Typography>
    </Box>
  );
};

interface Estimates {
  [key: string]: Estimate;
}

const baseLanguageLocation = "step.SideBar";

const advisorTooltipLanguageLocation =
  "step.MeetYourAdvisorStep.sidebarTooltip";
const basementTooltipLanguageLocation = "step.BasementTypeStep.sidebarTooltip";
const equipmentTitleLanguageLocation =
  "step.LightEstimateOptionsStep.lightOptionsCopy.equipmentTitle";

export const Sidebar = ({ slideState, stepName, payload }: SlideComponent) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState<string>("");
  const [showTooltip, setShowTooltip] = useState(true);

  const isAdvisorStep = stepName === "MeetYourAdvisorStep";
  const isBasementStep = stepName === "BasementTypeStep";

  // Check if this is the first view
  useEffect(() => {
    const hasViewedSidebar = localStorage.getItem("sidebarViewed");

    if (isBasementStep) {
      setIsExpanded(false);
    } else if (!hasViewedSidebar && isDesktop) {
      setIsExpanded(true);
      localStorage.setItem("sidebarViewed", "true");
    }
  }, [isDesktop, isBasementStep]);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  // Get all estimates
  const estimates: Estimates = slideState?.estimates || {};
  const estimateArray = Object.entries(estimates);

  // Set initial selected estimate if not set
  useEffect(() => {
    if (!selectedEstimate && estimateArray.length > 0) {
      setSelectedEstimate(estimateArray[0][0]); // Set first estimate as default
    }
  }, [estimateArray, selectedEstimate]);

  // Get current estimate data
  const heatingEquipmentType = payload?.heatingEquipmentType?.[0];
  const coolingEquipmentType = payload?.coolingEquipmentType?.[0];
  const currentEstimate = estimates[selectedEstimate]?.data || {};
  const doubt = estimates[selectedEstimate]
    ? estimates[selectedEstimate].doubt
    : 0.0;
  const billImpact = currentEstimate.billImpactEstimate;
  const climateImpact = currentEstimate.climateImpactEstimate;

  const handleEstimateChange = (event: SelectChangeEvent<string>) => {
    setSelectedEstimate(event.target.value);
  };

  const tooltipContent = (
    <Box>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
            fontSize: "0.875rem",
            [theme.breakpoints.down("md")]: {
              fontSize: "0.8125rem",
            },
          }}
        >
          {isBasementStep
            ? theme.t(`${basementTooltipLanguageLocation}.title`)
            : theme.t(`${advisorTooltipLanguageLocation}.title`)}
        </Typography>
        <CloseIcon
          sx={{ cursor: "pointer", fontSize: 16 }}
          onClick={() => setShowTooltip(false)}
        />
      </Stack>
      <Typography
        variant="body2"
        sx={{
          fontSize: "0.875rem",
          [theme.breakpoints.down("md")]: {
            fontSize: "0.8125rem",
          },
        }}
      >
        {isBasementStep
          ? theme.t(`${basementTooltipLanguageLocation}.description`)
          : theme.t(`${advisorTooltipLanguageLocation}.description`)}
      </Typography>
    </Box>
  );

  const showTooltipCondition = () => {
    if ((!isAdvisorStep && !isBasementStep) || (isBasementStep && !isDesktop))
      return false;
    if (isDesktop) return true;
    return !isExpanded; // Only show on mobile if content isn't expanded
  };

  const tooltipProps = {
    open: showTooltip && showTooltipCondition(),
    placement: isDesktop
      ? ("bottom" as TooltipProps["placement"])
      : ("left" as TooltipProps["placement"]), // Explicitly type the placement (TS was angry)
    arrow: true,
    PopperProps: {
      sx: {
        "& .MuiTooltip-tooltip": {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          border: `1px solid ${theme.palette.primary.light} !important`,
          borderColor: "divider",
          borderRadius: 1,
          padding: [1, 2],
          mr: isDesktop && !isExpanded ? 2 : 0,
          maxWidth: !isDesktop ? 220 : "auto",
        },
        "& .MuiTooltip-arrow": {
          color: theme.palette.common.white,
          "&::before": {
            border: `1px solid ${theme.palette.primary.light}`,
          },
        },
      },
    },
    title: tooltipContent,
  };

  const SidebarContent = () => (
    <Stack
      sx={{
        backgroundColor: "rgb(239, 239, 239)",
        boxShadow: isExpanded
          ? "0px 0px 50px 10px rgba(239,239,239,0.5)"
          : "0px 0px 10px 20px rgba(239,239,239,1.0)",
      }}
    >
      {/* Header */}
      {!isExpanded && (
        <Box
          onClick={toggleExpanded}
          sx={{
            gap: 1,
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontSize: 14 }}>
            {theme.t(`${baseLanguageLocation}.collapsed.title`)}
          </Typography>
          <SettingsInputAntennaIcon
            sx={{ fontSize: 15, color: theme.palette.text.secondary }}
          />
        </Box>
      )}

      {/* Collapsed State */}
      {!isExpanded && (
        <Stack
          onClick={toggleExpanded}
          sx={{
            p: 1.5,
            cursor: "pointer",
            borderRadius: "8px",
            width: "100%",
            border: `1px solid ${theme.palette.divider}`,
            display: ["none", "none", "inherit"],
          }}
        >
          <Stack spacing={1}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <PaymentsIcon
                sx={{ color: theme.palette.text.secondary, fontSize: 15 }}
              />
              <Typography sx={{ fontSize: 14 }}>
                {theme.t(
                  `${baseLanguageLocation}.collapsed.estimatedCost.label`
                )}
                :{" "}
                {formatCurrency(
                  currentEstimate.upFrontCostTotal -
                    currentEstimate.upFrontCostTotal * doubt,
                  { roundTo: 100 }
                )}
                -{" "}
                {formatCurrency(
                  currentEstimate.upFrontCostTotal +
                    currentEstimate.upFrontCostTotal * doubt,
                  { roundTo: 100 }
                )}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <ReceiptLongIcon
                sx={{ color: theme.palette.text.secondary, fontSize: 15 }}
              />
              <Typography sx={{ fontSize: 14 }}>
                {theme.t(`${baseLanguageLocation}.collapsed.billImpact.label`)}:{" "}
                {formatCurrency(billImpact, {
                  round: true,
                  arrow: true,
                  precise: false,
                  showNegative: false,
                  roundTo: 100,
                })}
                {theme.t(
                  `${baseLanguageLocation}.collapsed.billImpact.perYear`
                )}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <PublicIcon
                sx={{ color: theme.palette.text.secondary, fontSize: 15 }}
              />
              <Typography sx={{ fontSize: 14 }}>
                {theme.t(
                  `${baseLanguageLocation}.collapsed.climateImpact.label`
                )}
                :{" "}
                {formatNumber(climateImpact, {
                  round: true,
                  arrow: true,
                  precise: false,
                  showNegative: false,
                  roundTo: 1,
                  toFixed: 0,
                })}{" "}
                {theme.t(
                  `${baseLanguageLocation}.collapsed.climateImpact.unit`
                )}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      )}

      {/* Expanded State */}
      {isExpanded && (
        <Stack
          onClick={(e) => e.stopPropagation()} // Prevent closing when interacting with select
          sx={{
            width: 300,
            backgroundColor: theme.palette.primary.contrastText,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          {/* Purple Header */}
          <Box
            sx={{
              bgcolor: theme.palette.primary.light,
              p: 1,
              gap: 1,
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={toggleExpanded} // Keep header clickable for closing
          >
            <Box sx={{ gap: 1, display: "flex", alignItems: "center" }}>
              <SettingsInputAntennaIcon
                sx={{ color: theme.palette.common.white }}
              />
              <Typography variant="subtitle1" sx={{ cursor: "pointer" }}>
                {theme.t(`${baseLanguageLocation}.expanded.title`)}
              </Typography>
            </Box>
            <CloseIcon
              sx={{
                color: theme.palette.primary.contrastText,
                cursor: "pointer",
              }}
            />
          </Box>

          {/* Content */}
          <Stack sx={{ p: 1 }}>
            {/* System Selection */}
            <Box sx={{ mt: 1 }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="when-using-select-label">
                  {theme.t(
                    `${baseLanguageLocation}.expanded.systemSelection.label`
                  )}
                </InputLabel>
                <Select
                  labelId="when-using-select-label"
                  label={theme.t(
                    `${baseLanguageLocation}.expanded.systemSelection.label`
                  )}
                  fullWidth
                  value={selectedEstimate}
                  onChange={handleEstimateChange}
                  size="small"
                >
                  {estimateArray.map(([key, estimate]) => {
                    const equipmentScope = (estimate.data.equipmentScope ||
                      {}) as EquipmentScope;

                    const templateVars = {
                      ...payload,
                      tier: key,
                      primaryHeatingEquipment: heatingEquipmentType,
                      primaryCoolingEquipment: coolingEquipmentType,
                      heatingCoolingProjectScope:
                        payload.heatingCoolingProjectScope === "cooling"
                          ? "cooling"
                          : "heating",
                      ducted:
                        payload.heatingEquipmentType[0] ===
                          "furnace_with_ducts" ||
                        payload.coolingEquipmentType[0] ===
                          "central_air_conditioning",
                      primaryEnergyType: theme
                        .t(
                          `energyTypes.${equipmentScope.new?.primary_heating?.energy_type}`
                        )
                        .trim(),
                      secondaryEnergyType: theme
                        .t(
                          `energyTypes.${equipmentScope.new?.secondary_heating?.energy_type}`
                        )
                        .trim(),
                      coolingEnergyType: theme
                        .t(
                          `energyTypes.${equipmentScope.new?.cooling?.energy_type}`
                        )
                        .trim(),
                    };
                    return (
                      <MenuItem key={key} value={key}>
                        {theme
                          .t(`${equipmentTitleLanguageLocation}`, templateVars)
                          .trim()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            {/* Estimated Cost */}
            <Box
              sx={{
                mt: 1,
                bgcolor: `${theme.palette.primary.main}15`,
                p: 1,
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                  }}
                >
                  {theme.t(
                    `${baseLanguageLocation}.expanded.estimatedCost.title`
                  )}
                </Typography>
                {/* <IconButton size="small">
                  <AddIcon />
                </IconButton> */}
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <PaymentsIcon
                  sx={{ fontSize: 25, color: theme.palette.text.secondary }}
                />
                <Box sx={{ px: 1 }}>
                  <Typography sx={{ mt: 0.5, fontSize: 14 }}>
                    {formatCurrency(
                      currentEstimate.upFrontCostTotal -
                        currentEstimate.upFrontCostTotal * doubt,
                      { roundTo: 100 }
                    )}
                    -{" "}
                    {formatCurrency(
                      currentEstimate.upFrontCostTotal +
                        currentEstimate.upFrontCostTotal * doubt,
                      { roundTo: 100 }
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      lineHeight: 1.2,
                      display: "block",
                    }}
                    variant="caption"
                    color="text.secondary"
                  >
                    {theme.t(
                      `${baseLanguageLocation}.expanded.estimatedCost.subtitle`
                    )}
                  </Typography>
                </Box>
              </Stack>
            </Box>

            {/* Annual Bill Impact */}
            <Box
              sx={{
                mt: 1,
                bgcolor: `${theme.palette.primary.main}15`,
                p: 1,
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                  }}
                >
                  {theme.t(`${baseLanguageLocation}.expanded.billImpact.title`)}
                </Typography>
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <ReceiptLongIcon
                  sx={{ fontSize: 25, color: theme.palette.text.secondary }}
                />
                <Box sx={{ px: 1 }}>
                  <Typography sx={{ mt: 0.5, fontSize: 14 }}>
                    {formatCurrency(billImpact, {
                      round: true,
                      arrow: true,
                      precise: false,
                      showNegative: false,
                      roundTo: 100,
                    })}{" "}
                    {theme.t(
                      `${baseLanguageLocation}.expanded.billImpact.perYear`
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      lineHeight: 1.2,
                      display: "block",
                    }}
                    variant="caption"
                    color="text.secondary"
                  >
                    {theme.t(
                      `${baseLanguageLocation}.expanded.billImpact.subtitle`
                    )}
                  </Typography>
                </Box>
              </Stack>
            </Box>

            {/* Climate Impact */}
            <Box
              sx={{
                mt: 1,
                bgcolor: `${theme.palette.primary.main}15`,
                p: 1,
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                  }}
                >
                  {theme.t(
                    `${baseLanguageLocation}.expanded.climateImpact.title`
                  )}
                </Typography>
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <PublicIcon
                  sx={{ fontSize: 25, color: theme.palette.text.secondary }}
                />
                <Box sx={{ px: 1 }}>
                  <Typography sx={{ mt: 1, fontSize: 14 }}>
                    {formatNumber(climateImpact, {
                      round: true,
                      arrow: true,
                      precise: false,
                      showNegative: false,
                      roundTo: 1,
                      toFixed: 0,
                    })}{" "}
                    {theme.t(
                      `${baseLanguageLocation}.expanded.climateImpact.unit`
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      lineHeight: 1.2,
                      display: "block",
                    }}
                    variant="caption"
                    color="text.secondary"
                  >
                    {theme.t(
                      `${baseLanguageLocation}.expanded.climateImpact.subtitle`
                    )}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      )}
    </Stack>
  );

  if (!isDesktop) {
    return (
      <Tooltip {...tooltipProps}>
        <Box sx={{ position: "relative" }}>
          {/* Purple Circle Button */}
          {!isExpanded && (
            <Box
              onClick={toggleExpanded}
              sx={{
                width: 48,
                height: 48,
                borderRadius: "50%",
                backgroundColor: theme.palette.primary.main,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <SettingsInputAntennaIcon sx={{ color: "white", fontSize: 24 }} />
            </Box>
          )}

          {/* Expanded Content */}
          {isExpanded && (
            <Box sx={{ position: "relative", zIndex: 1501 }}>
              <SidebarContent />
            </Box>
          )}
        </Box>
      </Tooltip>
    );
  }

  // Desktop version with tooltip
  return (
    <Tooltip {...tooltipProps}>
      <Box>
        <SidebarContent />
      </Box>
    </Tooltip>
  );
};

export const CalendlyVirtualConsultation = ({
  payload,
  onChange,
  url = CALENDLY_URL_VIRTUAL_ASSESSMENT,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  onChange: (_slideIndex: number, _slideKey: string) => void;
  url?: string;
}) => {
  const theme = useTheme();
  useCalendlyEventListener({
    onEventScheduled: () => {
      onChange(-1, "BeInTouchStep");
    },
  });
  return (
    <Stack sx={{ width: "100%" }}>
      <InlineWidget
        url={url}
        prefill={{
          email: payload.user?.email,
          firstName: payload.user?.firstName,
          lastName: payload.user?.lastName,
          name: `${payload.user?.firstName} ${payload.user?.lastName}`,
        }}
        pageSettings={{
          backgroundColor: "ffffff",
          hideEventTypeDetails: true,
          hideLandingPageDetails: true,
          primaryColor: theme.palette.primary.main,
          textColor: theme.palette.common.black,
        }}
      />
    </Stack>
  );
};

/* eslint-disable prefer-rest-params */
import { responsiveFontSizes, Theme } from "@mui/material";
import get from "lodash/get";
import partial from "lodash/partial";
import isString from "lodash/isString";
import Handlebars from "handlebars";

import type { TenantName } from "./multitenancy";
import { AvailableTenants } from "./multitenancy";
import { Channel } from "@library/domain/channel";
import { PHONE_NUMBER } from "@library/common";

Handlebars.registerHelper({
  eq: (v1, v2) => v1 === v2,
  ne: (v1, v2) => v1 !== v2,
  lt: (v1, v2) => v1 < v2,
  gt: (v1, v2) => v1 > v2,
  lte: (v1, v2) => v1 <= v2,
  gte: (v1, v2) => v1 >= v2,
  and() {
    return Array.prototype.every.call(arguments, Boolean);
  },
  or() {
    return Array.prototype.slice.call(arguments, 0, -1).some(Boolean);
  },
});

export const replaceTokens = (
  template: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  options?: { handlebars: boolean }
) => {
  if (!isString(template)) return template;
  let handlebars = false;
  if (options?.handlebars === false) {
    handlebars = false;
  } else if (options?.handlebars === true) {
    handlebars = true;
  } else {
    const regex = /{{/;
    if (regex.test(template)) {
      handlebars = true;
    }
  }
  if (handlebars) {
    try {
      const _template = Handlebars.compile(template);
      return _template(values);
    } catch (e) {
      console.error(e);
      console.error(
        "Unable to process handlebars template, falling back to default templating engine."
      );
    }
  }
  return template.replace(/\{([^}]+)\}/g, (match, token) => {
    const value = get(values, token) ?? match;
    return value;
  });
};

function getText(
  theme: Theme,
  language: string,
  path: string,
  values?: Record<string, string | number | boolean>,
  options?: { handlebars: boolean }
) {
  const CONSTANTS = {
    PHONE_NUMBER,
  };
  const config =
    theme.config.language[language as keyof typeof theme.config.language];
  let text = get(config, path, "");
  text = replaceTokens(text, { constants: CONSTANTS, ...values }, options);
  return text;
}

export const getTheme = (
  tenant: TenantName = "pearl",
  channel: Channel,
  language = "en-us"
) => {
  let tenantConfig = AvailableTenants.pearl(channel);
  let config = responsiveFontSizes(tenantConfig.theme);
  try {
    tenantConfig =
      AvailableTenants[tenant](channel) ?? AvailableTenants.pearl(channel);
  } catch (e) {
    // do nothing
  }
  try {
    config = responsiveFontSizes(tenantConfig.theme);
    if (channel?.logoUrl) {
      config.logo.imageSrc = channel.logoUrl;
    } else if (channel?.LogoImage?.url) {
      config.logo.imageSrc = channel.LogoImage.url;
    }
  } catch (e) {
    // do nothing
  }

  config.t = partial(getText, config, language);
  return config;
};

import React from "react";
import { Box, SxProps, Theme } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { Root, classes } from "./styles";

interface PlaceholderImageProps {
  imageHeight?: number;
  iconSize?: number;
  sx?: SxProps<Theme>;
  top?: string;
  position?: string;
  backgroundColor?: string;
}

const PlaceholderImage: React.FC<PlaceholderImageProps> = ({
  sx,
  iconSize,
  imageHeight,
  top = "-40px",
  position = "relative",
  backgroundColor = "initial",
}) => {
  return (
    <Root sx={{ height: imageHeight || 300, width: "100%", ...sx }}>
      <Box
        className={classes.container}
        sx={{
          height: "100%",
          width: "100%",
          top,
          position,
          backgroundColor,
        }}
      >
        <ImageIcon sx={{ fontSize: iconSize || 40 }} className={classes.icon} />
      </Box>
    </Root>
  );
};

export default PlaceholderImage;

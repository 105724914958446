import { useEffect } from "react";
import { useTheme } from "@mui/material";

import { shortenFullName } from "../common";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
  }
}

export const useIntercom = (
  user: {
    id?: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  } | null,
  isLoading?: boolean
) => {
  const theme = useTheme();

  useEffect(() => {
    if (
      import.meta.env.VITE_ENV === "prod" ||
      import.meta.env.VITE_INTERCOM === "true"
    ) {
      if (isLoading === true) return;
      if (!window.Intercom) return;
      if (!user?.id || user.id === "_") {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: import.meta.env.VITE_INTERCOM_APP_ID,
          action_color: theme.palette.secondary.main,
          background_color: theme.palette.primary.main,
          name: "",
        });
      } else {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: import.meta.env.VITE_INTERCOM_APP_ID,
          user_id: user.id,
          name: `${shortenFullName(user.firstName, user.lastName)}`,
          email: user.email,
          action_color: theme.palette.secondary.main,
          background_color: theme.palette.primary.main,
        });
      }
    }
  }, [user, isLoading, theme]);
};

import React, { createContext, useCallback, useEffect, useState } from "react";

import { User } from "@library/domain/user";
import api from "@library/api";
import supabase from "@library/api/supabase";
import { isAvailableTenant, TenantName } from "@library/theme/multitenancy";
import { Channel } from "@library/domain/channel";
import {
  createGTMBodyScript,
  createGTMHeadScript,
} from "@library/scripts/googleTagManager";

interface SettingsContextValue {
  error: string;
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  user: User;
  setUser: (value: User) => void;
  signOut: () => void;
  channelId: string;
  channelTheme: string;
  channelData: Channel;
  setChannel: (value: string) => void;
  language: string;
  setLanguage: (value: string) => void;
}

export const SettingsContext = createContext<SettingsContextValue>({
  error: "",
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  isLoading: true,
  setIsLoading: () => {},
  user: {
    role: "anonymous",
    isSuperAdmin: false,
  },
  setUser: () => {},
  signOut: () => {},
  channelId: "pearl",
  channelTheme: "pearl",
  channelData: {} as Channel,
  setChannel: () => {},
  language: "en-us",
  setLanguage: () => {},
});

interface Props {
  children: React.ReactNode;
}

const links = [];
const formatFont = (font: string) => {
  return font.replace(/ /g, "+");
};

const DEFAULT_LANGUAGE = "en-us";

// Unique ID for a given channel
const DEFAULT_CHANNEL_ID = "pearl";

// Theme for a given channel (not all channels have a theme)
const DEFAULT_CHANNEL_THEME = "pearl" as TenantName;

const getChannelTheme = (channel: string | TenantName): TenantName => {
  if (isAvailableTenant(channel)) {
    return channel as TenantName;
  }
  return DEFAULT_CHANNEL_THEME;
};

const params = new URL(window?.document?.location?.toString()).searchParams;
const channel = params.get("channel");
if (channel) {
  window.localStorage.setItem("channel", channel);
}

if (window.location.host.includes("dte")) {
  window.localStorage.setItem("channel", "dte");
}

const languageParam = params.get("language");
window.localStorage.setItem(
  "language",
  languageParam ?? localStorage.getItem("language") ?? DEFAULT_LANGUAGE
);

export const SettingsProvider: React.FC<Props> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_token, setToken] = useState(
    localStorage.getItem(
      `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`
    )
  );
  const [channelId, setChannelId] = useState(
    localStorage.getItem("channel") ?? DEFAULT_CHANNEL_ID
  );
  const [channelTheme, setChannelTheme] = useState<TenantName>(
    getChannelTheme(localStorage.getItem("channel") ?? DEFAULT_CHANNEL_ID)
  );
  const [channelData, setChannelData] = useState<Channel>({} as Channel);
  const [language, setLanguage] = useState<string>(
    localStorage.getItem("language") || DEFAULT_LANGUAGE
  );
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [user, setUser] = useState<User>({
    role: "anonymous",
    firstName: "Guest",
    lastName: "User",
  });

  useEffect(() => {}, [channelId]);

  const setChannel = (channel: string | TenantName) => {
    setChannelId(channel);
    setChannelTheme(getChannelTheme(channel));
  };

  useEffect(() => {
    if (links?.length) return;
    if (channelData?.config?.fonts) {
      channelData.config.fonts.forEach((font) => {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = `https://fonts.googleapis.com/css2?family=${formatFont(font)}&display=swap`;
        document.getElementsByTagName("head")[0].appendChild(link);
        links.push(link);
      });
    }

    if (channelData?.config?.googleTag) {
      const gtmId = channelData.config.googleTag;
      // Sanitize ID (should be in format GTM-XXXXXXX)
      const sanitizedGtmId = gtmId.replace(/[^a-zA-Z0-9-]/g, "");

      // Inject the script
      const script = document.createElement("script");
      script.innerHTML = createGTMHeadScript(sanitizedGtmId);
      document.head.appendChild(script);

      // Add noscript element to body
      const noscript = document.createElement("noscript");
      noscript.innerHTML = createGTMBodyScript(sanitizedGtmId);
      document.body.insertBefore(noscript, document.body.firstChild);
    }
  }, [channelData]);

  const fetchSettings = useCallback(() => {
    setIsLoading(true);
    return api
      .get("auth/settings", { channelId })
      .then((response) => {
        const { data = {} } = response;
        const { id } = data.user ?? {};
        if (id && id.length > 1) {
          setIsAuthenticated(true);
        }
        setUser({
          ...(data.user ?? {}),
        });
        // This channel doesn't get cobranding except or in specifc spots.
        // Move their channel specific data to a new key to be used in specific locations.
        if (data?.channel?.config?.cobranding === false) {
          data.channel.config.LogoImage = data.channel.LogoImage || {};
          data.channel.LogoImage = {};
          data.channel.config.overrides = data.channel.overrides || {};
          data.channel.overrides = {};
        }
        setChannelData({
          ...(data.channel ?? {}),
        });
        if (data?.channel?.id) {
          setChannelTheme(data.channel.id);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 10);
      })
      .catch(() => {
        setError("An unknown error occured, please try again.");
        setIsLoading(false);
      });
  }, [channelId]);

  useEffect(() => {
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    supabase.auth.onAuthStateChange((event) => {
      if (window.location.href.includes("/login")) {
        if (isAuthenticated) return;
        if (event === "SIGNED_IN") {
          setIsAuthenticated(true);
          setIsLoading(true);
          fetchSettings();
        }
      } else {
        if (isAuthenticated) return;
        if (event === "TOKEN_REFRESHED") {
          setToken(
            localStorage.getItem(
              `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`
            )
          );
          fetchSettings();
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOut = async () => {
    document.cookie = `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token-code-verifier=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    localStorage.removeItem("onboarding");
    localStorage.removeItem("onboardingId");
    const { error } = await supabase.auth.signOut();
    if (error) {
      setError(error?.message);
    } else {
      setError("");
      window.location.href = `${window.location.origin}`;
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        error,
        isLoading,
        isAuthenticated,
        setIsLoading,
        setIsAuthenticated,
        user,
        setUser,
        signOut,
        channelId,
        channelTheme,
        channelData,
        setChannel,
        language,
        setLanguage,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;

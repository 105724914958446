import { useTheme } from "@mui/material";
import React from "react";

export const Heating = ({ styles }: { styles?: object }) => {
  const theme = useTheme();
  return (
    <svg
      width="94"
      height="134"
      viewBox="-1 -6 128 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <g transform="translate(0,50) scale(0.75 0.75)">
        <path
          d="M20.6973 37.8037L31.8607 34.6396H92.9053V133.274H31.9568L20.6974 124.894L20.6973 37.8037Z"
          style={{ stroke: theme.palette.primary.contrastText }}
        />
        <path
          d="M39.5254 42.302H48.6241"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M39.5254 45.3835H48.6241"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M39.5254 48.4653H48.6241"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M39.5254 51.5466H48.6241"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M39.5254 54.6282H48.6241"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M51.8525 42.302H60.9513"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M51.8525 45.3835H60.9513"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M51.8525 48.4653H60.9513"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M51.8525 51.5466H60.9513"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M51.8525 54.6284H60.9513"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M64.1777 42.3018H73.2764"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M64.1777 45.3833H73.2764"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M64.1777 48.4651H73.2764"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M64.1777 51.5466H73.2764"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M64.1777 54.6284H73.2764"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M76.5039 42.3018H85.6026"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M76.5039 45.3833H85.6026"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M76.5039 48.4651H85.6026"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M76.5039 51.5466H85.6026"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M76.5039 54.6284H85.6026"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M27.2334 -4.6712V35.5098M32.2362 -5.45825V17.4743M32.2362 17.4743L40.2617 27.5057M32.2362 17.4743H76.6111M40.2617 27.5057V34.5278M40.2617 27.5057L84.6366 27.5057M76.6111 -5.45825V17.4743M76.6111 17.4743L84.6366 27.5057M84.6366 27.5057V34.5278"
          style={{ stroke: theme.palette.primary.contrastText }}
        />
        <path d="M21.3809 89.4253V9.48438L0.59375 15.5618V95.7778C0.59375 121.247 19.6513 119.631 27.4985 119.818V95.7778C22.6656 95.7778 21.4064 91.5428 21.3809 89.4253Z" />
        <path
          d="M21.3809 -4.8457V89.4254C21.4064 91.5429 22.6656 95.7779 27.4985 95.7779V119.818C14.6894 119.818 0.59375 114.498 0.59375 95.7779V-2.76824"
          style={{ stroke: theme.palette.primary.contrastText }}
        />
      </g>

      {/* Cooling */}
      <g transform="translate(30,-30) scale(0.75 0.75)">
        <path
          d="M122.938 7.75955V40.084C122.938 43.6088 120.405 46.468 117.281 46.468H6.71807C3.59494 46.468 1.06152 43.6088 1.06152 40.084V7.75955C1.06152 4.23475 3.59494 1.37549 6.71807 1.37549H117.281C120.405 1.37549 122.938 4.23475 122.938 7.75955Z"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M113.101 36.4961H10.8984V39.343C10.8984 43.278 12.0981 46.468 13.578 46.468H110.422C111.901 46.468 113.101 43.278 113.101 39.343V36.4961Z"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M108.603 15.3726H113.377"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M108.603 25.5654H113.377"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M108.603 20.469H113.377"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6221 15.3726H15.3963"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6221 25.5657H15.3963"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6221 20.4697H15.3963"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69.6681 17.1309H54.3311V23.6943H69.6681V17.1309Z"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.5268 82.6243C38.1413 80.7776 39.5603 77.7874 38.7782 73.2137C38.6587 72.5149 38.4923 71.8256 38.2816 71.1495C37.8885 69.8881 37.3081 68.7052 36.6391 67.589C36.2231 66.8949 35.7793 66.227 35.2556 65.5151C34.3538 64.3928 33.3201 62.6371 33.1569 60.3602C32.9938 58.0834 33.7011 55.2853 36.2817 52.0781"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9243 82.6072C51.5387 80.7605 52.9577 77.7703 52.1756 73.1966C52.0561 72.4977 51.8898 71.8085 51.6791 71.1324C51.2859 69.871 50.7056 68.6881 50.0365 67.5719C49.6205 66.8778 49.1768 66.2099 48.653 65.498C47.7513 64.3757 46.7176 62.62 46.5544 60.3431C46.3912 58.0663 47.0986 55.2682 49.6792 52.061"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.3217 82.5903C64.9362 80.7436 66.3552 77.7534 65.5731 73.1797C65.4536 72.4809 65.2872 71.7917 65.0765 71.1156C64.6833 69.8541 64.103 68.6713 63.434 67.555C63.018 66.861 62.5743 66.1931 62.0505 65.4812C61.1488 64.3588 60.115 62.6031 59.9519 60.3263C59.7887 58.0494 60.4961 55.2514 63.0766 52.0442"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M76.7182 82.5731C78.3327 80.7263 79.7517 77.7361 78.9696 73.1624C78.8501 72.4636 78.6837 71.7744 78.473 71.0983C78.0799 69.8368 77.4995 68.6539 76.8305 67.5377C76.4145 66.8437 75.9707 66.1757 75.447 65.4639C74.5453 64.3415 73.5115 62.5858 73.3483 60.309C73.1852 58.0321 73.8926 55.234 76.4731 52.0269"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M88.3911 82.5731C90.0056 80.7263 91.4246 77.7361 90.6425 73.1624C90.523 72.4636 90.3566 71.7744 90.1459 71.0983C89.7527 69.8368 89.1724 68.6539 88.5033 67.5377C88.0873 66.8437 87.6436 66.1757 87.1198 65.4639C86.2181 64.3415 85.1844 62.5858 85.0212 60.309C84.858 58.0321 85.5654 55.234 88.146 52.0269"
          style={{ stroke: theme.palette.primary.contrastText }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default Heating;

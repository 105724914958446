import { styled } from "@mui/material";

const PREFIX = "LoginPageView";

export const classes = {
  formContainer: `${PREFIX}-formContainer`,
  loginButton: `${PREFIX}-loginButton`,
  forgotPasswordLink: `${PREFIX}-forgotPasswordLink`,
  forgotPasswordButton: `${PREFIX}-forgotPasswordButton`,
  loginAnotherWayButton: `${PREFIX}-loginAnotherWayButton`,
  signUpButton: `${PREFIX}-signUpButton`,
  backButton: `${PREFIX}-backButton`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.formContainer}`]: {
    width: 400,
    borderRadius: 24,
    padding: theme.spacing(5),
    backgroundColor: theme.palette.primary.contrastText,
  },
  [`& .${classes.loginButton}`]: {
    height: 50,
    borderRadius: 25,
  },
  [`& .${classes.forgotPasswordLink}`]: {
    margin: "5px auto",
    marginTop: 16,
  },
  [`& .${classes.forgotPasswordButton}`]: {
    textTransform: "uppercase",
  },
  [`& .${classes.loginAnotherWayButton}`]: {
    fontWeight: 400,
    fontSize: "1rem",
    margin: `${theme.spacing(2)} 0`,
    color: theme.palette.primary.contrastText,
  },
  [`& .${classes.signUpButton}`]: {
    color: theme.palette.primary.contrastText,
  },
  [`& .${classes.backButton}`]: {
    color: theme.palette.text.primary,
  },
}));

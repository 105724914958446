import { useTheme } from "@mui/material";
import React from "react";

export const ResidentialSolar = ({ styles }: { styles?: object }) => {
  const theme = useTheme();
  return (
    <svg
      width="139"
      height="140"
      viewBox="0 0 139 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M55.8181 138.986H1.13281V91.8657L24.6928 51.2297L55.8181 91.8657V138.986Z"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M17.7754 138.986V105.267H35.0674V138.986"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <mask id="path-3-inside-1_18929_25139" fill="white">
        <rect
          x="78.5889"
          y="104.201"
          width="35.515"
          height="18.7755"
          rx="0.893948"
        />
      </mask>
      <rect
        x="78.5889"
        y="104.201"
        width="35.515"
        height="18.7755"
        rx="0.893948"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeWidth="2"
        mask="url(#path-3-inside-1_18929_25139)"
      />
      <path
        d="M55.8633 138.975H137.119V91.5408H55.8633"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M24.7041 50.8428H106.625L137.258 91.5409"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M52.5264 50.8428L83.5408 91.5409M80.051 50.8428L110.645 91.5409"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M40.1816 71.0513L122.103 71.0513"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M108.988 43.5843C116.733 43.8713 123.246 37.7905 123.534 30.0026C123.823 22.2147 117.778 15.6691 110.033 15.3821C102.288 15.0952 95.7749 21.1755 95.4863 28.9634C95.1978 36.7513 101.243 43.2973 108.988 43.5843Z"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.555 1.28003L110.335 7.19968"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.3135 4.53613L99.0834 9.77238"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.6084 14.4773L90.6253 17.6271"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.3076 28.4387L87.2273 28.658"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.5642 42.68L89.8004 39.9102"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.709 54.4301L119.938 49.1938"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.415 44.4899L128.397 41.3401"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.713 30.5284L131.793 30.3091"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.458 16.2871L129.221 19.057"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.519 5.58159L121.369 10.5985"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ResidentialSolar;

// import jake from "@library/assets/jake.png";
import {
  CALENDLY_URL_EMERGENCY_SUPPORT,
  CALENDLY_URL_ENERGY_AUDIT,
  CALENDLY_URL_HOME_ASSESSMENT,
  CALENDLY_URL_VIRTUAL_ASSESSMENT,
  CALENDLY_URL_WEATHERIZATION_CONSULTATION,
  PHONE_NUMBER,
  PHONE_NUMBER_TEL,
} from "@library/common";

export interface Advisor {
  id?: string;
  firstName: string;
  lastName: string;
  image: string;
  phoneNumber: string;
  phoneNumberTel: string;
  email: string;
  data?: {
    bio?: string;
    calendars?: {
      homeAssessment?: { url?: string };
      virtualAssessment?: { url?: string };
      energyAudit?: { url?: string };
      weatherization?: { url?: string };
      emergencySupport?: { url?: string };
    };
  };
}

export type BorderStyle = "full" | "right" | "none";

export const defaultAdvisor: Advisor = {
  firstName: "Jake",
  lastName: "Yurek",
  image: "",
  phoneNumber: PHONE_NUMBER,
  phoneNumberTel: PHONE_NUMBER_TEL,
  email: "jake@pearledison.com",
  data: {
    bio: "Jake is a Michigan-based advisor for Pearl Edison. He is certified as a Building Analyst by the Building Performance Institute, as both a Building Analyst Technician (BA-T) and Building Analyst Professional (BA-P). He specializes in home energy auditing, HVAC system design, and duct design with a broader background in renewable energy.",
    calendars: {
      homeAssessment: {
        url: CALENDLY_URL_HOME_ASSESSMENT,
      },
      virtualAssessment: {
        url: CALENDLY_URL_VIRTUAL_ASSESSMENT,
      },
      energyAudit: {
        url: CALENDLY_URL_ENERGY_AUDIT,
      },
      weatherization: {
        url: CALENDLY_URL_WEATHERIZATION_CONSULTATION,
      },
      emergencySupport: {
        url: CALENDLY_URL_EMERGENCY_SUPPORT,
      },
    },
  },
};

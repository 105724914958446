import { styled, Dialog } from "@mui/material";

const PREFIX = "VideoDialog";

export const classes = {
  dialogHeader: `${PREFIX}-dialogHeader`,
  closeButton: `${PREFIX}-closeButton`,
  dialogContent: `${PREFIX}-dialogContent`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogHeader}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  [`& .${classes.closeButton}`]: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.common.white,
  },

  [`& .${classes.dialogContent}`]: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[900],
  },
}));

import React from "react";
import { useTheme } from "@mui/material";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { replaceTokens } from "@library/theme/createDefaultTheme";

export const Language = ({
  languageKey,
  languageString,
  defaultValue = "",
  values = {},
  markdown = false,
}: {
  languageKey?: string;
  languageString?: string;
  defaultValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any;
  markdown?: boolean;
}) => {
  const theme = useTheme();
  let sanitizedHtml;
  let str;
  if (languageKey) {
    str = (theme.t(languageKey, values) || defaultValue || "") as string;
  }
  if (languageString) {
    str = replaceTokens(languageString, values);
  }

  if (!str) return "";

  // Configure DOMPurify to allow links
  const purifyConfig = {
    ALLOWED_TAGS: [
      "a",
      "strong",
      "br",
      "p",
      "h1",
      "h2",
      "h3",
      "h4",
      "span",
      "div",
      "em",
      "ol",
      "ul",
      "li",
    ],
    ALLOWED_ATTR: ["href", "target", "rel", "style"],
  };

  if (markdown) {
    try {
      const html = marked.parse(str, { async: false });
      sanitizedHtml = DOMPurify.sanitize(html, purifyConfig);
    } catch (e) {
      sanitizedHtml = str;
    }
  } else {
    sanitizedHtml = str;
  }

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: sanitizedHtml,
      }}
    />
  );
};

export default Language;

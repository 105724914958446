import { styled } from "@mui/material";

const PREFIX = "SectionContent";

export const classes = {
  sectionContentSubtitle: `${PREFIX}-sectionContentSubtitle`,
  sectionContentDescription: `${PREFIX}-sectionContentDescription`,
  smallCard: `${PREFIX}-smallCard`,
  smallCardIconContainer: `${PREFIX}-smallCardIconContainer`,
  smallCardTextContainer: `${PREFIX}-smallCardTextContainer`,
  largeCard: `${PREFIX}-largeCard`,
  largeCardTitle: `${PREFIX}-largeCardTitle`,
  boldText: `${PREFIX}-boldText`,
  initialContentLineItem: `${PREFIX}-initialContentLineItem`,
  leftAlignedContentLineItem: `${PREFIX}-leftAlignedContentLineItem`,
  contentLineItem: `${PREFIX}-contentLineItem`,
  list: `${PREFIX}-list`,
  financeBoxContainer: `${PREFIX}-financeBoxContainer`,
  otherOptionContainer: `${PREFIX}-otherOptionContainer`,
  otherOptionButtonContainer: `${PREFIX}-otherOptionButtonContainer`,
  otherOptionButton: `${PREFIX}-otherOptionButton`,
  taskListContainer: `${PREFIX}-taskListContainer`,
  taskListItemContainer: `${PREFIX}-taskListItemContainer`,
  learnMoreBox: `${PREFIX}-learnMoreBox`,
  lightPurpleCard: `${PREFIX}-lightPurpleCard`,
  outlinedLightBorderBox: `${PREFIX}-outlinedLightBorderBox`,
  rebateDetailContainer: `${PREFIX}-rebateDetailContainer`,
  filledDarkBox: `${PREFIX}-filledDarkBox`,
  outlinedBox: `${PREFIX}-outlinedBox`,
  paymentOptionTitle: `${PREFIX}-paymentOptionTitle`,
  testimonialContainer: `${PREFIX}-testimonialContainer`,
  lightPurpleDivider: `${PREFIX}-lightPurpleDivider`,
  miSaveslogoContainer: `${PREFIX}-miSaveslogoContainer`,
  logoContainer: `${PREFIX}-logoContainer`,
  logo: `${PREFIX}-logo`,
  teamImage: `${PREFIX}-teamImage`,
  certImageContainer: `${PREFIX}-certImageContainer`,
  certImage: `${PREFIX}-certImage`,
  largeCertImage: `${PREFIX}-largeCertImage`,
  customerInformationContainer: `${PREFIX}-customerInformationContainer`,
  testimonialImage: `${PREFIX}-testimonialImage`,
  smallCardContainer: `${PREFIX}-smallCardContainer`,
  footnoteIndex: `${PREFIX}-footnoteIndex`,
  bottomBorder: `${PREFIX}-bottomBorder`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.sectionContentSubtitle}`]: {
    fontWeight: 500,
    color: theme.palette.primary.light,
  },
  [`& .${classes.sectionContentDescription}`]: {
    fontSize: 20,
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.smallCard}`]: {
    display: "flex",
    boxShadow: "none",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.smallCardIconContainer}`]: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${classes.smallCardTextContainer}`]: {
    padding: theme.spacing(2),
    borderLeft: `1px solid ${theme.palette.divider}`,
    "& a": {
      color: theme.palette.primary.light,
    },
  },
  [`& .${classes.largeCard}`]: {
    borderRadius: 5,
    boxShadow: "none",
    padding: `${theme.spacing(2)} 0`,
    border: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.largeCardTitle}`]: {
    fontWeight: 500,
    color: theme.palette.primary.light,
  },
  [`& .${classes.boldText}`]: {
    fontWeight: 600,
  },
  [`& .${classes.initialContentLineItem}`]: {
    width: 60,
  },
  [`& .${classes.leftAlignedContentLineItem}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(1),
  },
  [`& .${classes.contentLineItem}`]: {
    display: "flex",
    alignItems: "center",
    // padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    justifyContent: "space-between",
  },
  [`& .${classes.list}`]: {
    margin: 0,
    "& li": {
      color: theme.palette.text.secondary,
    },
  },
  [`& .${classes.financeBoxContainer}`]: {
    borderRadius: 5,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.divider}`,
    "& a": {
      color: theme.palette.text.secondary,
    },
    /* Logo styling */
    "& img": {
      maxWidth: "100%",
      height: "auto",
      marginRight: theme.spacing(2),
      width: "auto",
    },
  },
  [`& .${classes.otherOptionContainer}`]: {
    borderRadius: 5,
    padding: theme.spacing(3),
    backgroundColor: "#F2EDF7",
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    "& h6": {
      lineHeight: 1,
      fontWeight: 400,
      marginBottom: theme.spacing(2),
      color: theme.palette.primary.main,
    },
  },
  [`& .${classes.otherOptionButtonContainer}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${classes.otherOptionButton}`]: {
    width: "60%",
    textAlign: "center",
    marginTop: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    "& :hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  [`& .${classes.taskListContainer}`]: {
    paddingLeft: theme.spacing(3),
    "& li": {
      marginBottom: theme.spacing(1),
    },
    "& li::marker": {
      color: theme.palette.text.secondary,
    },
  },
  [`& .${classes.taskListItemContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.learnMoreBox}`]: {
    borderRadius: 5,
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    "& a": {
      color: theme.palette.text.secondary,
    },
  },
  [`& .${classes.lightPurpleCard}`]: {
    boxShadow: "none",
    padding: theme.spacing(2),
    backgroundColor: "#F2EDF7",
  },
  [`& .${classes.outlinedLightBorderBox}`]: {
    borderRadius: 5,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    color: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
  },
  [`& .${classes.rebateDetailContainer}`]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  [`& .${classes.filledDarkBox}`]: {
    borderRadius: 5,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  [`& .${classes.outlinedBox}`]: {
    borderRadius: 5,
    color: theme.palette.primary.main,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  [`& .${classes.paymentOptionTitle}`]: {
    fontWeight: 600,
    color: theme.palette.primary.light,
  },
  [`& .${classes.testimonialContainer}`]: {
    fontSize: 22,
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.light,
  },
  [`& .${classes.lightPurpleDivider}`]: {
    height: 2,
    backgroundColor: theme.palette.primary.light,
  },
  [`& .${classes.miSaveslogoContainer}`]: {
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    "& img": {
      maxWidth: "100%",
      height: "auto",
      width: "auto",
    },
  },
  [`& .${classes.logoContainer}`]: {
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff", // White background for logo
    justifyContent: "center",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.logo}`]: {
    width: "80%", // Adjust as needed
    height: "auto",
    maxHeight: 80, // Adjust as needed
    objectFit: "contain",
  },
  [`& .${classes.teamImage}`]: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    borderRadius: 5,
  },
  [`& .${classes.certImageContainer}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [`& .${classes.certImage}`]: {
    width: "auto",
    maxWidth: "100%",
    height: "auto",
    maxHeight: 80,
    objectFit: "contain",
  },
  [`& .${classes.largeCertImage}`]: {
    maxHeight: "120px !important",
  },
  [`& .${classes.customerInformationContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  [`& .${classes.testimonialImage}`]: {
    height: 200,
    width: "100%",
    borderRadius: 8,
    objectFit: "cover",
  },
  [`& .${classes.smallCardContainer}`]: {
    marginBottom: 2,
    position: "relative",
  },
  [`& .${classes.footnoteIndex}`]: {
    width: 24,
    height: 24,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 8,
    left: 8,
    zIndex: 1,
  },
  [`& .${classes.bottomBorder}`]: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
}));

import React from "react";
import { useTheme } from "@mui/material";

export const PartiallyBelowGrade = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 218 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H210C214.418 0.5 218 4.08172 218 8.5V152.5C218 156.918 214.418 160.5 210 160.5H8C3.58173 160.5 0 156.918 0 152.5V8.5Z"
        style={{ fill: theme.palette.grey["900"] }}
      />
      <path
        d="M62 53.5L109 23.5L156 53.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59 47.5L109 15.5L159 47.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149 97.5V116.5H69V97.5"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M149 86H69"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M93.9692 96.9824V90H83.9692V96.9824"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M113.438 96.9824V90H103.438V96.9824"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M133.438 96.9824V90H123.438V96.9824"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M93.9692 98V104.982H83.9692V98"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M113.438 98V104.982H103.438V98"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M133.438 98V104.982H123.438V98"
        style={{ stroke: theme.palette.common.accent }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
        strokeWidth="1.5"
      />
      <path
        d="M163 97.5H55"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149 49.5V96.5M69 96.5V49.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PartiallyBelowGrade;

import { KeywordData } from "./types";

import dualFuelIcon from "@library/assets/dualFuel.svg";
import ductedIcon from "@library/assets/ducted.svg";
import heatPumpIcon from "@library/assets/heatPump.svg";
import weatherizationIcon from "@library/assets/weatherization.svg";
import ductlessIcon from "@library/assets/ductless.png";
import allElectricIcon from "@library/assets/allElectric.png";
import highEfficiencyFurnaceIcon from "@library/assets/highEfficiencyFurnace.png";
import highEfficiencyBoilerIcon from "@library/assets/highEfficiencyBoiler.png";

export const KEYWORD_LIBRARY: Record<string, KeywordData> = {
  "heat pump": {
    term: "Heat Pump",
    icon: heatPumpIcon,
    description:
      "A heat pump efficiently heats and cools your home using electricity - sort of like a combined furnace and air conditioner",
    // learnMoreLink: "#",
  },
  ducted: {
    term: "Ducted",
    icon: ductedIcon,
    description:
      "This system will utilize your existing ductwork, assuming it's usable. This reduces the cost of your new system.",
  },
  ductless: {
    term: "Ductless",
    icon: ductlessIcon,
    description:
      "This system does not require ductwork - instead, we'll mount a unit on the wall in each room you want to heat and cool. This allows for zoned comfort - meaning you can control the temperature of each room individually. ",
  },
  "dual-fuel": {
    term: "Dual-Fuel",
    icon: dualFuelIcon,
    description:
      "This system runs on electricity (heat pump) and gas (backup) - whichever is cheaper. This reduces your utility bill.",
  },
  "all-electric": {
    term: "All-Electric",
    icon: allElectricIcon,
    description:
      "This system is all-electric - no more fossil fuels! This results in significant energy savings, and often results in utility bill savings too.",
  },
  weatherization: {
    term: "Weatherization",
    icon: weatherizationIcon,
    description:
      "Reducing energy waste - via insulation, air sealing, and ductwork - improves comfort and reduces bills.",
  },
  "high-efficiency furnace": {
    term: "High-efficiency Furnace",
    icon: highEfficiencyFurnaceIcon,
    description:
      "A high-efficiency furnace generates heat, and a blower distributes this heat throughout your house through your existing ductwork.",
  },
  "high-efficiency boiler": {
    term: "High-efficiency Boiler",
    icon: highEfficiencyBoilerIcon,
    description:
      "A high-efficiency boiler heats water and distributes it through your existing radiators.",
  },
};

import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  Box,
  Grid2,
  Toolbar,
  CircularProgress,
  Alert,
  useTheme,
} from "@mui/material";

import { SettingsContext } from "@library/settings/provider";
import LoginPageView from "@library/pages/LoginPageView";
import ResetPasswordPageView from "@library/pages/ResetPasswordPageView";
import UpdatePasswordPageView from "@library/pages/UpdatePasswordPageView";
import UnsubscribeView from "@library/pages/UnsubscribeView";

import {
  LandingPageView,
  ConciergePageView,
  NotOperationalView,
} from "./pages";

import { UncaughtError } from "@library/components/UncaughtError";

import ConciergePageViewV3 from "./pages/ConciergePageViewV3";
import OnboardingV3PageView from "@library/pages/OnboardingV3PageView";
import { useIntercom } from "@library/hooks/intercom";

function Router() {
  const { isLoading, error, user } = useContext(SettingsContext);
  const theme = useTheme();
  useIntercom(user, isLoading);

  return (
    <>
      {error ? (
        <Box component={"main"}>
          <Toolbar sx={{ height: "64px" }} />{" "}
          <Grid2
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "100vh" }}
          >
            <Grid2 size={3}>
              <Alert severity="error">{error}</Alert>
            </Grid2>
          </Grid2>
        </Box>
      ) : isLoading ? (
        <Box component={"main"}>
          <Grid2
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
              position: "absolute",
              top: "-20px",
              bottom: 0,
              zIndex: 9999999999,
              left: 0,
              right: 0,
              backgroundColor: theme.palette.common.white,
            }}
          >
            <CircularProgress />
          </Grid2>
        </Box>
      ) : (
        <Box component={"main"}>
          <Toolbar sx={{ height: "64px" }} />{" "}
          {/* This empty Toolbar pushes content below the fixed AppBar */}
          <Routes>
            <Route path="/login" element={<LoginPageView />} />
            <Route path="/auth/login" element={<LoginPageView />} />{" "}
            {/* @DEPRECATE SUPPORT LEGACY PATH */}
            <Route path="/reset-password" element={<ResetPasswordPageView />} />
            <Route
              path="/auth/reset-password"
              element={<ResetPasswordPageView />}
            />{" "}
            {/* @DEPRECATE SUPPORT LEGACY PATH */}
            <Route
              path="/update-password"
              element={<UpdatePasswordPageView />}
            />
            <Route
              path="/auth/update-password"
              element={<UpdatePasswordPageView />}
            />{" "}
            {/* @DEPRECATE SUPPORT LEGACY PATH */}
            <Route
              path={"/job/:jobId/:quoteId?"}
              element={
                <ErrorBoundary fallback={<UncaughtError />}>
                  <ConciergePageViewV3 />
                </ErrorBoundary>
              }
            />
            <Route
              path={"/concierge/:quoteId"}
              element={
                <ErrorBoundary fallback={<UncaughtError />}>
                  <ConciergePageViewV3 />
                </ErrorBoundary>
              }
            />
            <Route path={"/concierge"} element={<ConciergePageView />} />
            <Route
              path={"/onboarding"}
              element={
                <ErrorBoundary fallback={<UncaughtError />}>
                  <OnboardingV3PageView />
                </ErrorBoundary>
              }
            />
            <Route
              path={"/onboarding3"}
              element={
                <ErrorBoundary fallback={<UncaughtError />}>
                  <OnboardingV3PageView />
                </ErrorBoundary>
              }
            />
            <Route path={"/not-operational"} element={<NotOperationalView />} />
            <Route
              path={"/unsubscribe/:userId"}
              element={<UnsubscribeView />}
            />
            <Route
              path={"/"}
              element={
                <ErrorBoundary fallback={<UncaughtError />}>
                  <LandingPageView />
                </ErrorBoundary>
              }
            />
            <Route path={"*"} element={<Navigate to={"/"} />} />{" "}
            {/* catch for undefined paths */}
          </Routes>
        </Box>
      )}
    </>
  );
}

export default Router;

import { useTheme } from "@mui/material";
import React from "react";

export const EnergyEfficientAppliances = ({ styles }: { styles?: object }) => {
  const theme = useTheme();
  return (
    <svg
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M109.226 41.4956H0.774414V109.194H109.226V41.4956Z"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8858 93.4132C34.7932 93.4132 40.3928 87.8137 40.3928 80.9063C40.3928 73.9989 34.7932 68.3994 27.8858 68.3994C20.9785 68.3994 15.3789 73.9989 15.3789 80.9063C15.3789 87.8137 20.9785 93.4132 27.8858 93.4132Z"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4748 101.284C42.7291 99.3016 50.2457 88.5713 48.2635 77.317C46.2813 66.0627 35.551 58.5462 24.2967 60.5283C13.0423 62.5105 5.52583 73.2408 7.50803 84.4951C9.49023 95.7494 20.2205 103.266 31.4748 101.284Z"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5479 82.6211C15.5479 82.6211 21.2376 78.101 28.9798 80.9596C36.722 83.8183 40.2411 81.6015 40.2411 81.6015"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.1115 93.4132C89.0189 93.4132 94.6184 87.8137 94.6184 80.9063C94.6184 73.9989 89.0189 68.3994 82.1115 68.3994C75.2041 68.3994 69.6045 73.9989 69.6045 80.9063C69.6045 87.8137 75.2041 93.4132 82.1115 93.4132Z"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.7249 101.28C96.9769 99.2844 104.481 88.5455 102.486 77.2935C100.491 66.0415 89.7516 58.5374 78.4996 60.5326C67.2476 62.5278 59.7434 73.2668 61.7386 84.5188C63.7339 95.7708 74.4729 103.275 85.7249 101.28Z"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.365 40.7579V36.3272C105.365 32.9049 102.591 30.1306 99.1685 30.1306H65.0578C61.6356 30.1306 58.8613 32.9049 58.8613 36.3272V40.7579"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.365 25.8342V25.5617C105.365 22.1395 102.591 19.3652 99.1685 19.3652H65.0578C61.6356 19.3652 58.8613 22.1395 58.8613 25.5617V25.8342C58.8613 27.439 60.1622 30.1302 61.767 30.1302H102.459C104.064 30.1302 105.365 27.439 105.365 25.8342Z"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.17871 20.979L8.37039 38.4025C8.53752 40.1562 10.0105 41.4955 11.772 41.4955H44.0192C45.7809 41.4955 47.2538 40.1562 47.4209 38.4025L49.6126 20.979"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.1394 10.8511H3.65527V20.7753H52.1394V10.8511Z"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5303 28.1733H31.2643"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.3308 6.77832V6.52678C49.3308 3.36738 46.7695 0.806152 43.6101 0.806152H12.1191C8.95966 0.806152 6.39844 3.36738 6.39844 6.52678V6.77832C6.39844 8.25993 7.59944 10.8512 9.08092 10.8512H46.6482C48.1297 10.8512 49.3308 8.25993 49.3308 6.77832Z"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.415 78.6418V83.1696"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55 42.4543V108.151"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.975586 53.3494H109.021"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EnergyEfficientAppliances;

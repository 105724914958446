import { Theme } from "@mui/material";

export class APIModel {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _data: any;
  theme?: Theme;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any, theme?: Theme) {
    this._data = data;
    this.theme = theme;
    this.initialize();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defineGetters(data: any) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        Object.defineProperty(this, key, {
          get() {
            return this._data[key];
          },
          enumerable: true,
          configurable: true,
        });
      }
    }
  }

  initialize() {
    this.defineGetters(this._data);
  }
}

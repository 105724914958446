import * as Sentry from "@sentry/react";

let SENTRY_LOADED = false;

export const init = () => {
  if (
    import.meta.env.VITE_SENTRY_DSN &&
    import.meta.env.VITE_ENV === "prod" &&
    !SENTRY_LOADED
  ) {
    SENTRY_LOADED = true;
    try {
      Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        beforeSend(event) {
          if (event.exception) {
            if (
              (event?.exception?.values?.[0]?.value || "")
                .toLowerCase()
                .includes("jquery")
            ) {
              return null;
            }
            const frames =
              event.exception.values?.[0]?.stacktrace?.frames || [];
            if (
              frames.some(
                (frame) =>
                  frame.filename?.includes("gtm.js") ||
                  frame.filename?.includes("analytics.js")
              )
            ) {
              return null; // Ignore the error
            }
          }
          return event; // Otherwise, send to Sentry
        },
      });
    } catch (e) {
      console.error(e);
    }
  } else {
    console.warn("Unable to load Sentry");
  }
};

export default Sentry;

import { useState, useRef, useEffect } from "react";
import toNumber from "lodash/toNumber";
import isString from "lodash/isString";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import transform from "lodash/transform";
import union from "lodash/union";
import keys from "lodash/keys";
import { Onboarding, OnboardingPayload } from "@library/domain/onboarding";
import {
  Address,
  FloorData,
  Home,
  LoadCalcRoom,
  RoomData,
  SourceTotals,
} from "@library/domain/home";
import {
  PolyvectorPayload,
  PricingLineItem,
  Task,
} from "@library/domain/estimate";

export const PHONE_NUMBER = "(313) 334-7525";
export const PHONE_NUMBER_TEL = "1 313 334 7525";
export const EMAIL = "homeowner@pearledison.com";
export const PHONE_NUMBER_JAKE = "1 312 358 5085";

export const CALENDLY_URL_HOME_ASSESSMENT =
  "https://calendly.com/pearledison/home-assessment";
export const CALENDLY_URL_VIRTUAL_ASSESSMENT =
  "https://calendly.com/jake-nwos/30min";
export const CALENDLY_URL_EMERGENCY_SUPPORT =
  "https://calendly.com/jake-nwos/emergency-support";
export const CALENDLY_URL_ENERGY_AUDIT =
  "https://calendly.com/jake-nwos/energy-audit";
export const CALENDLY_URL_WEATHERIZATION_CONSULTATION =
  "https://calendly.com/jake-nwos/weatherization-consultation";

let CORP_HOST = "";
if (import.meta.env.ENV === "prod") {
  CORP_HOST = "https://pearledison.com";
} else if (import.meta.env.ENV === "staging") {
  CORP_HOST = "https://preview.pearledison.com";
} else {
  CORP_HOST = "";
}

let app_host = "";
if (import.meta.env.ENV === "prod") {
  app_host = "https://app.pearledison.com";
} else if (import.meta.env.ENV === "staging") {
  app_host = "https://preview.pearledison.com";
} else {
  app_host = "http://localhost:5173";
}
export const APP_HOST = app_host;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opera: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MSStream: any;
  }
}

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

export const corpUrl = (path: string) => `${CORP_HOST}${path}`;

let CUSTOMER_HOST = "http://localhost:5173";
if (import.meta.env.VITE_ENV === "staging") {
  CUSTOMER_HOST = "https://preview.pearledison.com";
} else if (import.meta.env.VITE_ENV === "prod") {
  CUSTOMER_HOST = "https://app.pearledison.com";
}

export const customerUrl = (path: string) => `${CUSTOMER_HOST}${path}`;

export const useIsTruncated = () => {
  const [isTruncated, setIsTruncated] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight);
    }
  }, []);

  return [ref, isTruncated] as const;
};

// custom hook for dynamic displaying when text is truncated
export const useIsHorizontallyTruncated = (content: React.ReactNode) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const checkTruncation = () => {
      const element = ref.current;
      if (element) {
        setIsTruncated(element.scrollWidth > element.clientWidth);
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);
    return () => window.removeEventListener("resize", checkTruncation);
  }, [content]);

  return [ref, isTruncated] as const;
};

export const shortenFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
) => {
  const lastLetter = lastName?.charAt(0).toUpperCase();

  const name = `${firstName} ${lastLetter}`;
  return name;
};

export const formatFromUpperCase = (name: string = ""): string => {
  return name
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatFromHyphenated = (name: string = ""): string => {
  return name
    .toLowerCase()
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const trimMiddle = (str: string, maxLength = 10) => {
  if (str.length < maxLength) return str;
  const start = str.substring(0, maxLength / 2);
  const end = str.substring(str.length - maxLength / 2, str.length);
  return `${start}...${end}`;
};

interface FormatNumberOpts {
  round?: boolean;
  roundTo?: number;
  empty?: boolean;
  sign?: boolean;
  toFixed?: number;
  showNegative?: boolean;
  increaseDecrease?: boolean;
  arrow?: boolean; // New option
  units?: string;
  precise?: boolean;
}

export const formatNumber = (
  value: number | string | null | undefined,
  opts?: FormatNumberOpts
) => {
  if (opts?.empty && !value) return "";

  let newValue = value;
  if (typeof newValue === "string") {
    newValue = newValue.replace(/,/g, "");
  }
  newValue = toNumber(newValue);

  if (opts?.round || opts?.roundTo) {
    if (opts?.roundTo) {
      newValue = Math.round(newValue / opts.roundTo) * opts.roundTo;
    } else {
      newValue = Math.round(newValue);
    }
  }

  let formatted = new Intl.NumberFormat("en", {
    minimumFractionDigits: opts?.toFixed ?? 0,
  }).format(newValue);

  if (opts?.toFixed !== undefined) {
    formatted = parseFloat(formatted).toFixed(opts.toFixed);
  }

  if (opts?.units) {
    formatted = `${formatted}${opts.units}`;
  }

  // Handle arrow or increase/decrease text (mutually exclusive)
  if (value) {
    const isNegative = String(newValue).includes("-") || newValue < 0;
    if (opts?.arrow) {
      formatted = formatted.replace(/-/g, "");
      formatted = `${isNegative ? "↓" : "↑"} ${opts?.precise === false ? "~ " : ""}${formatted}`;
    } else {
      if (opts?.increaseDecrease) {
        if (isNegative) {
          formatted = formatted.replace(/-/g, "");
          formatted = `${opts?.precise === false ? "~ " : ""}${formatted} decrease`;
        } else {
          formatted = `${opts?.precise === false ? "~ " : ""}${formatted} increase`;
        }
      }
      if (opts?.precise === false) {
        formatted = `~ ${formatted}`;
      }
    }
  }

  if (opts?.showNegative === false) {
    formatted = formatted.replace(/-/g, "");
  }

  if (formatted.includes("NaN")) return "";
  return formatted;
};

const defaultOpts = { round: true, sign: true, empty: false };

export const formatCurrency = (
  value: number | string | null | undefined,
  opts: FormatNumberOpts = { round: true, sign: true, empty: false }
) => {
  if (opts?.empty && !value) return "";
  const fullOpts = { ...defaultOpts, ...opts };

  let resp;
  // If arrow is true, prepend it before the dollar sign
  if (fullOpts.arrow && value) {
    const isNegative = String(value).includes("-") || Number(value) < 0;
    resp = `${isNegative ? "↓" : "↑"}${fullOpts.precise === false ? " ~ " : ""}${fullOpts.sign ? "$" : ""}${formatNumber(value, { ...fullOpts, arrow: false, precise: undefined })}`;
  } else {
    resp = `${fullOpts.precise === false ? " ~ " : ""}${fullOpts.sign ? "$" : ""}${formatNumber(value, { ...fullOpts, precise: undefined })}`;
  }
  if (resp.includes("NaN")) resp = "";
  return resp;
};

export const camelCaseToHuman = (camelCaseString: string): string => {
  let result = camelCaseString.replace(/_/g, " ");
  result = result.replace(/([A-Z])/g, " $1").toLowerCase();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const formatPhoneNumber = (
  value: string,
  previousValue: string = ""
) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
  return value;
};

export const chunksToGrid = (chunks: number = 3) => {
  if (!chunks) return undefined;
  if (chunks == 1) return 12;
  if (chunks == 2) return 6;
  if (chunks == 3) return 4;
  if (chunks == 4) return 3;
  if (chunks == 5) return 2.4;
  if (chunks == 6) return 2;
  if (chunks == 7) return 1.714;
  if (chunks == 8) return 1.5;
  return 4;
};

export const operationalStates = ["MI"];

export const JOB_STATUS = {
  10: "Onboarding",
  20: "Instant Estimate",
  30: "Home Assessment",
  40: "Final Quote",
  45: "Marketplace",
  50: "Contracted",
  60: "Scheduled",
  70: "In-Progress",
  75: "Final Inspection",
  80: "Complete",
  100: "Closed-Win",
};

export const JOB_STATUS_OPTIONS = () => {
  return Object.keys(JOB_STATUS).map((id) => {
    return {
      value: parseInt(id, 10),
      title: JOB_STATUS[id as unknown as keyof typeof JOB_STATUS],
    };
  });
};

export const TIERS = {
  // @DEPRECATED
  free: "Free",
  base: "Base",
  replace: "Replace",
  pearl: "Pearl",
  edison: "Edison",
  a2zero: "A²ZERO",
  a2zero_he: "A²Z Efficient",
};

export const camelToHuman = (str: string) => {
  let output = "";
  const len = str?.length || 0;
  let char;

  for (let i = 0; i < len; i++) {
    char = str.charAt(i);

    if (i == 0) {
      output += char.toUpperCase();
    } else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
      output += " " + char;
    } else if (char == "-" || char == "_") {
      output += " ";
    } else {
      output += char;
    }
  }

  return output;
};

export const getOnboardingData = (
  onboarding?: Onboarding
): Partial<OnboardingPayload> => {
  if (!onboarding) return {};

  if (onboarding.data) {
    return onboarding.data;
  } else if (onboarding.dataJSON) {
    try {
      const parsedData = JSON.parse(onboarding.dataJSON);

      // FYI this is probably a moot return, since `data` is defined
      return {
        sqFootage: parsedData.sqFootage,
        homeType: parsedData.homeType,
        yearBuilt: parsedData.yearBuilt,
        whatsImportantToYou: parsedData.whatsImportantToYou,
        peopleLiveInHome: parsedData.peopleLiveInHome,
        numBathrooms: parsedData.numBathrooms,
        numBedrooms: parsedData.numBedrooms,
        attic: parsedData.attic,
        atticFinish: parsedData.atticFinish,
        basement: parsedData.basement,
        basementFinished: parsedData.basementFinished,
        rooms: parsedData.rooms,
        user: {
          ...parsedData.user,
          phoneNumber: parsedData.user.phoneNumber,
          communicationPreference: parsedData.user.communicationPreference,
        },
      };
    } catch (error) {
      console.error("Error parsing dataJSON:", error);
      return {};
    }
  }

  return {};
};

export const calculateTotalBTU = (
  rooms: LoadCalcRoom[]
): {
  heating: number;
  cooling: number;
} => {
  const initialValue = { heating: 0, cooling: 0 };

  if (!rooms) return initialValue;

  return rooms.reduce((total, room) => {
    const coolingSum = room?.cooling
      ? Object.values(room.cooling).reduce((sum, value) => sum + value, 0)
      : 0;
    const heatingSum = room?.heating
      ? Object.values(room.heating).reduce((sum, value) => sum + value, 0)
      : 0;

    return {
      cooling: total.cooling + Math.ceil(coolingSum),
      heating: total.heating + Math.ceil(heatingSum),
    };
  }, initialValue);
};

export const calculateRoomBTU = (rooms: LoadCalcRoom[]): FloorData[] => {
  const floorMap = new Map<string, RoomData[]>();

  rooms.forEach((room) => {
    const floorName = room.floor;
    const roomType = room.type
      .replace("_", " ")
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase());

    const coolingSum = room.cooling
      ? Object.values(room.cooling).reduce((sum, value) => sum + value, 0)
      : 0;
    const heatingSum = room.heating
      ? Object.values(room.heating).reduce((sum, value) => sum + value, 0)
      : 0;

    const roomData: RoomData = {
      name: roomType,
      sqFt: 0, // TODO: where does this come from?
      heatBTU: Math.ceil(heatingSum),
      coolBTU: Math.ceil(coolingSum),
    };

    if (!floorMap.has(floorName)) {
      floorMap.set(floorName, []);
    }
    floorMap.get(floorName)!.push(roomData);
  });

  const sortedFloors = Array.from(floorMap.entries()).sort(([a], [b]) => {
    if (a === "basement") return -1;
    if (b === "basement") return 1;
    return parseInt(a) - parseInt(b);
  });

  return sortedFloors.map(([floorName, rooms]) => ({
    name: floorName === "basement" ? "Basement" : `Floor ${floorName}`,
    rooms,
  }));
};

export const calculateSourceTotals = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadCalculations: any[]
): SourceTotals => {
  const initialTotals: SourceTotals = {
    heating: {
      wall: 0,
      window: 0,
      shg: 0,
      ceiling: 0,
      floor: 0,
      infiltration: 0,
      duct_loss: 0,
    },
    cooling: {
      wall: 0,
      window: 0,
      shg: 0,
      ceiling: 0,
      floor: 0,
      infiltration: 0,
      duct_loss: 0,
    },
  };

  if (!loadCalculations) return initialTotals;

  return loadCalculations.reduce((totals, room) => {
    ["heating", "cooling"].forEach((type) => {
      Object.keys(room[type]).forEach((source) => {
        totals[type][source] += room[type][source];
      });
    });
    return totals;
  }, initialTotals);
};

interface FormattedSection {
  title: string;
  total: number;
  items: PricingLineItem[];
}

interface CategoryMapping {
  title: string;
  categories: readonly string[];
  totalKey:
    | "hvacCostTotal"
    | "electricalCostTotal"
    | "weatherizationCostTotal"
    | "otherCostTotal";
}

const CATEGORY_MAPPINGS: Record<string, CategoryMapping> = {
  HVAC: {
    title: "HVAC System",
    categories: [
      "Indoor Unit",
      "Outdoor Unit",
      "Balance of System",
      "Permitting",
    ],
    totalKey: "hvacCostTotal",
  },
  ELECTRICAL: {
    title: "Electrical Work",
    categories: ["Electrical Wiring", "Service Panel"],
    totalKey: "electricalCostTotal",
  },
  WEATHERIZATION: {
    title: "Weatherization",
    categories: ["Weatherization"],
    totalKey: "weatherizationCostTotal",
  },
  OTHER: {
    title: "Other Costs",
    categories: ["Miscellaneous"],
    totalKey: "otherCostTotal",
  },
} as const;

export const formatPricingData = (
  payload?: PolyvectorPayload
): FormattedSection[] => {
  if (!payload) return [];

  const selectedTier = payload.pricingSelectedTier;
  const lineItems = payload.pricingLineItems?.[selectedTier] || [];
  const sections: FormattedSection[] = [];
  const tasks: Task[] = payload.tasks || [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(CATEGORY_MAPPINGS).forEach(([_, mapping]) => {
    const relevantItems = lineItems.filter((item: PricingLineItem) =>
      mapping.categories.includes(item.category as string)
    );

    if (relevantItems.length > 0) {
      const total = (payload[mapping.totalKey] as number) || 0;
      for (let i = 0; i < relevantItems.length; i += 1) {
        const item = relevantItems[i];
        const task = tasks.find(
          (t) => t.uuid === item.uuid || t.parent_uuid === item.uuid
        );
        item.task = task;
      }
      sections.push({
        title: mapping.title,
        total,
        items: relevantItems,
      });
    }
  });

  return sections;
};

const monthNames = {
  "1": "Jan",
  "2": "Feb",
  "3": "Mar",
  "4": "Apr",
  "5": "May",
  "6": "Jun",
  "7": "Jul",
  "8": "Aug",
  "9": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};

export const DEFAULT_FIPS_CODE = "26163";

export const MT_PER_MTU = {
  electricity: 1,
  natural_gas: 1,
  fuel_oil: 1,
  propane: 1,
  // electricity: 0.000417, // per kWh
  // natural_gas: 0.0055, // per CCF
  // fuel_oil: 0.010145238, // per gallon
  // propane: 0.005619048, // per gallon
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processGraphData = (data: any, type: "price" | "footprint") => {
  if (!data) return [];
  if (!isObject(data[type])) return [];
  return (
    Object.entries(data[type])
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map(([month, values]: [string, any]) => {
        const currentTotal =
          (values.current.natural_gas || 0) *
            (type === "footprint" ? MT_PER_MTU.natural_gas : 1) +
          (values.current.electricity || 0) *
            (type === "footprint" ? MT_PER_MTU.electricity : 1) +
          (values.current.fuel_oil || 0) *
            (type === "footprint" ? MT_PER_MTU.fuel_oil : 1) +
          (values.current.propane || 0) *
            (type === "footprint" ? MT_PER_MTU.propane : 1);
        const newTotal =
          (values.new.natural_gas || 0) *
            (type === "footprint" ? MT_PER_MTU.natural_gas : 1) +
          (values.new.electricity || 0) *
            (type === "footprint" ? MT_PER_MTU.electricity : 1) +
          (values.new.fuel_oil || 0) *
            (type === "footprint" ? MT_PER_MTU.fuel_oil : 1) +
          (values.new.propane || 0) *
            (type === "footprint" ? MT_PER_MTU.propane : 1);

        const savings = currentTotal - newTotal;
        return {
          month: monthNames[month as keyof typeof monthNames],
          total: currentTotal,
          savings: savings,
        };
      })
      .sort((a, b) => {
        const monthOrder = Object.values(monthNames);
        return monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month);
      })
  );
};

export const deltaObjects = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  o1: Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  o2: Record<string, any>
) => {
  if (isEqual(o1, o2)) return undefined; // If objects are identical, return undefined

  if (isObject(o1) && isObject(o2)) {
    return transform(
      union(keys(o1), keys(o2)),
      (result, key) => {
        const diff = deltaObjects(o1[key], o2[key]);
        if (diff !== undefined) result[key] = diff;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {} as Record<string, any>
    );
  }

  return o1 !== o2 ? (o2 !== undefined ? o2 : o1) : undefined;
};

export const languageJoinArray = (
  arr: string[],
  separator = ", ",
  terminator = " and "
) => {
  if (arr.length === 0) return "";
  if (arr.length === 1) return arr[0];
  if (arr.length === 2) return arr[0] + `${terminator}` + arr[1];
  return arr.slice(0, -1).join(separator) + `${terminator}` + arr.slice(-1);
};

export const camelToSpaces = (camelCaseString: string) => {
  return camelCaseString.replace(/([A-Z])/g, " $1");
};

export const snakeToSpaces = (str: string) => {
  return str
    .split("_") // Split by underscore
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join with spaces
};

export const stringToBoolean = (value: string | boolean) => {
  if (isString(value)) {
    if (value === "false") return false;
    return true;
  }
  return value;
};

export const winterMonths = ["Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];
export const summerMonths = ["Apr", "May", "Jun", "Jul", "Aug", "Sep"];

export const joinAddress = (address: Address | Home = {} as Address | Home) => {
  let resp = "";
  if (address.address1) resp += `${address.address1}`;
  if (address.address2) resp += ` ${address.address2}`;
  if (address.city) resp += `, ${address.city}`;
  if (address.state) resp += `, ${address.state}.`;
  if (address.zip) resp += ` ${address.zip}`;
  return resp;
};

export const downloadSVG = (
  svgElement: HTMLElement,
  fileName = "image.svg"
) => {
  const serializer = new XMLSerializer();
  const svgString = serializer.serializeToString(svgElement);
  const blob = new Blob([svgString], { type: "image/svg+xml" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const downloadPNG = (
  svgElement: HTMLElement,
  fileName = "image.png"
) => {
  const serializer = new XMLSerializer();
  const svgString = serializer.serializeToString(svgElement);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const img = new Image();
  const svgBlob = new Blob([svgString], {
    type: "image/svg+xml;charset=utf-8",
  });
  const url = URL.createObjectURL(svgBlob);

  img.onload = function () {
    if (ctx) {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      URL.revokeObjectURL(url);

      // Download as PNG
      const pngUrl = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = pngUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  img.src = url;
};

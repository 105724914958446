import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

import { baseConfig } from "../config";
import { baseOverrides } from "../overrides";

import bg from "./home-background.jpg";
import logo from "./logo.png";

const FONT_LIGHT = 300;
const FONT_REGULAR = 400;
const FONT_NORMAL = 500;
const FONT_MEDIUM = 600;
const FONT_BOLD = 600;

const config = merge({}, cloneDeep(baseConfig), {
  name: "A²ZERO",
  language: {
    "en-us": {
      onboardingTitle:
        "Upgrade to efficient, climate-friendly, wallet-friendly home heating and cooling.",
      tier: {
        pearl: {
          name: "Dual-Fuel",
          title: "Dual-Fuel Package",
        },
        a2zero: {
          name: "A²ZERO",
          title: "A²ZERO Package",
          snippet:
            "We recommend this package to optimize your monthly energy cost while taking advantage of the A²ZERO Home Energy Rebates",
        },
        a2zero_he: {
          name: "A²ZERO High-Efficiency",
          title: "A²ZERO High-Efficiency Package",
          snippet:
            "We recommend this package to optimize your energy consumption and carbon footprint while taking advantage of the A²ZERO Home Energy Rebates.",
        },
      },
      step: {
        LetsGetStartedStep: {
          title: "Welcome to A²ZERO Heat Pump Concierge!",
          description:
            "You could be eligible for $15,000 or more in A²ZERO Home Energy Rebates for efficient heating and cooling and weatherization - plus additional state, city, and utility rebates and tax incentives. Reserve funds today - it only takes five minutes.",
        },
        InstantEstimateStep: {
          reminderText:
            "After you review your Instant Estimate, reserve your A²ZERO Home Energy Rebates by scheduling a Home Assessment to finalize the scope of your project.",
        },
      },
      concierge: {
        PackageDetails: {
          PackageCard: {
            recommendations: {
              ducted: {
                a2zero: {
                  explanation:
                    "We've designed this system to maximize your savings! Your new system includes an electric heat pump that is sized to the full heating load of your home and a brand new high-efficiency natural gas system to provide backup heat on very cold days, as this will result in lower utility bills. During the summer, this system will provide efficient air conditioning.",
                  brand:
                    "We recommend Amana, because they deliver superior performance and reliability - and because their air-source heat pump will pair with your Amana backup furnace. But, if you have a brand preference, we'll work with you.",
                },
                a2zero_he: {
                  explanation:
                    "We've designed this system to maximize your climate impact! Your new system includes an electric heat pump, which will replace your existing system - no backup heat required! This may result in higher utility bills during a cold winter, but minimizes your impact on the planet. During the summer, this system will provide efficient air conditioning.",
                  brand:
                    "Mitsubishi Hyper Heat heat pumps are the gold standard in extreme cold weather performance. If you're going all-electric, we recommend Mitsubishi.",
                },
              },
              ductless: {
                a2zero: {
                  explanation:
                    "We've designed this system to maximize your savings! Your new system includes an electric heat pump that is sized to the full heating load of your home and a brand new high-efficiency natural gas system to provide backup heat on very cold days, as this will result in lower utility bills. During the summer, this system will provide efficient air conditioning.",
                  brand:
                    "We recommend Amana, because they deliver superior performance and reliability - and because their air-source heat pump will pair with your Amana backup furnace. But, if you have a brand preference, we'll work with you.",
                },
                a2zero_he: {
                  explanation:
                    "We've designed this system to maximize your climate impact! Your new system includes an electric heat pump, which will replace your existing system - no backup heat required! This may result in higher utility bills during a cold winter, but minimizes your impact on the planet. During the summer, this system will provide efficient air conditioning.",
                  brand:
                    "Mitsubishi Hyper Heat heat pumps are the gold standard in extreme cold weather performance. If you're going all-electric, we recommend Mitsubishi.",
                },
              },
            },
          },
        },
      },
    },
  },
  tiers: {
    default: "a2zero_he",
    recommended: ["a2zero_he", "a2zero"],
    available: ["pearl", "a2zero", "a2zero_he"],
  },
  rebates: {
    ami: {
      lower: "80",
      upper: "120",
    },
  },
  sx: {
    step: {
      LetsGetStartedStep: {
        //       backgroundColor: "#0b9a6d",
        //       ".MuiButton-containedPrimary": {
        //         backgroundColor: "#43749E",
        //       },
        //       ".MuiTypography-root": {
        //         color: "#FFF !important",
        //       },
        //       ".MuiButtonBase-root": {
        //         color: "#FFF !important",
        //       },
        //       ".MuiButton-outlinedPrimary": {
        //         borderColor: "#FFF",
        //       },
        //       ".Navigation": {
        //         backgroundColor: "#3BAE8A !important",
        //         boxShadow: "none !important",
        //       },
        ".LetsGetStartedStep-descriptionButtonContainer": {
          flexDirection: "column !important",
          "& .LetsGetStartedStep-videoButton": {
            margin: "8px 0",
          },
        },
        //       ".LetsGetStartedStep-boxContainer": {
        //         backgroundColor: "#3BAE8A !important",
        //         svg: {
        //           path: {
        //             stroke: "#FFF !important",
        //           },
        //         },
        //       },
        //       ".LetsGetStartedStep-buttonContainer": {
        //         px: "32px !important",
        //       },
      },
    },
  },
  onboarding: {
    default: {
      payload: {
        projectType: "heating_cooling",
        heatingCoolingProjectScope: "both",
        heatingCoolingProjectType: "replace",
        projectMotivation: "online_quote",
        wholeOrPartialHome: "whole",
      },
      slideKey: "LetsGetStartedStep",
    },
    invisible: [
      "ProjectTypeStep",
      "HeatingCoolingProjectScopeStep",
      "HeatingCoolingProjectStep",
      "HeatingOrAirConditioningServiceAddressStep",
      "HeatingOrAirConditioningServiceStep",
      "HeatingOrAirConditioningScheduleServiceStep",
      "HeatingOrAirConditioningReplacementStep",
      "HeatingOrAirConditioningReplaceEmergencyStep",
      "HeatingOrAirConditioningReplaceEmergencyScheduleStep",
      "HeatingOrAirConditioningWholeOrPartialStep",
      "UploadQuoteStep",
      "SchedulingAddressStep",
      "SchedulingCommunicationStep",
      "SchedulingStep",
    ],
  },
});

const overrides = merge({}, cloneDeep(baseOverrides), {
  logo: {
    imageSrc: logo,
    alt: "A²ZERO Logo",
    logoUrl: `${window.location.origin}/onboarding?s=true`,
    width: 150,
    height: 30,
    poweredBy: true,
  },
  splash: {
    imageSrc: bg,
  },
  //shadows:[],
  typography: {
    fontFamily: "Fira Sans",
    fontWeightLight: FONT_LIGHT,
    fontWeightRegular: FONT_REGULAR,
    fontWeightNormal: FONT_NORMAL,
    fontWeightMedium: FONT_MEDIUM,
    fontWeightBold: FONT_BOLD,
    h1: {
      fontWeight: FONT_REGULAR,
      fontSize: "42px",
    },
    h2: {
      fontWeight: FONT_REGULAR,
      fontSize: "25px",
    },
    h3: {
      fontWeight: FONT_REGULAR,
      fontSize: "22px",
    },
    h4: {
      fontWeight: FONT_MEDIUM,
      fontSize: "20px",
    },
    h5: {
      fontWeight: FONT_MEDIUM,
      fontSize: "18px",
    },
    body1: {
      fontWeight: 400,
      "@media (max-width:600px)": {
        fontSize: "0.8rem",
      },
    },
    body2: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: "#0b9a6d",
      dark: "#0b6b4c",
      light: "#3BAE8A",
      lighter: "#dafef3",
    },
    secondary: {
      main: "#43749E",
      light: "#017bbc",
    },
    accent: {
      main: "#FCF2D9",
    },
    success: {
      main: "#FCF2D9",
    },
    warning: {
      main: "#97ff00", //"#7FCD0D"
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
        },
      },
    },
  },
});

export default {
  ...overrides,
  config,
};

import React from "react";
import {
  Box,
  Grid2,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import sumBy from "lodash/sumBy";
import { Root, classes } from "./styles";
import { SectionContentProps } from "../../types";
import ImpactChart from "../ImpactChart";
import {
  // energyPricesOptions,
  // energyUsageOptions,
  weatherOptions,
} from "../../constants";
import {
  formatCurrency,
  processGraphData,
  summerMonths,
  winterMonths,
} from "@library/common";
import Language from "@library/components/Language";

const LIFETIME = 15;

const baseLanguageLocation = "concierge.PackageDetails.BillImpactContent";

export const BillImpactContent: React.FC<SectionContentProps> = ({
  theme,
  graphData,
  graphDataOptions,
  onGraphOptionChange,
}) => {
  const processedData = processGraphData(graphData, "price");

  const summerSavings = sumBy(
    processedData.filter((d) => summerMonths.includes(d.month)),
    (d) => d.savings
  );

  const winterSavings = sumBy(
    processedData.filter((d) => winterMonths.includes(d.month)),
    (d) => d.savings
  );

  const totalSavings = processedData.reduce(
    (sum, month) => sum + month.savings,
    0
  );

  const handleWeatherChange = (value: string) => {
    onGraphOptionChange?.({ weatherSeverity: value });
  };

  // FYI: we do not offer any other option but "Staus Quo" for energy use/price for now
  // const handleEnergyPricesChange = (value: string) => {
  //   onGraphOptionChange?.({ energyPrices: value });
  // };

  // const handleEnergyUsageChange = (value: string) => {
  //   onGraphOptionChange?.({ energyUsage: value });
  // };

  return (
    <Root>
      <Box>
        <Box className={classes.sectionContentDescription} sx={{ mb: 2 }}>
          <Language
            languageKey={`${baseLanguageLocation}.savings`}
            markdown={true}
            values={{
              totalSavings: formatCurrency(totalSavings, { precise: true }),
              lifetimeSavings: formatCurrency(totalSavings * LIFETIME, {
                precise: true,
              }),
              summerSavings: formatCurrency(summerSavings, { round: true }),
              winterSavings: formatCurrency(winterSavings, { round: true }),
            }}
          />
        </Box>
        {/* Dropdowns container */}
        <Grid2 container spacing={2} sx={{ mb: 2 }}>
          <Grid2
            size={{
              xs: 12,
              sm: 4,
            }}
          >
            <FormControl fullWidth>
              <InputLabel>
                {theme?.t(`${baseLanguageLocation}.weather`)}
              </InputLabel>
              <Select
                value={graphDataOptions?.weatherSeverity || "Average"}
                label={theme?.t(`${baseLanguageLocation}.weather`)}
                onChange={(e) => handleWeatherChange(e.target.value)}
              >
                {weatherOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          {/* FYI: we do not offer any other option but "Staus Quo" for energy use/price for now */}
          {/* <Grid2
            size={{
              xs: 12,
              sm: 4,
            }}
          >
            <FormControl fullWidth>
              <InputLabel>
                {theme?.t(`${baseLanguageLocation}.energyUsage`)}
              </InputLabel>
              <Select
                value={graphDataOptions?.energyUsage || "Status Quo"}
                label={theme?.t(`${baseLanguageLocation}.energyUsage`)}
                onChange={(e) => handleEnergyUsageChange(e.target.value)}
              >
                {energyUsageOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2> */}
          {/* <Grid2
            size={{
              xs: 12,
              sm: 4,
            }}
          >
            <FormControl fullWidth>
              <InputLabel>
                {theme?.t(`${baseLanguageLocation}.energyPrices`)}
              </InputLabel>
              <Select
                value={graphDataOptions?.energyPrices || "Status Quo"}
                label={theme?.t(`${baseLanguageLocation}.energyPrices`)}
                onChange={(e) => handleEnergyPricesChange(e.target.value)}
              >
                {energyPricesOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2> */}
        </Grid2>
        <Grid2 container spacing={3}>
          <Grid2 size={12}>
            <ImpactChart data={processedData || []} />
          </Grid2>
        </Grid2>
      </Box>
    </Root>
  );
};

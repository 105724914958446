import React from "react";
import {
  Box,
  Chip,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/material";
import { Rebate } from "@library/domain/estimate";
import { formatCurrency } from "@library/common";
import PlaceholderImage from "@library/components/PlaceholderImage";
import ComingSoon from "@library/components/ConciergeV3/components/ComingSoon";
import moment from "moment";

// Determine the priority of a rebate based on timing and type
const getRebatePriority = (rebate: Rebate) => {
  if (rebate.timing === "instant") {
    return 1; // Point-of-sale rebate (highest priority)
  } else if (rebate.timing === "paid_later") {
    return 2; // Rebate paid after installation
  } else if (rebate.type === "credit") {
    return 3; // Tax credit
  }

  return 4; // Default priority
};

const sortRebates = (rebates: Rebate[], useCapForSorting: boolean) => {
  return rebates.sort((a, b) => {
    // First, sort by priority (timing and type)
    const priorityA = getRebatePriority(a);
    const priorityB = getRebatePriority(b);

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    // If same priority, use the appropriate value for sorting
    const valueA = useCapForSorting ? a.total_cap : a.totals.eligible;
    const valueB = useCapForSorting ? b.total_cap : b.totals.eligible;

    return valueB - valueA;
  });
};

const EligibleRebatesStep = ({
  showCap = false,
  slideState,
}: {
  showCap?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slideState: any;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const rebates = slideState.rebates || [];

  const sortedRebates = sortRebates(rebates, showCap);

  const DesktopLayout = ({
    rebate: r,
    eligible,
    total,
    types = [],
  }: {
    rebate: Rebate;
    eligible: boolean;
    total: string;
    types: string[];
  }) => (
    <Stack
      sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 1px 1px 1px rgba(0,0,0,0.1)",
      }}
      direction="row"
    >
      <Stack
        sx={{
          minWidth: "200px",
          backgroundColor: theme.palette.divider,
        }}
      >
        {r.imageUrl ? (
          <img
            src={r.imageUrl}
            alt={r.fileName || r.name}
            style={{
              width: "100%",
              height: 200,
              objectFit: "fill",
              padding: "20px",
            }}
          />
        ) : (
          <PlaceholderImage
            imageHeight={200}
            iconSize={20}
            top="0px"
            position="initial"
          />
        )}
      </Stack>
      <Stack width="100%" direction="row" justifyContent="space-between" p={2}>
        <Stack sx={{ pr: 2 }}>
          <Typography
            sx={{ fontSize: "1.0rem", fontWeight: 600 }}
            color="primary"
          >
            {r.source || "Source of the rebate"}
          </Typography>
          <Typography sx={{ fontSize: "1.75rem", fontWeight: 600, mt: 0.5 }}>
            {r.name}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {r.description || "Description of the rebate package"}
          </Typography>
          {moment(r.startDate) > moment() ? (
            <Box mt={2}>
              <ComingSoon incentive={r} />
            </Box>
          ) : null}
          <Box sx={{ mt: 2 }}>
            {types.map((type, index) => (
              <Chip key={index} label={type} />
            ))}
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Divider orientation="vertical" flexItem sx={{ height: "100%" }} />
          <Stack
            justifyContent="center"
            alignItems="center"
            pl={2}
            width={"160px"}
          >
            <Typography variant="body1">Up to</Typography>
            <Typography sx={{ fontWeight: 600, fontSize: "1.5rem" }}>
              {eligible ? total : "Not eligible"}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );

  const MobileLayout = ({
    rebate: r,
    eligible,
    total,
    types = [],
  }: {
    rebate: Rebate;
    eligible: boolean;
    total: string;
    types: string[];
  }) => (
    <Stack
      sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 1px 1px 1px rgba(0,0,0,0.1)",
        p: 2,
      }}
      spacing={1}
    >
      <Typography sx={{ fontSize: "1.0rem", fontWeight: 600 }} color="primary">
        {r.source || "Source of the rebate"}
      </Typography>
      <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, lineHeight: 1 }}>
        {r.name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {r.description || "Description of the rebate package"}
      </Typography>
      {moment(r.startDate) > moment() ? (
        <Box mt={4}>
          <ComingSoon incentive={r} />
        </Box>
      ) : null}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {types.map((type) => (
          <Chip label={type} />
        ))}
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body2">Up to</Typography>
          <Typography sx={{ fontWeight: 600, fontSize: "1.25rem" }}>
            {eligible ? total : "Not eligible"}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Stack spacing={2} sx={{ pb: 8 }}>
      {sortedRebates.length
        ? sortedRebates.map((r: Rebate, index: number) => {
            const eligible = showCap
              ? r.total_cap > 0
                ? true
                : false
              : r.total > 0
                ? true
                : false;
            const total = formatCurrency(showCap ? r.total_cap : r.total);
            const typeLabel = `${theme.t(`labels.rebates.timing.enum.${r.timing}`)} ${theme.t(`labels.rebates.type.enum.${r.type}`)}`;
            const types = [typeLabel];
            return isMobile ? (
              <MobileLayout
                key={index}
                rebate={r}
                eligible={eligible}
                total={total}
                types={types}
              />
            ) : (
              <DesktopLayout
                key={index}
                rebate={r}
                eligible={eligible}
                total={total}
                types={types}
              />
            );
          })
        : null}
    </Stack>
  );
};

export default EligibleRebatesStep;

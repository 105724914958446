import Ajv from "ajv";

const date = new Date();
const maximumYear = date.getFullYear() + 1;

export const schemaDef = {
  definitions: {
    ATTIC: {
      enum: ["yes", "no"],
      title: "ATTIC",
      type: "string",
    },
    ATTIC_FINISH: {
      enum: ["unfinished", "partially_finished", "finished"],
      title: "ATTIC_FINISH",
      type: "string",
    },
    ATTIC_TYPE: {
      enum: ["permanent_stairs", "hatch", "no_attic"],
      title: "ATTIC_TYPE",
      type: "string",
    },
    BASEMENT: {
      enum: ["yes", "no"],
      title: "BASEMENT",
      type: "string",
    },
    BASEMENT_FINISHED: {
      enum: ["finished", "partially_finished", "unfinished"],
      title: "BASEMENT_FINISHED",
      type: "string",
    },
    BASEMENT_TYPE: {
      enum: ["below_grade", "partially_below_grade", "above_grade"],
      title: "BASEMENT_TYPE",
      type: "string",
    },
    COMMUNICATION_PREFERENCE: {
      enum: ["email", "text", "call"],
      title: "COMMUNICATION_PREFERENCE",
      type: "string",
    },
    COOLING_EQUIPMENT_TYPE: {
      enum: [
        "central_air_conditioning",
        "window_unit_air_conditioning",
        "evaporative_cooling",
        "heat_pump_mini_split",
        "something_else",
        "no_air_conditioning",
      ],
      title: "COOLING_EQUIPMENT_TYPE",
      type: "string",
    },
    DRAFTY_STUFFY: {
      // @DEPRECATED
      enum: ["yes_drafty", "yes_stuffy", "no"],
      title: "DRAFTY_STUFFY",
      type: "string",
    },
    DRAFTY: {
      enum: ["yes", "no"],
      title: "DRAFTY",
      type: "string",
    },
    STUFFY: {
      enum: ["yes", "no"],
      title: "STUFFY",
      type: "string",
    },
    ELECTRIC_UTILITY: {
      oneOf: [
        {
          type: "string",
          enum: [""], // Replaced with dynamically loaded enum values
        },
        {
          type: "string",
        },
      ],
      title: "ELECTRIC_UTILITY",
      type: "string",
    },
    EQUIPMENT_TYPE: {
      enum: [
        "Heat Pump",
        "Air Conditioning Unit",
        "Boiler",
        "Furnace",
        "Air Handler Unit",
        "Heat Pump Water Heater",
      ],
      title: "EQUIPMENT_TYPE",
      type: "string",
    },
    EXTERIOR_WALLS: {
      enum: [
        "stucco",
        "vinyl",
        "fiber_cement",
        "brick",
        "wood",
        "stone",
        "metal",
      ],
      title: "EXTERIOR_WALLS",
      type: "string",
    },
    FLOORS: {
      enum: ["basement", "1", "2", "3", "attic"],
      title: "FLOORS",
      type: "string",
    },
    FLOOR_SIZE: {
      enum: [
        "second_floor_is_same_size",
        "second_floor_a_bit_smaller",
        "second_floor_a_lot_smaller",
      ],
      title: "FLOOR_SIZE",
      type: "string",
    },
    FUEL_SOURCE: {
      enum: [
        "natural_gas",
        "fuel_oil",
        "electricity",
        "something_else",
        "propane",
        "i_don_t_know",
      ],
      title: "FUEL_SOURCE",
      type: "string",
    },
    GARAGE: {
      enum: ["yes", "no"],
      title: "GARAGE",
      type: "string",
    },
    GARAGE_TYPE: {
      enum: [
        "fully_detached",
        "attached_with_no_living_space_above",
        "attached_with_living_space_above",
      ],
      title: "GARAGE_TYPE",
      type: "string",
    },
    GAS_UTILITY: {
      oneOf: [
        {
          type: "string",
          enum: [""], // Replaced with dynamically loaded enum values
        },
        {
          type: "string",
        },
      ],
      title: "GAS_UTILITY",
      type: "string",
    },
    HEATING_EQUIPMENT_TYPE: {
      enum: [
        "furnace_with_ducts",
        "boiler_with_radiators",
        "electric_resistive_with_baseboards",
        "heat_pump_mini_split",
        "none_of_these",
      ],
      title: "HEATING_EQUIPMENT_TYPE",
      type: "string",
    },
    HEATING_COOLING_PROJECT_SCOPE: {
      enum: ["heating", "cooling", "both"],
      title: "HEATING_COOLING_PROJECT_SCOPE",
      type: "string",
    },
    HEATING_COOLING_PROJECT_TYPE: {
      enum: ["replace", "service", "add"],
      title: "HEATING_COOLING_PROJECT_TYPE",
      type: "string",
    },
    HOME_QUALITY_TYPE: {
      enum: ["yes", "no_always_cold", "no_always_hot", "uneven"],
      title: "HOME_QUALITY_TYPE",
      type: "string",
    },
    HOW_WOULD_YOU_LIKE_TO_PROCEED: {
      enum: ["instant_quote", "schedule_virtual_consultation", "save_progress"],
      title: "HOW_WOULD_YOU_LIKE_TO_PROCEED",
      type: "string",
    },
    INSTALL_TYPE: {
      enum: ["ducted", "ductless"],
      title: "INSTALL_TYPE",
      type: "string",
    },
    INTERIOR_WALLS: {
      enum: [
        "stucco",
        "drywall",
        "plaster",
        "brick",
        "wood_panel",
        "concrete",
        "ceramic_tile",
      ],
      title: "INTERIOR_WALLS",
      type: "string",
    },
    ISSUES: {
      enum: [
        // "roof_damage",
        // "asbestos",
        // "wall_damage",
        // "visible_mold",
        // "missing_windows",
        // "standing_water",
        // "electrical_issues",
        // "indoor_air_quality",
        "structural",
        "electrical",
        "health_and_safety",
      ],
      title: "ISSUES",
      type: "string",
    },
    ELECTRICAL_PANEL_RATING: {
      enum: ["lt_100", "between_100_200", "gt_200", "i_don_t_know"],
      title: "ELECTRICAL_PANEL_RATING",
      type: "string",
    },
    HEATING_EQUIPMENT_AGE: {
      enum: ["lt_5", "between_5_10", "between_10_15", "gt_15", "i_don_t_know"],
      title: "HEATING_EQUIPMENT_AGE",
      type: "string",
    },
    HEATING_EQUIPMENT_USAGE: {
      enum: ["always_on", "frequency_adjustments", "rarely_use"],
      title: "HEATING_EQUIPMENT_USAGE",
      type: "string",
    },
    COOLING_EQUIPMENT_AGE: {
      enum: ["lt_5", "between_5_10", "between_10_15", "gt_15", "i_don_t_know"],
      title: "COOLING_EQUIPMENT_AGE",
      type: "string",
    },
    COOLING_EQUIPMENT_USAGE: {
      enum: ["always_on", "frequency_adjustments", "rarely_use"],
      title: "COOLING_EQUIPMENT_USAGE",
      type: "string",
    },
    PROJECT_TIMELINE: {
      enum: ["asap", "flexible", "i_don_t_know"],
      title: "PROJECT_TIMELINE",
      type: "string",
    },
    LoadCalculation: {
      properties: {
        ceiling: {
          title: "Ceiling",
          type: "integer",
        },
        duct_loss: {
          title: "Duct Loss",
          type: "integer",
        },
        floor: {
          title: "Floor",
          type: "integer",
        },
        infiltration: {
          title: "Infiltration",
          type: "integer",
        },
        shg: {
          title: "Shg",
          type: "integer",
        },
        wall: {
          title: "Wall",
          type: "integer",
        },
        window: {
          title: "Window",
          type: "integer",
        },
      },
      required: [
        "wall",
        "window",
        "shg",
        "ceiling",
        "floor",
        "infiltration",
        "duct_loss",
      ],
      title: "LoadCalculation",
      type: "object",
    },
    OWN_OR_RENT: {
      enum: ["own", "rent"],
      title: "OWN_OR_RENT",
      type: "string",
    },
    PEOPLE_LIVE_IN_HOME: {
      enum: [1, 2, 3, 4, 5, 6, 7, 8],
      title: "PEOPLE_LIVE_IN_HOME",
      type: "integer",
    },
    NUMBER_OF_DEPENDENTS: {
      enum: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      title: "NUMBER_OF_DEPENDENTS",
      type: "integer",
    },
    NUMBER_OF_WINDOWS: {
      enum: ["a_few", "average", "a_lot"],
      title: "NUMBER_OF_WINDOWS",
      type: "string",
    },
    PLANS: {
      enum: ["CURRENT", "FREE", "REPLACE", "BASE", "PEARL", "EDISON"],
      title: "PLANS",
      type: "string",
    },
    PROJECT_TYPE: {
      enum: [
        "heating_cooling",
        // "heating",
        // "air_conditioning",
        "weatherization",
        "water_heating",
        "residential_solar",
        "ev_charging",
        "energy_efficient_appliances",
        "electrical_upgrades",
        "im_not_sure",
      ],
      title: "Project Type",
      type: "string",
    },
    PROJECT_MOTIVATION: {
      enum: [
        "online_quote",
        "directional_estimate",
        "feedback",
        "incentives",
        "support",
      ],
      title: "Project Motivation",
      type: "string",
    },
    ROOMS: {
      enum: [
        "BEDROOM",
        "BATHROOM",
        "LIVING_ROOM",
        "KITCHEN",
        "DINING_ROOM",
        "OFFICE",
        "DEN",
        "MEDIA_ROOM",
        "FOYER",
        "LAUNDRY_ROOM",
        // "REC_ROOM",
        // "MASTER_BEDROOM",
        // "MASTER_BATHROOM",
      ],
      title: "ROOMS",
      type: "string",
    },
    ROOM_SIZE: {
      enum: ["SMALL", "MEDIUM", "LARGE"],
      title: "ROOM_SIZE",
      type: "string",
    },
    Room: {
      // required: ["name", "floor", "size"],
      properties: {
        type: {
          $ref: "#/definitions/ROOMS",
          title: "Room type",
        },
        name: {
          type: "string",
          title: "Room name",
          minLength: 3,
        },
        size: {
          $ref: "#/definitions/ROOM_SIZE",
          title: "What size?",
        },
        floor: {
          $ref: "#/definitions/FLOORS",
          title: "Which floor?",
        },
        walls: {
          title: "How many walls have windows?",
          type: "string",
        },
        wallsWithWindows: {
          title: "How many windows?",
          $ref: "#/definitions/WALLS_WITH_WINDOWS",
        },
        isCurrentlyHeated: {
          title: "Currently heating?",
          type: "boolean",
        },
        isComfortIssue: {
          title: "Is comfort issue?",
          type: "boolean",
        },
        comfortIssueFrequency: {
          title: "Comfort issue frequency",
          $ref: "#/definitions/COMFORT_ISSUE_FREQUENCY",
        },
        comfortIssueInfo: {
          title: "Comfort issue info",
          type: "string",
        },
        isHeatComfortIssue: {
          title: "Comfort issue?",
          type: "boolean",
        },
        heatComfortIssueInfo: {
          title: "Please explain",
          type: "string",
        },
        isCurrentlyAirConditioned: {
          title: "Currently cooling?",
          type: "boolean",
        },
        isAirConditionComfortIssue: {
          title: "Comfort issue?",
          type: "boolean",
        },
        airConditionComfortIssueInfo: {
          title: "Please explain",
          type: "string",
        },
        label: {
          title: "Label",
          type: "string",
        },
      },
      title: "Room",
      type: "object",
    },
    SupportEquipment: {
      properties: {
        category: {
          allOf: [
            {
              $ref: "#/definitions/INVOICE_SECTION",
            },
          ],
          default: "Miscellaneous",
        },
        install_price: {
          default: 0,
          title: "Install Price",
          type: "integer",
        },
        name: {
          default: "Billing Line Item",
          title: "Name",
          type: "string",
        },
        note: {
          default: "",
          title: "Note",
          type: "string",
        },
        price: {
          default: 0,
          title: "Price",
          type: "integer",
        },
        type: {
          allOf: [
            {
              $ref: "#/definitions/SUPPORT_ITEM_TYPE",
            },
          ],
          default: "Miscellaneous",
        },
      },
      title: "SupportEquipment",
      type: "object",
    },
    UPGRADES: {
      enum: [
        "added_insulation",
        // "upgraded_windows",
        "air_sealing",
        // "upgraded_electrical_panel",
        "duct_insulation_sealing",
        // "something_else",
      ],
      title: "UPGRADES",
      type: "string",
    },
    AIR_SEALING_SCOPE: {
      enum: ["whole", "targeted", "i_don_t_know"],
      title: "AIR_SEALING_SCOPE",
      type: "string",
    },
    AIR_SEALING_INSTALLATION: {
      enum: ["professional", "diy", "i_don_t_know"],
      title: "AIR_SEALING_INSTALLATION",
      type: "string",
    },
    DUCT_SEALING_SCOPE: {
      enum: ["tape_mastic", "aeroseal", "i_don_t_know"],
      title: "DUCT_SEALING_SCOPE",
      type: "string",
    },
    DUCT_SEALING_INSTALLATION: {
      enum: ["professional", "diy", "i_don_t_know"],
      title: "DUCT_SEALING_INSTALLATION",
      type: "string",
    },
    ATTIC_INSULATION: {
      enum: ["yes"],
      title: "ATTIC_INSULATION",
      type: "string",
    },
    ATTIC_INSULATION_TYPE: {
      enum: [
        "loose_fill_cellulose",
        "dense_packed_cellulose",
        "fiberglass_batts",
        "rigid_foam_board",
        "i_don_t_know",
      ],
      title: "ATTIC_INSULATION_TYPE",
      type: "string",
    },
    BASEMENT_INSULATION: {
      enum: ["yes"],
      title: "BASEMENT_INSULATION",
      type: "string",
    },
    BASEMENT_INSULATION_TYPE: {
      enum: [
        "loose_fill_cellulose",
        "dense_packed_cellulose",
        "fiberglass_batts",
        "rigid_foam_board",
        "i_don_t_know",
      ],
      title: "BASEMENT_INSULATION_TYPE",
      type: "string",
    },
    WALL_INSULATION: {
      enum: ["yes"],
      title: "WALL_INSULATION",
      type: "string",
    },
    WALL_INSULATION_TYPE: {
      enum: [
        "loose_fill_cellulose",
        "dense_packed_cellulose",
        "fiberglass_batts",
        "rigid_foam_board",
        "i_don_t_know",
      ],
      title: "WALL_INSULATION_TYPE",
      type: "string",
    },
    RIM_JOIST_INSULATION: {
      enum: ["yes"],
      title: "RIM_JOIST_INSULATION",
      type: "string",
    },
    RIM_JOIST_INSULATION_TYPE: {
      enum: [
        "loose_fill_cellulose",
        "dense_packed_cellulose",
        "fiberglass_batts",
        "rigid_foam_board",
        "i_don_t_know",
      ],
      title: "RIM_JOIST_INSULATION_TYPE",
      type: "string",
    },
    SHORT_CYCLES: {
      enum: ["yes", "no", "i_don_t_know"],
      title: "SHORT_CYCLES",
      type: "string",
    },
    NOISY_DUCTWORK: {
      enum: ["yes", "no", "i_don_t_know"],
      title: "SHORT_CYCLES",
      type: "string",
    },
    WALLS_WITH_WINDOWS: {
      enum: ["none", "just one", "a few", "a lot", "huge ones"],
      title: "WALLS_WITH_WINDOWS",
      type: "string",
    },
    COMFORT_ISSUE_FREQUENCY: {
      enum: ["always", "summer", "winter"],
      title: "COMFORT_ISSUE_FREQUENCY",
      type: "string",
    },
    WHATS_IMPORTANT_TO_YOU: {
      enum: [
        "lowUpFrontCost",
        "lowerMonthlyBills",
        "climateImpact",
        "betterComfort",
        "somethingElse",
      ],
      title: "WHATS_IMPORTANT_TO_YOU",
      type: "string",
    },
    WHOLE_OR_PARTIAL: {
      enum: ["whole", "partial"],
      title: "Whole or Partial Home?",
      type: "string",
    },
    WHY_ARE_YOU_SHOPPING: {
      enum: [
        "equipmentBroken",
        "equipmentLastLeg",
        "moreEfficientSystem",
        "justBrowsing",
      ],
      title: "WHY_ARE_YOU_SHOPPING",
      type: "string",
    },
    WINDOW_TYPE: {
      enum: ["single", "double", "triple"],
      title: "WINDOW_TYPE",
      type: "string",
    },
    DUCT_LOCATION: {
      enum: ["fully_conditioned", "partially_conditioned", "unconditioned"],
      title: "DUCT_LOCATION",
      type: "string",
    },
    DUCT_SEALING: {
      enum: ["poor", "moderate", "good", "excellent"],
      title: "DUCT_SEALING",
      type: "string",
    },
    ZONE_TYPES: {
      enum: ["combine", "zone", "no_zone"],
      title: "ZONE_TYPES",
      type: "string",
    },
  },
  description: "",
  properties: {
    user: {
      type: "object",
      required: [
        "firstName",
        "lastName",
        "email",
        "phoneNumber",
        "communicationPreference",
      ],
      properties: {
        firstName: {
          type: "string",
          title: "First Name",
        },
        lastName: {
          type: "string",
          title: "Last Name",
        },
        email: {
          type: "string",
          title: "Email",
          format: "email",
        },
        phoneNumber: {
          type: "string",
          title: "Phone Number",
        },
        communicationPreference: {
          type: "string",
          enum: ["email", "text", "call"],
          title: "Communication Preference",
        },
      },
    },
    aboveGroundStories: {
      title: "Above-Ground Stories",
      type: "integer",
      minimum: 1,
      maximum: 3,
    },
    address: {
      type: "string",
      title: "Address",
    },
    attic: {
      $ref: "#/definitions/ATTIC",
    },
    atticFinish: {
      $ref: "#/definitions/ATTIC_FINISH",
    },
    atticType: {
      $ref: "#/definitions/ATTIC_TYPE",
    },
    basement: {
      $ref: "#/definitions/BASEMENT",
    },
    basementFinished: {
      $ref: "#/definitions/BASEMENT_FINISHED",
    },
    basementType: {
      $ref: "#/definitions/BASEMENT_TYPE",
    },
    ceilingHeight: {
      title: "Ceiling Height",
      type: "integer",
      minimum: 6,
      maximum: 20,
    },
    communicationPreference: {
      $ref: "#/definitions/COMMUNICATION_PREFERENCE",
      title: "Communication Prefefence",
    },
    coolingEquipmentType: {
      items: {
        $ref: "#/definitions/COOLING_EQUIPMENT_TYPE",
      },
      title: "Cooling Equipment Type",
      type: "array",
    },
    coolingEquipmentUsage: {
      $ref: "#/definitions/COOLING_EQUIPMENT_USAGE",
    },
    coolingEquipmentTypeMoreInfo: {
      title: "Cooling equipment type more info",
      type: "string",
    },
    coolingEquipmentTypeAge: {
      $ref: "#/definitions/COOLING_EQUIPMENT_AGE",
      type: "string",
    },
    coolingEquipmentSEERRating: {
      title: "SEER",
      type: "number",
      minimum: 5,
      maximum: 40,
    },
    coolingEquipmentTonRating: {
      title: "tons",
      type: "number",
    },
    draftyStuffy: {
      items: {
        $ref: "#/definitions/DRAFTY_STUFFY",
      },
      type: "array",
      title: "Drafty/Stuffy",
    },
    draftyStuffyMoreInfo: {
      title: "Drafty / stuffy more info",
      type: "string",
    },
    drafty: {
      $ref: "#/definitions/DRAFTY",
    },
    stuffy: {
      $ref: "#/definitions/STUFFY",
    },
    electricUtility: {
      $ref: "#/definitions/ELECTRIC_UTILITY",
    },
    email: {
      type: "string",
    },
    exteriorWalls: {
      $ref: "#/definitions/EXTERIOR_WALLS",
    },
    firstName: {
      type: "string",
    },
    fuelSource: {
      $ref: "#/definitions/FUEL_SOURCE",
    },
    floorSize: {
      $ref: "#/definitions/FLOOR_SIZE",
    },
    garage: {
      $ref: "#/definitions/GARAGE",
    },
    garageType: {
      $ref: "#/definitions/GARAGE_TYPE",
    },
    gasUtility: {
      $ref: "#/definitions/GAS_UTILITY",
    },
    heatingEquipmentType: {
      items: {
        $ref: "#/definitions/HEATING_EQUIPMENT_TYPE",
      },
      title: "Heating Equipment Type",
      type: "array",
    },
    heatingEquipmentTypeMoreInfo: {
      title: "Heating equipment type more info",
      type: "string",
    },
    heatingCoolingProjectScope: {
      $ref: "#/definitions/HEATING_COOLING_PROJECT_SCOPE",
    },
    heatingCoolingProjectType: {
      $ref: "#/definitions/HEATING_COOLING_PROJECT_TYPE",
    },
    heatingCoolingProjectTypeMoreInfo: {
      type: "string",
    },
    heatingEquipmentUsage: {
      $ref: "#/definitions/HEATING_EQUIPMENT_USAGE",
    },
    homeType: {
      type: "string",
      title: "Home Type",
      oneOf: [
        {
          const: "singleFamily",
          title: "Single Family",
        },
        {
          const: "townhouse",
          title: "Townhouse",
        },
        {
          const: "condo",
          title: "Condo",
        },
        {
          const: "duplex",
          title: "Duplex",
        },
      ],
    },
    homeQuality: {
      items: {
        $ref: "#/definitions/HOME_QUALITY_TYPE",
      },
      title: "Home Quality",
      type: "array",
    },
    homeQualityMoreInfo: {
      title: "Home quality more info",
      type: "string",
    },
    houseHoldIncome: {
      title: "House Hold Income",
      type: "number",
    },
    howWouldYouLikeToProceed: {
      $ref: "#/definitions/HOW_WOULD_YOU_LIKE_TO_PROCEED",
    },
    interiorWalls: {
      $ref: "#/definitions/INTERIOR_WALLS",
    },
    issues: {
      items: {
        $ref: "#/definitions/ISSUES",
      },
      title: "Issues",
      type: "array",
    },
    issuesMoreInfo: {
      title: "Issues more info",
      type: "string",
    },
    electricalPanelRating: {
      $ref: "#/definitions/ELECTRICAL_PANEL_RATING",
      type: "string",
    },
    heatingEquipmentTypeAge: {
      $ref: "#/definitions/HEATING_EQUIPMENT_AGE",
      type: "string",
    },
    heatingEquipmentAFUERating: {
      title: "AFUE",
      type: "number",
      minimum: 0,
      maximum: 100,
    },
    heatingEquipmentHSPFRating: {
      title: "HSPF",
      type: "number",
      minimum: 6.7,
      maximum: 13.6,
    },
    heatingEquipmentBTUHrRating: {
      title: "BTU/hr",
      type: "number",
      minimum: 0,
    },
    lastName: {
      type: "string",
    },
    numBedrooms: {
      title: "Number of Bedrooms",
      type: "integer",
      minimum: 1,
      maximum: 20,
    },
    numDependents: {
      $ref: "#/definitions/NUMBER_OF_DEPENDENTS",
    },
    numWindows: {
      $ref: "#/definitions/NUMBER_OF_WINDOWS",
    },
    ownOrRent: {
      $ref: "#/definitions/OWN_OR_RENT",
    },
    peopleLiveInHome: {
      $ref: "#/definitions/PEOPLE_LIVE_IN_HOME",
    },
    phoneNumber: {
      type: "string",
      title: "Phone number",
      placeholder: "(XXX) XXX-XXXX",
    },
    projectType: {
      $ref: "#/definitions/PROJECT_TYPE",
    },
    projectMotivation: {
      $ref: "#/definitions/PROJECT_MOTIVATION",
    },
    projectTimeline: {
      $ref: "#/definitions/PROJECT_TIMELINE",
    },
    rooms: {
      items: {
        $ref: "#/definitions/Room",
      },
      title: "Rooms",
      type: "array",
      minItems: 1,
      contains: {
        type: "object",
        properties: {
          type: {
            type: "string",
            enum: ["BEDROOM"],
          },
        },
        required: ["type"],
      },
    },
    sqFootage: {
      title: "Sq. Footage",
      type: "integer",
      minimum: 100,
      maximum: 10000,
    },
    shortCycles: {
      title: "Short cycles",
      $ref: "#/definitions/SHORT_CYCLES",
    },
    noisyDuctwork: {
      title: "Noisy Ductwork",
      $ref: "#/definitions/NOISY_DUCTWORK",
    },
    temperatureSummer: {
      title: "Temperature Summer",
      type: "integer",
      minimum: 40,
      maximum: 100,
    },
    temperatureWinter: {
      title: "Temperature Winter",
      type: "integer",
      minimum: 40,
      maximum: 100,
    },
    upgrades: {
      items: {
        $ref: "#/definitions/UPGRADES",
      },
      title: "Upgrades",
      type: "array",
    },
    upgradesMoreInfo: {
      title: "Upgrades more info",
      type: "string",
    },
    airSealingScope: {
      $ref: "#/definitions/AIR_SEALING_SCOPE",
      title: "Air sealing scope",
    },
    airSealingInstallation: {
      $ref: "#/definitions/AIR_SEALING_INSTALLATION",
      title: "Air sealing installation",
    },
    airSealingMoreInfo: {
      title: "Anything else we should know?",
      type: "string",
    },
    ductSealingScope: {
      $ref: "#/definitions/DUCT_SEALING_SCOPE",
      title: "Duct sealing scope",
    },
    ductSealingInstallation: {
      $ref: "#/definitions/DUCT_SEALING_INSTALLATION",
      title: "Duct sealing installation",
    },
    ductSealingMoreInfo: {
      title: "Anything else we should know?",
      type: "string",
    },
    atticInsulation: {
      items: {
        $ref: "#/definitions/ATTIC_INSULATION",
      },
      title: "Attic Insulation",
      type: "array",
      minItems: 0,
    },
    atticInsulationType: {
      items: {
        $ref: "#/definitions/ATTIC_INSULATION_TYPE",
      },
      title: "Attic Insulation Type",
      type: "string",
    },
    basementInsulation: {
      items: {
        $ref: "#/definitions/BASEMENT_INSULATION",
      },
      title: "Basement Insulation",
      type: "array",
      minItems: 0,
    },
    basementInsulationType: {
      items: {
        $ref: "#/definitions/BASEMENT_INSULATION_TYPE",
      },
      title: "Basement Insulation Type",
      type: "string",
    },
    wallInsulation: {
      items: {
        $ref: "#/definitions/WALL_INSULATION",
      },
      title: "Wall Insulation",
      type: "array",
      minItems: 0,
    },
    wallInsulationType: {
      items: {
        $ref: "#/definitions/WALL_INSULATION_TYPE",
      },
      title: "Wall Insulation Type",
      type: "string",
    },
    rimJoistInsulation: {
      items: {
        $ref: "#/definitions/RIM_JOIST_INSULATION",
      },
      title: "Wall Insulation",
      type: "array",
      minItems: 0,
    },
    rimJoistInsulationType: {
      items: {
        $ref: "#/definitions/RIM_JOIST_INSULATION_TYPE",
      },
      title: "Rim Joist Insulation Type",
      type: "string",
    },
    whatsImportantToYou: {
      items: {
        $ref: "#/definitions/WHATS_IMPORTANT_TO_YOU",
      },
      title: "Whats important to you",
      type: "array",
    },
    whatsImportantToYouInfo: {
      title: "Whats important to you info",
      type: "string",
    },
    wholeOrPartialHome: {
      $ref: "#/definitions/WHOLE_OR_PARTIAL",
    },
    whyAreYouShoppingToday: {
      $ref: "#/definitions/WHY_ARE_YOU_SHOPPING",
    },
    windowType: {
      $ref: "#/definitions/WINDOW_TYPE",
    },
    yearBuilt: {
      title: "Year Built",
      type: "integer",
      minimum: 1776,
      maximum: maximumYear,
    },
    // Overrides
    // R-values
    wallRValue: {
      title: "Wall",
      type: "integer",
    },
    ceilingRValue: {
      title: "Ceiling",
      type: "integer",
    },
    floorRValue: {
      title: "Floor",
      type: "integer",
    },
    windowRValue: {
      title: "Window",
      type: "integer",
    },
    // Infiltration and Duct Losses
    ach50: {
      title: "ACH-50",
      type: "number",
      minimum: 0.0,
    },
    ductLossHeating: {
      title: "Winter duct losses",
      type: "integer",
      minimum: 0,
      maximum: 100,
    },
    ductLossCooling: {
      title: "Summer duct losses",
      type: "integer",
      minimum: 0,
      maximum: 100,
    },
    ductLocation: {
      $ref: "#/definitions/DUCT_LOCATION",
    },
    ductSealing: {
      $ref: "#/definitions/DUCT_SEALING",
    },
    sketchPad: {
      title: "Sketch Pad",
      properties: {},
    },
  },
  required: [],
  title: "",
  type: "object",
};

const ajv = new Ajv({ allErrors: true });
export const validate = ajv.compile(schemaDef);

export default schemaDef;

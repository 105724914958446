import { useTheme } from "@mui/material";
import React from "react";

export const WaterHeating = ({ styles }: { styles?: object }) => {
  const theme = useTheme();
  return (
    <svg
      width="68"
      height="112"
      viewBox="0 0 68 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <circle
        cx="33.9998"
        cy="77.655"
        r="5.62964"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M37.8039 74.0796L33.7246 78.1589"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
      />
      <path
        d="M5.16031 1.24902H62.8403C65.1477 1.24902 67.0182 3.11953 67.0182 5.42691V101.134C67.0182 103.441 65.1477 105.312 62.8403 105.312H5.16031C2.85292 105.312 0.982422 103.441 0.982422 101.134V5.4269C0.982422 3.11952 2.85292 1.24902 5.16031 1.24902Z"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M20.7774 21.2527H47.2245C48.2401 21.2527 49.0635 22.076 49.0635 23.0916V41.9046C49.0635 42.9202 48.2401 43.7436 47.2245 43.7436H20.7774C19.7618 43.7436 18.9385 42.9202 18.9385 41.9046V23.0916C18.9385 22.076 19.7618 21.2527 20.7774 21.2527Z"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M9.79688 105.404V109.692C9.79688 110.553 10.495 111.251 11.3562 111.251H18.4415C19.3027 111.251 20.0008 110.553 20.0008 109.692V105.404"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M47.999 105.404V109.692C47.999 110.553 48.6971 111.251 49.5583 111.251H56.6437C57.5048 111.251 58.203 110.553 58.203 109.692V105.404"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <line
        x1="0.482422"
        y1="97.0676"
        x2="67.5184"
        y2="97.0676"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <line
        x1="0.482422"
        y1="8.96753"
        x2="67.5184"
        y2="8.96754"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <circle
        cx="11.7804"
        cy="77.6551"
        r="2.93075"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <circle
        cx="56.2208"
        cy="77.6549"
        r="2.93075"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M41.1487 86.3421C43.689 84.2681 45.3109 81.1117 45.3109 77.5766C45.3109 71.3301 40.2471 66.2664 34.0006 66.2664C27.7542 66.2664 22.6904 71.3301 22.6904 77.5766C22.6904 81.1117 24.3123 84.2681 26.8526 86.3421"
        style={{ stroke: theme.palette.primary.contrastText }}
      />
      <path
        d="M38.1699 34.6552C38.1699 36.9577 36.3035 38.8242 34.001 38.8242C31.6985 38.8242 29.832 36.9577 29.832 34.6552C29.832 32.7029 32.8295 27.9684 33.7411 26.5736C33.8639 26.3856 34.1381 26.3856 34.2609 26.5736C35.1725 27.9684 38.1699 32.7029 38.1699 34.6552Z"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WaterHeating;

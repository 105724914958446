import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Stack,
  IconButton,
} from "@mui/material";
import { StyledDialog, classes } from "./styles";
import Close from "@mui/icons-material/Close";

interface OptionsDialogProps {
  open: boolean;
  title?: string;
  buttonText?: string;
  children?: React.ReactNode;
  footerContent?: React.ReactNode;
  toggle: () => void;
  onClick?: () => void;
}

const OptionsDialog: React.FC<OptionsDialogProps> = ({
  open,
  toggle,
  onClick,
  children,
  footerContent,
  title,
  buttonText = "Confirm",
}) => {
  return (
    <StyledDialog open={open} onClose={toggle} fullWidth>
      <Stack className={classes.dialogTitle}>
        <DialogTitle>{title}</DialogTitle>
        <IconButton onClick={toggle}>
          <Close />
        </IconButton>
      </Stack>
      <DialogContent sx={{ mt: 3 }}>{children}</DialogContent>
      <DialogActions className={classes.footerContainer}>
        {footerContent && <Box>{footerContent}</Box>}
        {onClick && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onClick}
            sx={{ mb: 1 }}
          >
            {buttonText}
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default OptionsDialog;

import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { SettingsContext } from "@library/settings/provider";
import supabase from "@library/api/supabase";

import { useNavigate } from "react-router-dom";
import { classes, Root } from "./styles";
import { ArrowBack } from "@mui/icons-material";

const baseLanguageLocation = "LoginPageView.MagicLink";

const MagicLinkForm = () => {
  const theme = useTheme();
  const { isAuthenticated } = useContext(SettingsContext);
  const [authError, setAuthError] = React.useState<string | undefined>();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const handleOnSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await supabase.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: false,
        emailRedirectTo: `${window.location.origin}/login`,
      },
    });
    setIsLoading(false);
    if (response?.error) {
      if (response.error?.message?.includes("not allowed for otp")) {
        setSubmitted(true);
      } else {
        setAuthError(response?.error?.message ?? "An unknown error occured.");
      }
    } else {
      setSubmitted(true);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const resetForm = () => {
    setEmail("");
    setSubmitted(false);
    setIsLoading(false);
  };

  const isError = Boolean(authError);

  return (
    <Root>
      <form onSubmit={handleOnSubmit}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            {authError && <Alert severity="error">{authError}</Alert>}
            {submitted ? (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton className={classes.backButton} onClick={resetForm}>
                  <ArrowBack color="primary" />
                </IconButton>
                <Typography color="text.secondary">
                  {theme?.t(`${baseLanguageLocation}.linkSent`)}
                </Typography>
                {/* Invisible element to balance the space */}
                <Box sx={{ width: 40 }} /> {/* Width matching IconButton */}
              </Stack>
            ) : (
              <Typography
                color="text.secondary"
                className={classes.leftOffText}
              >
                {theme?.t(`${baseLanguageLocation}.pickUp`)}
              </Typography>
            )}
            {submitted && (
              <Alert severity="info">
                {theme?.t(`${baseLanguageLocation}.submittedMessage`)}
              </Alert>
            )}
            {!submitted && (
              <>
                <TextField
                  name="email"
                  label="Email"
                  defaultValue={email}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  InputProps={{ error: isError }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  data-testid="submit"
                  disabled={isLoading}
                  className={classes.loginLinkButton}
                >
                  {theme?.t(`${baseLanguageLocation}.loginLink`)}
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </form>
    </Root>
  );
};

export default MagicLinkForm;

import { useTheme } from "@mui/material";
import React from "react";

export const Phone = ({ styles }: { styles?: object }) => {
  const theme = useTheme();
  return (
    <svg
      width="28"
      height="47"
      viewBox="0 0 28 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M24.5287 1.37988H3.42908C2.06421 1.37988 0.957764 2.48645 0.957764 3.85132V43.1486C0.957764 44.5135 2.06421 45.6198 3.42908 45.6198H24.5287C25.8936 45.6198 27 44.5135 27 43.1486V3.85132C27 2.48645 25.8936 1.37988 24.5287 1.37988Z"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.03271 7.38568H26.9246"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.8304 35.8481H1.1272"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1162 4.46228H15.8411"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4169 40.8176C16.4169 42.1642 15.3253 43.2558 13.9789 43.2558C12.6324 43.2558 11.5408 42.1642 11.5408 40.8176C11.5408 39.4712 12.6323 38.3794 13.9789 38.3794C15.3254 38.3794 16.4169 39.4712 16.4169 40.8176Z"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3644 21.313C16.0901 19.5156 16.0844 16.658 14.328 14.8677C12.54 13.0451 9.61489 13.0189 7.7945 14.809C5.97414 16.5992 5.94787 19.5279 7.73588 21.3504C9.00278 22.6418 10.8406 23.0314 12.4518 22.512C12.4518 22.512 13.768 23.99 15.2737 23.2672C15.2737 23.2672 13.7292 22.0899 14.3644 21.313Z"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.24459 22.4045C9.27118 23.3269 9.68667 24.6127 9.98864 25.2341C10.3707 26.4442 7.93994 26.9441 7.93994 26.9441C9.36908 28.4991 11.78 27.2848 11.78 27.2848C12.7781 28.0159 14.0089 28.4476 15.3403 28.4476C18.6738 28.4476 21.3761 25.742 21.3761 22.4045C21.3761 19.067 18.6738 16.3614 15.3403 16.3614"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Phone;

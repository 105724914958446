const equipmentTitle = `
{{#if (eq tier "edison")}}
  {{#if (eq ducted true)}}
    Ducted Heat Pump System
  {{else if (eq ducted false)}}
    Ductless Heat Pump System
  {{/if}}
{{/if}}

{{#if (eq tier "pearl")}}
  {{#if (eq ducted true)}}
    Dual-Fuel Ducted Heat Pump System
  {{else if (eq ducted false)}}
    Dual-Fuel Ductless Heat Pump System
  {{/if}}
{{/if}}

{{#if (eq tier "replace")}}
  {{#if (eq heatingCoolingProjectScope "heating")}}
    {{#if (eq primaryHeatingEquipment "boiler_with_radiators")}}
      High Efficiency Boiler
    {{else if (eq primaryHeatingEquipment "electric_resistive_with_baseboards")}}
      Resistive Heating / Space Heater
    {{else if (eq primaryHeatingEquipment "furnace_with_ducts")}}
      High Efficiency Furnace
    {{else if (eq primaryHeatingEquipment "heat_pump_mini_split")}}
      {{#if (eq ducted true)}}
        Ducted Heat Pump System
      {{else}}
        Ductless Heat Pump System
      {{/if}}
    {{else}}
      {{#if (eq ducted true)}}
        Ducted Heat Pump System
      {{else}}
        Ductless Heat Pump System
      {{/if}}
    {{/if}}
  {{/if}}
  {{#if (eq heatingCoolingProjectScope "cooling")}}
    {{#if (eq ducted true)}}
      High-Efficiency Central Air Conditioning System
    {{else}}
      High-Efficiency Mini-Split / Wall Unit Air Conditioning System
    {{/if}}
  {{/if}}
{{/if}}
`;

const equipmentImageUrl = `
{{#if (eq tier "edison")}}
  {{#if (eq ducted true)}}
    /assets/lightEstimateImages/coldClimateHeatPump.png
  {{else if (eq ducted false)}}
    /assets/lightEstimateImages/wallUnitAC.jpg
  {{/if}}
{{/if}}

{{#if (eq tier "pearl")}}
  {{#if (eq ducted true)}}
    /assets/lightEstimateImages/dualFuelHeatPump.png
  {{else if (eq ducted false)}}
    /assets/lightEstimateImages/wallUnitAC.jpg
  {{/if}}
{{/if}}

{{#if (eq tier "replace")}}
  {{#if (eq heatingCoolingProjectScope "heating")}}
    {{#if (eq primaryHeatingEquipment "boiler_with_radiators")}}
      /assets/lightEstimateImages/boiler.jpg
    {{else if (eq primaryHeatingEquipment "electric_resistive_with_baseboards")}}
      /assets/lightEstimateImages/resistiveHeat.jpg
    {{else if (eq primaryHeatingEquipment "furnace_with_ducts")}}
      /assets/lightEstimateImages/furnace.png
    {{else if (eq primaryHeatingEquipment "heat_pump_mini_split")}}
      {{#if (eq ducted true)}}
        /assets/lightEstimateImages/coldClimateHeatPump.png
      {{else}}
        /assets/lightEstimateImages/wallUnitAC.jpg
      {{/if}}
    {{else}}
      {{#if (eq ducted true)}}
        /assets/lightEstimateImages/coldClimateHeatPump.png
      {{else}}
        /assets/lightEstimateImages/wallUnitAC.jpg
      {{/if}}
    {{/if}}
  {{/if}}
  {{#if (eq heatingCoolingProjectScope "cooling")}}
    {{#if (eq ducted true)}}
      /assets/lightEstimateImages/centralAC.jpg
    {{else}}
      /assets/lightEstimateImages/wallUnitAC.jpg
    {{/if}}
  {{/if}}
{{/if}}
`;

const equipmentSubtitle = `
  {{#if primaryEnergyType}}
    {{primaryEnergyType}}{{#if secondaryEnergyType}} / {{secondaryEnergyType}}{{/if}}
  {{else}}
    {{#if coolingEnergyType}}
      {{coolingEnergyType}}
    {{else}}
      N/A
    {{/if}}
  {{/if}}
`;

const description = `
{{#if (eq tier "edison")}}
  A single system that replaces your furnace and air conditioning units, providing efficient heating and cooling while eliminating your fossil fuel usage for a more climate-friendly home.
{{/if}}
{{#if (eq tier "pearl")}}
  Combines efficient heating and cooling in one system, replacing your furance and your AC unit. It uses a heat pump for moderate temperatures and a backup furance for colder months, optimizing energy cost year-round.
{{/if}}
{{#if (eq tier "replace")}}
  {{#if (eq heatingCoolingProjectScope "heating")}}
    {{#if (eq primaryHeatingEquipment "boiler_with_radiators")}}
      Will lower both your carbon footprint and energy bills. It seamlessly integrates with your existing radiators for easy installation.
    {{else if (eq primaryHeatingEquipment "electric_resistive_with_baseboards")}}
      Inefficient low-performing, contributing to high utility bills and poor comfort.
    {{else}}
      Provides reliable home heating while significantly reducing energy waste, lowering both your carbon footprint and energy bills. It seamlessly integrates with your existing ductwork for easy installation.
    {{/if}}
  {{/if}}
  {{#if (eq heatingCoolingProjectScope "cooling")}}
    Will lower both your carbon footprint and energy bills. However, you might consider a heat pump instead, which functions similarly but also provides primary heating.
  {{/if}}
{{/if}}
`;

const bullets = `
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas furnace (cheap heat in the cold winter months) to reduce your energy cost for both heating and air conditioning.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the natural gas furnace you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for winter heating.
Provides heat, but you'll need to replace your air conditioning system separately when it fails.
Cheaper to install, but with limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas furnace (cheap heat in the cold winter months) to minimize your energy cost for both heating and air conditioning.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the natural gas furnace you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for winter heating.
Provides heat, but you won't add central air conditioning like you would with a heat pump.
Cheaper to install, but with limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas furnace (cheap heat in the cold winter months) to minimize your energy cost for both heating and air conditioning.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the natural gas furnace you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for winter heating.
Provides heat, but you won't add central air conditioning like you would with a heat pump.
Cheaper to install, but with limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Will increase your energy cost in the summer because you add a/c, but minimize your energy cost for heating by combining the benefits of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas furnace (cheap heat in the cold winter months).
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will increase your energy cost in the summer because you add a/c. Will also modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the natural gas furnace you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for winter heating.
Provides heat, but you won't add central air conditioning like you would with a heat pump.
Cheaper to install, but with limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning - all in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas furnace (cheap heat in the cold winter months) to minimize your energy cost for both heating and air conditioning.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the natural gas furnace you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for winter heating.
Provides heat, but you won't add central air conditioning like you would with a heat pump.
Cheaper to install, but with limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the propane furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install propane furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the propane furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install propane furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the propane furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install propane furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane furnace to ensure you're comfortable year round.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the propane furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install propane furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the propane furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install propane furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the fuel oil furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install fuel oil furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the fuel oil furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install fuel oil furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the fuel oil furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install fuel oil furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil furnace to ensure you're comfortable year round.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the fuel oil furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install fuel oil furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the fuel oil furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install fuel oil furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric furnaces are not.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the electric furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install electric furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric furnaces are not.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the electric furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install electric furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric furnaces are not.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the electric furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install electric furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric furnaces are not.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the electric furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install electric furnaces.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric furnaces are not.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the electric furnace you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install electric furnaces.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas boiler (cheap heat in the cold winter months) to reduce your energy cost for both heating and air conditioning.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the natural gas boiler you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for winter heating.
Provides heat, but you'll need to replace your air conditioning system separately when it fails.
Cheaper to install, but with limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds air multi-split air conditioning to replace your window-unit system - all in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas boiler (cheap heat in the cold winter months) to minimize your energy cost for both heating and air conditioning.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split conditioning to replace your window-unit system - all in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the natural gas boiler you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for winter heating.
Provides heat, but you won't add air conditioning like you would with a heat pump.
Cheaper to install, but with limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas boiler (cheap heat in the cold winter months) to minimize your energy cost for both heating and air conditioning.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split conditioning - all in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the natural gas boiler you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for winter heating.
Provides heat, but you won't add central air conditioning like you would with a heat pump.
Cheaper to install, but with limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Will increase your energy cost in the summer because you add a/c, but minimize your energy cost for heating by combining the benefits of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas boiler (cheap heat in the cold winter months).
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds central air conditioning.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will increase your energy cost in the summer because you add a/c. Will also modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the natural gas boiler you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for winter heating.
Provides heat, but you won't add air conditioning like you would with a heat pump.
Cheaper to install, but with limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds air conditioning - all in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas boiler (cheap heat in the cold winter months) to minimize your energy cost for both heating and air conditioning.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the natural gas boiler you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for winter heating.
Provides heat, but you won't add air conditioning like you would with a heat pump.
Cheaper to install, but with limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane boiler to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the propane boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install propane boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning to replace your window-unit system - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane boiler to ensure you're comfortable year round.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the propane boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install propane boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane boiler to ensure you're comfortable year round.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the propane boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install propane boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane boiler to ensure you're comfortable year round.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the propane boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install propane boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane boiler to ensure you're comfortable year round.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the propane boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install propane boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil boiler to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the fuel oil boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install fuel oil boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning to replace your window-unit system - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil boiler to ensure you're comfortable year round.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the fuel oil boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install fuel oil boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil boiler to ensure you're comfortable year round.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the fuel oil boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install fuel oil boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil boiler to ensure you're comfortable year round.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the fuel oil boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install fuel oil boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil boiler to ensure you're comfortable year round.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the fuel oil boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install fuel oil boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric resistive heating is not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
A replacement for the electric resistive heating you have today - meaning your new system will be functionally similar to your current system.
We do not recommend or install electric resistive heating.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric resistive heating is not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
A replacement for the electric resistive heating you have today - meaning your new system will be functionally similar to your current system.
We do not recommend or install electric resistive heating.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric resistive heating is not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
A replacement for the electric resistive heating you have today - meaning your new system will be functionally similar to your current system.
We do not recommend or install electric resistive heating.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric resistive heating is not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
A replacement for the electric resistive heating you have today - meaning your new system will be functionally similar to your current system.
We do not recommend or install electric resistive heating.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric resistive heating is not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
A replacement for the electric resistive heating you have today - meaning your new system will be functionally similar to your current system.
We do not recommend or install electric resistive heating.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric boilers are not.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the electric boilers you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install electric boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric boilers are not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the electric boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install electric boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric boilers are not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the electric boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install electric boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric boilers are not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the electric boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install electric boilers.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric boilers are not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the electric boiler you have today - meaning your new system will be functionally similar and is likely to modestly reduce your energy cost for winter heating.
We do not recommend or install electric boilers.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas furnace (cheap heat in the cold winter months) to reduce your energy cost for both heating and air conditioning.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas furnace (cheap heat in the cold winter months) to minimize your energy cost for both heating and air conditioning.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas furnace (cheap heat in the cold winter months) to minimize your energy cost for both heating and air conditioning.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Will increase your energy cost in the summer because you add a/c, but minimize your energy cost for heating by combining the benefits of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas furnace (cheap heat in the cold winter months).
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will increase your energy cost in the summer because you add a/c. Will also modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning - all in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas furnace (cheap heat in the cold winter months) to minimize your energy cost for both heating and air conditioning.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane furnace to ensure you're comfortable year round.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil furnace to ensure you're comfortable year round.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil furnace to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your furnace and your central air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric furnaces are not.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric furnaces are not.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric furnaces are not.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric furnaces are not.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your furnace, but also adds central air conditioning to supplement your multi-split system - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric furnaces are not.
Utilizes your existing ductwork, just like your furnace.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "furnace_with_ducts"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas boiler (cheap heat in the cold winter months) to reduce your energy cost for both heating and air conditioning.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds air multi-split air conditioning to replace your window-unit system - all in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas boiler (cheap heat in the cold winter months) to minimize your energy cost for both heating and air conditioning.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split conditioning to replace your window-unit system - all in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas boiler (cheap heat in the cold winter months) to minimize your energy cost for both heating and air conditioning.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split conditioning - all in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Will increase your energy cost in the summer because you add a/c, but minimize your energy cost for heating by combining the benefits of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas boiler (cheap heat in the cold winter months).
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds central air conditioning.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will increase your energy cost in the summer because you add a/c. Will also modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds air conditioning - all in a single package.
Combines the best of a heat pump (efficient shoulder season heating and air conditioning) and a natural gas boiler (cheap heat in the cold winter months) to minimize your energy cost for both heating and air conditioning.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Eliminates your natural gas consumption for home heating - the single most climate-friendly choice you can make for your home.
Will modestly increase your energy cost for heating during cold winter months - we can help you explore options to minimize this increase.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "natural_gas") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane boiler to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning to replace your window-unit system - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane boiler to ensure you're comfortable year round.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane boiler to ensure you're comfortable year round.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane boiler to ensure you're comfortable year round.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "propane") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and propane is expensive.
Eliminates your propane consumption for home heating - the single most climate-friendly choice you can make for your home.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "propane") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup propane boiler to ensure you're comfortable year round.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "propane") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil boiler to ensure you're comfortable year round.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning to replace your window-unit system - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil boiler to ensure you're comfortable year round.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil boiler to ensure you're comfortable year round.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil boiler to ensure you're comfortable year round.
Will increase your energy cost in the summer because you add a/c, but this will be offset by reducing your energy cost for heating in the winter.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are efficient, and fuel oil is expensive.
Eliminates your fuel oil consumption for home heating - the single most climate-friendly choice you can make for your home.
{{/if}}
{{#if (and (eq tier "pearl") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Includes a heat pump will do the heavy lifting to reduce your energy cost for heating, but includes an efficient backup fuel oil boiler to ensure you're comfortable year round.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric resistive heating is not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "fuel_oil") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric resistive heating is not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric resistive heating is not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric resistive heating is not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric resistive heating is not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "electric_resistive_with_baseboards"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home - a replacement for both your boiler and your central air conditioning system, in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric boilers are not.
Utilizes your existing ductwork, just like your central air conditioning.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "central_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the central air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your furnace separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning to replace your window-unit system - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric boilers are not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "window_unit_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric boilers are not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "heat_pump_mini_split") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric boilers are not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "no_air_conditioning") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
{{#if (and (eq tier "edison") (eq fuelSource "electricity") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
Provides efficient heating and air conditioning to your entire home. This system replaces your boiler, but also adds multi-split air conditioning - all in a single package.
Will reduce your energy cost for heating - heat pumps are very efficient, and electric boilers are not.
{{/if}}
{{#if (and (eq tier "replace") (eq fuelSource "electricity") (eq primaryCoolingEquipment "something_else") (eq primaryHeatingEquipment "boiler_with_radiators"))}}
A more efficient version of the air conditioning system you have today - meaning your new system will be functionally similar and is likely to reduce your energy cost for summer cooling.
Provides air conditioning, but you'll need to replace your boiler separately when it fails.
Limited eligibility for rebates and tax incentives that reduce your price.
{{/if}}
`;

export const lightEstimateTemplates = {
  equipmentTitle,
  equipmentSubtitle,
  equipmentImageUrl,
  description,
  bullets,
};

import { styled } from "@mui/material";

const PREFIX = "DropzoneArea";

export const classes = {
  dropzoneContainer: `${PREFIX}-dropzoneContainer`,
  ctaText: `${PREFIX}-ctaText`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.dropzoneContainer}`]: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    border: `1px dashed ${theme.palette.divider}`,
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  [`& .${classes.ctaText}`]: {
    cursor: "pointer",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
}));

import React from "react";

interface TaskImageProps {
  url: string;
  taskName: string;
  width?: number;
  height?: number;
}

const TaskImage: React.FC<TaskImageProps> = ({
  url,
  taskName,
  width = 80,
  height = 80,
}) => {
  return (
    <img
      src={url}
      alt={taskName}
      style={{
        width,
        height,
        objectFit: "contain",
      }}
    />
  );
};

export default TaskImage;

import { styled } from "@mui/material";

const PREFIX = "EnginePageView";

export const classes = {
  fileRowOuterContainer: `${PREFIX}-fileRowOuterContainer`,
  fileNameContainer: `${PREFIX}-fileNameContainer`,
  floorSelectionContainer: `${PREFIX}-floorSelectionContainer`,
  floorSelectFormControl: `${PREFIX}-floorSelectFormControl`,
  iconContainer: `${PREFIX}-iconContainer`,
  thumbnail: `${PREFIX}-thumbnail`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.fileRowOuterContainer}`]: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    alignItems: "stretch",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  [`& .${classes.fileNameContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  [`& .${classes.floorSelectionContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      justifyContent: "flex-end",
      minWidth: 250,
    },
  },
  [`& .${classes.floorSelectFormControl}`]: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      flexGrow: 0,
      minWidth: 150,
      marginBottom: 0,
    },
  },
  [`& .${classes.iconContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },
  [`& .${classes.thumbnail}`]: {
    width: "150px",
    height: "100px",
    objectFit: "contain",
    marginRight: theme.spacing(2),
  },
}));

import { useTheme } from "@mui/material";
import React from "react";

export const Contract = ({ styles }: { styles?: object }) => {
  const theme = useTheme();
  return (
    <svg
      width="74"
      height="70"
      viewBox="0 0 74 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M45.9154 51.2327L32.095 59.4802L10.6159 23.2655L31.7071 10.6788L37.6329 7.14247L41.4235 13.5337"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.615 16.0167L17.7921 26.3809L10.6164 23.2652"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.6334 7.14253L37.0305 12.725"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.8287 52.6323L29.2729 48.2983L35.2498 12.4866L60.8057 16.8206L54.8287 52.6323Z"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.3653 20.2936L56.9287 21.5708"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.6098 24.7664L56.1732 26.0436"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.8545 29.2385L55.4179 30.5157"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.1011 33.7109L54.6645 34.9881"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8439 36.241L54.0112 39.4777"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.0898 40.7136L53.2572 43.9503"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.336 45.1859L52.5033 48.4226"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.4836 25.8844C44.1275 27.9935 42.1289 29.4146 40.0198 29.0584C37.9107 28.7023 36.4895 26.7038 36.8457 24.5946C37.3297 21.7282 41.7511 18.8058 41.7511 18.8058C41.7511 18.8058 44.9978 22.8395 44.4836 25.8844Z"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.6992 19.115L39.6631 31.1724"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Contract;

import React from "react";
import PlaceholderImage from "@library/components/PlaceholderImage";

interface EquipmentImageProps {
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

export const EquipmentImage: React.FC<EquipmentImageProps> = ({
  url,
  payload,
}) => {
  if (!url) return <PlaceholderImage imageHeight={200} top="0px" />;

  return (
    <img
      src={url}
      alt={`${payload.heatingEquipmentType} equipment`}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "center",
      }}
    />
  );
};

export default EquipmentImage;

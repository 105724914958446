import React from "react";
import {
  CardContent,
  Typography,
  SxProps,
  Theme,
  Box,
  Stack,
} from "@mui/material";
import { StyledCard, classes } from "./styles";

interface CardWithHeaderProps {
  title: string;
  subtitle?: string;
  padding?: boolean;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  headerIcon?: React.ReactNode;
  actions?: React.ReactNode;
}

const CardWithHeader: React.FC<CardWithHeaderProps> = ({
  title,
  subtitle,
  children,
  sx,
  padding = true,
  headerIcon,
  actions,
}) => {
  return (
    <StyledCard sx={sx}>
      <div className={classes.headerContainer}>
        <Box
          className={classes.headerAndIconBoxContainer}
          sx={{ width: "100%" }}
        >
          {headerIcon && headerIcon}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body1">{subtitle}</Typography>
          </Stack>
        </Box>
        {actions && actions}
      </div>
      <CardContent
        className={
          padding
            ? classes.cardContentWithPadding
            : classes.cardContentWithoutPadding
        }
      >
        {children}
      </CardContent>
    </StyledCard>
  );
};

export default CardWithHeader;

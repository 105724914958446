import { styled } from "@mui/material";

const PREFIX = "LetsGetStartedStep";

export const classes = {
  boxContainer: `${PREFIX}-boxContainer`,
  icon: `${PREFIX}-icon`,
  cardTitle: `${PREFIX}-cardTitle`,
  title: `${PREFIX}-title`,
  timeframe: `${PREFIX}-timeframe`,
  description: `${PREFIX}-description`,
  videoButton: `${PREFIX}-videoButton`,
  descriptionButtonContainer: `${PREFIX}-descriptionButtonContainer`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.boxContainer}`]: {
    borderRadius: 8,
    padding: theme.spacing(2),
    height: "100%",
    minHeight: "100%",
    [theme.breakpoints.up("md")]: {
      minHeight: 540,
    },
    backgroundColor: theme.palette.common.white,
  },
  [`& .${classes.icon}`]: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(2),
    flexShrink: 0,
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  [`& .${classes.cardTitle}`]: {
    fontSize: 38,
    fontWeight: 700,
    lineHeight: "38.4px",
    color: theme.palette.primary.light,
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.title}`]: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "24px",
    color: theme.palette.text.primary,
  },
  [`& .${classes.timeframe}`]: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    color: theme.palette.primary.main,
  },
  [`& .${classes.description}`]: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    color: theme.palette.text.secondary,
  },
  [`& .${classes.videoButton}`]: {
    // height: 32,
    borderRadius: 4,
  },
  [`& .${classes.descriptionButtonContainer}`]: {
    marginTop: `${theme.spacing(1)} !important`,
  },
}));

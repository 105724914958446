import { styled } from "@mui/material";

const PREFIX = "AdvisorTooltipContent";

export const classes = {
  caption: `${PREFIX}-caption`,
  advisorName: `${PREFIX}-advisorName`,
  advisorDescription: `${PREFIX}-advisorDescription`,
  tooltipContent: `${PREFIX}-tooltipContent`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.tooltipContent}`]: {
    width: "100%",
  },

  [`& .${classes.caption}`]: {
    color: theme.palette.text.primary,
    fontSize: "0.75rem", // 12px base
    [theme.breakpoints.down("md")]: {
      fontSize: "0.6875rem", // 11px on smaller screens
    },
  },

  [`& .${classes.advisorName}`]: {
    margin: 0,
    color: theme.palette.text.primary,
    fontSize: "1.5rem", // 24px base
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem", // 20px on smaller screens
    },
    lineHeight: 1.2,
  },

  [`& .${classes.advisorDescription}`]: {
    marginTop: theme.spacing(1),
    fontSize: "0.875rem", // 14px base
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8125rem", // 13px on smaller screens
    },
  },
}));

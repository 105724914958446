import { useTheme } from "@mui/material";
import React from "react";

export const Agreement = ({ styles }: { styles?: object }) => {
  const theme = useTheme();
  return (
    <svg
      width="104"
      height="105"
      viewBox="0 0 104 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M41.6657 25.0223L28.3105 39.828L47.2621 42.1947L34.2905 58.7472"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.7853 69.0293L36.5425 73.345"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.2885 80.3074L39.5386 83.8108"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.3821 31.2644C66.7704 36.7979 71.5674 41.0295 77.0961 40.7158C82.6251 40.402 86.7921 35.6618 86.4036 30.1282C86.0151 24.5946 81.2181 20.3631 75.6894 20.6768C70.1604 20.9906 65.9934 25.7308 66.3821 31.2644Z"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.7774 20.7745L72.7747 1.38409C72.7747 1.38409 50.856 16.7515 68.2803 36.4913"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.2804 36.4915C68.2804 36.4915 40.1637 51.3813 53.7639 94.2102C54.5789 96.7766 57.3318 98.3408 59.8701 97.4421C59.9103 97.4278 59.9505 97.4131 59.991 97.3977C62.2882 96.5226 63.3941 93.9014 62.7084 91.5406C60.2138 82.9532 54.3753 55.6163 75.6113 40.6879"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.3232 14.8717L80.2746 1.38409C80.2746 1.38409 102.193 16.7515 84.7688 36.4913"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.7684 36.4915C84.7684 36.4915 112.885 51.3813 99.285 94.2102C98.4701 96.7766 95.7172 98.3408 93.1788 97.4421C93.1386 97.4278 93.0983 97.4131 93.058 97.3977C90.7607 96.5226 89.6548 93.9014 90.3406 91.5406C92.835 82.9532 98.6734 55.6163 77.4375 40.6879"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.154 45.3705L83.303 85.7179L63.123 92.1254"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.9018 95.1307L27.3475 103.785L2.09277 26.2914L58.0489 8.22455L61.5404 18.9381"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.629883 33.9605L7.79422 31.6256"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.97217 44.2191L11.1365 41.8843"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.31641 54.4779L14.4809 52.143"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6587 64.738L17.8232 62.403"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0029 74.9966L21.1673 72.6617"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3467 85.2552L24.511 82.9205"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6895 95.5155L27.854 93.1805"
        style={{ stroke: theme.palette.primary.main }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Agreement;

import { styled } from "@mui/material";

const PREFIX = "AssessmentWalkthroughPhotos";

export const classes = {
  homeAssessmentContainer: `${PREFIX}-homeAssessmentContainer`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.homeAssessmentContainer}`]: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(8),
  },
}));

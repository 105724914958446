import React from "react";
import { Button, DialogContent, Grid2, Typography } from "@mui/material";
import { StyledDialog, classes } from "./styles";

interface InformationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  text?: string;
  title?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  buttonText?: string;
}

const InformationDialog: React.FC<InformationDialogProps> = ({
  text,
  title,
  isOpen,
  onClose,
  maxWidth = "xs",
  buttonText = "Done",
}) => {
  const handleDoneClick = () => {
    onClose();
  };

  return (
    <StyledDialog open={isOpen} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <DialogContent className={classes.dialogContent}>
        <Grid2 container spacing={2}>
          {title && (
            <Grid2 size={12}>
              <Typography variant="h5">{title}</Typography>
            </Grid2>
          )}
          {text && (
            <Grid2 size={12}>
              <Typography>{text}</Typography>
            </Grid2>
          )}
          <Grid2 container className={classes.buttonContainer} size={12}>
            <Grid2 size={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleDoneClick}
              >
                {buttonText}
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </DialogContent>
    </StyledDialog>
  );
};

export default InformationDialog;

import { useTheme } from "@mui/material";
import React from "react";

export const Weatherization = ({ styles }: { styles?: object }) => {
  const theme = useTheme();
  return (
    <svg
      width="106"
      height="108"
      viewBox="0 0 106 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M9.11328 26.3372V107.092H96.8855V26.3372"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.779 30.6352L52.9996 0.907227L1.2207 30.6352"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.9996 13.0222L87.8635 33.4857V96.3528H18.1357V33.4857L52.9996 13.0222Z"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.5848 81.0082C34.3742 78.7459 33.5497 75.9434 33.5449 73.1047C33.5396 69.8629 34.6705 66.5742 37.1942 63.9899C39.7086 61.4151 40.8375 58.0996 40.8404 54.8661C40.8431 52.0138 40.0186 49.2338 37.8006 46.9626"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.3913 81.0082C50.1808 78.7459 49.3563 75.9434 49.3516 73.1047C49.3462 69.8629 50.4771 66.5742 53.0007 63.9899C55.5151 61.4151 56.6441 58.0996 56.647 54.8661C56.6497 52.0138 55.8252 49.2338 53.6072 46.9626"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.198 81.0082C65.9875 78.7459 65.163 75.9434 65.1582 73.1047C65.1529 69.8629 66.2838 66.5742 68.8074 63.9899C71.3218 61.4151 72.4508 58.0996 72.4537 54.8661C72.4563 52.0138 71.6318 49.2338 69.4139 46.9626"
        style={{ stroke: theme.palette.primary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Weatherization;

import React from "react";
import { FieldProps } from "@rjsf/utils";
import {
  Box,
  Stack,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  useTheme,
  Grid2,
} from "@mui/material";

interface SchemaProperty {
  type: string;
  title?: string;
  enum?: string[];
  format?: string;
}

interface SchemaProperties {
  [key: string]: SchemaProperty;
}

const CommunicationsField = ({
  formData,
  onChange,
  schema,
  uiSchema,
  errorSchema,
}: FieldProps) => {
  const theme = useTheme();

  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const formatFunction = uiSchema?.[field]?.format;

      const newFormData = {
        ...formData,
        [field]: formatFunction
          ? formatFunction(value, formData?.[field] || "")
          : value,
      };
      onChange(newFormData);
    };

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 2,
        margin: "0 auto",
        backgroundColor: "white",
      }}
    >
      <Stack spacing={2}>
        {Object.entries(schema.properties as SchemaProperties).map(
          ([field, fieldSchema]) => {
            const fieldError = errorSchema?.[field]?.__errors;
            const hasError = Boolean(fieldError && fieldError.length);

            if (field === "communicationPreference") {
              return (
                <Grid2 container spacing={1} alignItems="center" key={field}>
                  <Grid2
                    size={{
                      xs: 12,
                      sm: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: "text.primary",
                      }}
                    >
                      Communication Preference
                    </Typography>
                  </Grid2>
                  <Grid2
                    size={{
                      xs: 12,
                      sm: 8,
                    }}
                  >
                    <RadioGroup
                      value={formData?.[field] || ""}
                      onChange={handleChange(field)}
                    >
                      <Stack direction="row" spacing={3}>
                        <FormControlLabel
                          value="call"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: theme.palette.primary.main,
                                },
                              }}
                            />
                          }
                          label="Call me"
                        />
                        <FormControlLabel
                          value="text"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: theme.palette.primary.main,
                                },
                              }}
                            />
                          }
                          label="Text me"
                        />
                        <FormControlLabel
                          value="email"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: theme.palette.primary.main,
                                },
                              }}
                            />
                          }
                          label="Email me"
                        />
                      </Stack>
                    </RadioGroup>
                    {hasError && (
                      <Grid2 size={12}>
                        <Typography color="error" variant="caption">
                          {fieldError?.join(", ")}
                        </Typography>
                      </Grid2>
                    )}
                  </Grid2>
                </Grid2>
              );
            }

            return (
              <Grid2 container alignItems="center" key={field}>
                <Grid2
                  size={{
                    xs: 12,
                    sm: 4,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "text.primary",
                    }}
                  >
                    {fieldSchema.title}
                  </Typography>
                </Grid2>
                <Grid2
                  size={{
                    xs: 12,
                    sm: 8,
                  }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={field === "email" ? "email" : "text"}
                    value={formData?.[field] || ""}
                    onChange={handleChange(field)}
                    placeholder={uiSchema?.[field]?.["ui:placeholder"]}
                    error={hasError}
                    helperText={hasError ? fieldError?.join(", ") : ""}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.palette.primary.main,
                          borderWidth: 2,
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "16px",
                        color: "text.secondary",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.12)",
                      },
                    }}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                      },
                    }}
                  />
                </Grid2>
              </Grid2>
            );
          }
        )}
      </Stack>
    </Box>
  );
};

export default CommunicationsField;

import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import Language from "@library/components/Language";
import { Incentive } from "@library/domain/estimate";

export const ComingSoon = ({ incentive }: { incentive: Incentive }) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 1,
        p: 2,
      }}
    >
      <Typography
        sx={{
          color: theme.palette.secondary.contrastText,
          fontWeight: 600,
          fontSize: "1.1rem",
        }}
      >
        <Language
          languageKey={`concierge.PackageDetails.PricingContent.rebates.comingSoon.title`}
          values={{
            ...(incentive || {}),
          }}
        />
      </Typography>
      <Typography
        sx={{
          color: theme.palette.secondary.contrastText,
          fontWeight: 400,
          fontSize: "0.9rem",
          mt: 2,
          mb: 2,
        }}
      >
        <Language
          languageKey={`concierge.PackageDetails.PricingContent.rebates.comingSoon.description`}
          values={{
            ...(incentive || {}),
          }}
        />
      </Typography>
      {incentive.url ? (
        <Typography
          sx={{ color: theme.palette.secondary.contrastText, fontWeight: 400 }}
          display="inline"
        >
          <a
            style={{ color: theme.palette.secondary.contrastText }}
            href={incentive.url}
            target="_blank"
            title={theme.t(
              `concierge.PackageDetails.PricingContent.rebates.comingSoon.linkTitle`
            )}
          >
            {theme.t(
              `concierge.PackageDetails.PricingContent.rebates.comingSoon.linkTitle`
            )}
          </a>
          <a
            style={{ color: theme.palette.secondary.contrastText }}
            href={incentive.url}
            target="_blank"
            title={theme.t(
              `concierge.PackageDetails.PricingContent.rebates.comingSoon.linkTitle`
            )}
          >
            <OpenInNewIcon
              sx={{ ml: 1, width: "20px", top: "8px", position: "relative" }}
            />
          </a>
        </Typography>
      ) : null}
    </Stack>
  );
};

export default ComingSoon;

import React from "react";
import { WidgetProps } from "@rjsf/utils";
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";

import assets from "../assets";

const Component = ({
  checked,
  props,
  name,
  optionValue,
  optionLabel,
  fontSize,
  placement = "side",
}: {
  checked: boolean;
  padding: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  name: string;
  optionValue: string;
  optionLabel: string;
  image?: React.FC;
  borderRadius?: string | number;
  fontSize?: string;
  placement?: string;
}) => {
  const theme = useTheme();
  return (
    <ToggleButton
      value={optionValue}
      sx={{
        p: { xs: 1, sm: 2 }, // Reduce padding on mobile
        opacity: "1.0",
        margin: "0px",
        color: theme.palette.text.primary,
        flex: 1,
        minWidth: 0,
        width: "100%",
      }}
    >
      <Stack
        alignItems={placement === "bottom" ? "center" : "flex-start"}
        sx={{ width: "100%" }}
      >
        <Typography
          sx={{
            width: "100%",
            fontSize: { xs: "0.875rem", sm: fontSize }, // Smaller font on mobile
            textAlign: placement === "bottom" ? "center" : undefined,
            textTransform: "none",
            overflow: "hidden",
            textOverflow: "ellipsis", // Add ellipsis for overflow
            whiteSpace: "nowrap",
            color: checked
              ? theme.palette.primary.contrastText
              : theme.palette.text.secondary,
          }}
        >
          {theme.t(`labels.${props.name}.enum.${optionValue}.title`) ||
            theme.t(`labels.${name}.enum.${optionValue}`) ||
            optionLabel ||
            optionValue}
        </Typography>
        {theme.t(`labels.${props.name}.enum.${optionValue}.description`) ? (
          <Typography sx={{ fontSize: "12px" }}>
            {theme.t(`labels.${props.name}.enum.${optionValue}.description`)}
          </Typography>
        ) : null}
      </Stack>
    </ToggleButton>
  );
};

export const ButtonGroup = (props: WidgetProps) => {
  const theme = useTheme();
  const name = props.name as keyof typeof assets;
  const {
    title,
    padding = 1,
    borderRadius = 10,
    backgroundColor = "white",
    titleKey = "",
    fontSize,
    placement,
    containerBorderRadius = "10px",
    multiple = false,
    maxWidth = "550px", // Adjust this value as needed
  } = props.uiSchema ?? {};
  return (
    <Stack
      sx={{
        width: "100%",
        px: { xs: 0, sm: 2 }, // Remove padding on xs screens
      }}
    >
      <Stack
        sx={{
          backgroundColor,
          py: 2,
          px: { xs: 1, sm: 3 }, // Reduce padding on xs screens
          borderRadius: containerBorderRadius,
          mb: 1,
          width: "100%",
          minWidth: 0, // To prevent overflow
        }}
        justifyContent="space-between"
        alignItems="center"
        direction={{ xs: "column", sm: "row" }} // Stack vertically on mobile
        spacing={2}
      >
        {title && (
          <Stack
            justifyContent="flex-start"
            alignItems={{ xs: "center", sm: "flex-start" }}
            sx={{
              width: { xs: "100%", sm: "150px" }, // Full width on mobile
              mb: { xs: 1, sm: 0 }, // Add margin bottom on mobile
            }}
          >
            <Typography sx={{ fontSize: "1rem !important", fontWeight: 600 }}>
              {theme.t(`labels.${titleKey}.${name}.label`) ||
                theme.t(`labels.${name}.label`) ||
                name}
            </Typography>
          </Stack>
        )}
        <Stack
          sx={{
            flex: 1,
            maxWidth: maxWidth,
            width: "100%", // Ensure full width
            overflow: "hidden", // Prevent overflow
          }}
        >
          <ToggleButtonGroup
            value={props.value}
            exclusive={!multiple}
            onChange={(_event, value: string | number) => {
              props.onChange(value);
            }}
            sx={{
              width: "100%",
              display: "flex",
              textAlign: "center",
              "& .MuiToggleButton-root": {
                flex: 1,
                minWidth: 0,
                px: { xs: 1, sm: 2 }, // Reduce padding on mobile
              },
              ".Mui-selected": {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: `${theme.palette.secondary.contrastText} !important`,
              },
              ".MuiToggleButtonGroup-grouped .Mui-selected": {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: `${theme.palette.secondary.contrastText} !important`,
              },
            }}
          >
            {props?.options?.enumOptions?.map((option) => {
              const value = option.value as keyof (typeof assets)[typeof name];
              const label = option.label ?? value;
              const checked = value === props.value;
              return (
                <Component
                  key={value}
                  padding={padding}
                  option={option}
                  checked={checked}
                  props={props}
                  value={value}
                  name={name}
                  optionValue={value}
                  optionLabel={label}
                  borderRadius={borderRadius}
                  fontSize={fontSize}
                  placement={placement}
                />
              );
            })}
          </ToggleButtonGroup>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ButtonGroup;
